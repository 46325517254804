const USER_ACTIONS = {
    LOAD_ORGANISATION:'load-organisation',
    LOAD_INVOICES: "load-invoices",
    LOAD_INVOICE: "load-invoice",
    ADD_INVOICE: "add-invoice",
    AMEND_INVOICE: "amend-invoice",
    SAVE_AND_SEND: "save-and-send",
    UPDATE_AND_SEND: "update-and-send",
    REMOVE_INVOICE: "remove-invoice",
    DOWNLOAD_INVOICE: 'download-invoice',
    RESEND_INVOICE:'resend-invoice',
    PAYMENT: 'payment'
}
export default USER_ACTIONS