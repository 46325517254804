import Loading from "components/Loader/Loading";
import { Col, PanelTab, PanelTabs, PanelWindow, Row } from "ims-ui-kit";
import React from "react";
import IncidentManagement from "views/incidentManagement/IncidentManagement";
import { Attachments } from "views/shared/Attachments/Index";
import DetailsDrawerHeader from "views/shared/DetailComponents/DetailsDrawerHeader";
import DetailsWrapper from "views/shared/DetailComponents/DetailsWrapper";
import TaskManagement from "views/taskManagement/TaskManagement";
import ContractsButtons from "./ContractsButtons";
import KpiObjective from "./KpiObjective";
import KpiObjectiveForm from "./KpiObjectiveForm";
import OnboardingsButtons from "./OnboardingsButtons";
import SlasButtons from "./SlasButtons";
import SupplierOverview from "./SupplierOverview";
import USER_ACTIONS from "./actions";
import { useSupplier } from "./store";

const SupplierDrawerDetail = () => {
  let { processing, visitingSupplier: supplier, addKpi } = useSupplier();
  return (
    <React.Fragment>
        {processing[USER_ACTIONS.LOAD_SUPPLIER].status ? (
          <Loading />
        ) : (
          supplier && (
            <React.Fragment>
              <DetailsDrawerHeader data={supplier} />
              <PanelTabs variant="outline" activeTab={0}>
                <PanelTab>
                  <i className="ims-icons-20 icon-icon-notebook-24 mr-1"></i>
                  Overview
                </PanelTab>
                <PanelTab>
                  <i className="ims-icons-20 icon-icon-list-24 mr-1"></i>
                  Details
                </PanelTab>
                <PanelTab>
                  <i
                    className="ims-icons-20 icon-icon-activity-24 mr-1
                        "
                  ></i>
                  KPI/Objectives
                </PanelTab>

                <PanelTab>
                  <i
                    className="ims-icons-20 icon-icon-circlewavywarning-24 mr-1
      "
                  ></i>
                  Incidents
                </PanelTab>
                <PanelTab>
                  <i className="ims-icons-20 icon-icon-notepad-24 mr-1"></i>
                  Tasks
                </PanelTab>
                <PanelWindow tabId={0}>
                  <SupplierOverview />
                </PanelWindow>
                <PanelWindow tabId={1}>
                  <Row>
                    <Col md="12">
                      <DetailsWrapper
                        label={"Service provision:"}
                        iconClass={"tim-icons icon-pencil"}
                        value={supplier?.serviceProvision}
                        labelClass={"pr-2"}
                      />
                    </Col>
                    {/* <Col md="12">
                      <DetailsSectionContent
                        label={"KPI/Objectives:"}
                        value={supplier.kpiObjectives.map((kpi, index) => (
                          <p className="text-secondary">
                            KPI-{index}. {kpi.value}
                          </p>
                        ))}
                      />
                    </Col> */}

                    {supplier?.contractFiles.length > 0 && (
                      <Col md="12">
                        <DetailsWrapper
                          label={"Contracts:"}
                          iconClass={"tim-icons icon-pencil"}
                          value={null}
                          labelClass={"pr-2"}
                        />
                        <Attachments s3Information={supplier.contractFiles}>
                          <ContractsButtons />
                        </Attachments>
                      </Col>
                    )}
                    {supplier?.slaFiles.length > 0 && (
                      <Col md="12">
                        <DetailsWrapper
                          label={"SLAs:"}
                          iconClass={"tim-icons icon-pencil"}
                          value={null}
                          labelClass={"pr-2"}
                        />
                        <Attachments s3Information={supplier.slaFiles}>
                          <SlasButtons />
                        </Attachments>
                      </Col>
                    )}
                    {supplier?.onBoardingFiles.length > 0 && (
                      <Col md="12">
                        <DetailsWrapper
                          label={"Onboarding documents:"}
                          iconClass={"tim-icons icon-pencil"}
                          value={null}
                          labelClass={"pr-2"}
                        />
                        <Attachments s3Information={supplier.onBoardingFiles}>
                          <OnboardingsButtons />
                        </Attachments>
                      </Col>
                    )}
                  </Row>
                </PanelWindow>
                <PanelWindow tabId={2}>
                  <Row>
                    <Col md="12" className="mb-4">
                      <KpiObjectiveForm
                        onSubmit={async (data) => {
                          await addKpi(data);
                        }}
                      />
                      {supplier.kpiObjectives.map((kpi) => (
                        <KpiObjective kpi={kpi} key={kpi._id} />
                      ))}
                    </Col>
                  </Row>
                </PanelWindow>
                <PanelWindow tabId={3}>
                  <IncidentManagement
                    moduleType="suppliers"
                    moduleId={supplier._id}
                  />
                </PanelWindow>
                <PanelWindow tabId={4}>
                  <TaskManagement
                    moduleType="suppliers"
                    module={supplier._id}
                  />
                </PanelWindow>
              </PanelTabs>
            </React.Fragment>
          )
        )}
    </React.Fragment>
  );
};

export default SupplierDrawerDetail;
