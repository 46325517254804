import { DrawerContextProvider } from "ims-ui-kit";
import HardwareTable from "./HardwareTable";

import { HardwareAssetsContextProvider } from "./store";
import { TagsAndCategoriesContextProvider } from "views/tagsAndCategoriesManager/store";

const HardwareAssets = (props) => {
  return (
    <>
      <DrawerContextProvider>
        <HardwareAssetsContextProvider {...props}>
          <TagsAndCategoriesContextProvider
            applicableModules={"hardwareassets"}
          >
            <HardwareTable {...props} />
          </TagsAndCategoriesContextProvider>
        </HardwareAssetsContextProvider>
      </DrawerContextProvider>
    </>
  );
};

export default HardwareAssets;
