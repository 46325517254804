import Loading from "components/Loader/Loading";
import ReactTable from "components/ReactTable/ReactTable";
import useAccess from "hooks/useAccess";
import {
  Button,
  DrawerOpener,
  DrawerRight,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
  useDrawer,
} from "ims-ui-kit";
import React from "react";
import Index from "views/tagsAndCategoriesManager/Index";
import { ACTIONS, EFFECTS, IMS_SERVICES } from "rolesAndPermissions";
import CreatePeopleAsset from "./CreatePeopleAsset";
import PeopleAssetDrawerDetail from "./PeopleAssetDrawerDetail";
import PeopleAssetDrawerForm from "./PeopleAssetDrawerForm";
import PeopleAssetToolbar from "./PeopleAssetToolbar";
import USER_ACTIONS from "./actions";
import { usePeopleAssets } from "./store";
import { useHistory } from "react-router-dom";
import PeopleAssetForm from "./PeopleAssetForm";
import PeopleFilter from "./PeopleFilter";
const defaultdata = [["No data found"]];

const ReactTables = ({ ...props }) => {
  let { closeDrawer, openDrawer } = useDrawer();
  let {
    alert,
    peopleAssets,
    processing,
    pathname,
    fetchPeoples,
    PeopleQueryTools,
    handlePeopleDelete,
    warningWithConfirmMessage,
    visitPeople,
    createPeople,
    people,
    ...rest
  } = usePeopleAssets();
  let { authUser, authGlobalAccess, authAdminAccess, entityAccessControl } =
    useAccess();
  peopleAssets = peopleAssets ? peopleAssets : defaultdata;
  let history = useHistory();

  const data = React.useMemo(
    () =>
      peopleAssets.map((data, key) => {
        return {
          id: data._id,
          reference: data.reference,
          name: data.name,
          role: data.role,
          responsibility: data.responsibility,
          group: data.group && data.group.name,
          data: data,
          actions: (
            <>
              <UncontrolledDropdown size="sm" direction="right">
                <DropdownToggle
                  outline
                  onClick={(e) => {
                    e.stopPropagation();
                    visitPeople(data);
                  }}
                  data-display="static"
                  className="border border rounded-circle"
                >
                  <i className="fa-solid fa-ellipsis-h" />
                </DropdownToggle>
                <DropdownMenu>
                  <DrawerOpener
                    drawerId="people-asset-detail"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <DropdownItem
                      id="detail"
                      tooltip="View Details"
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push(`/admin/inventory/people/${data._id}`);
                      }}
                    >
                      Details
                    </DropdownItem>
                  </DrawerOpener>
                  {authUser({
                    service: IMS_SERVICES.INVENTORY,
                    action: ACTIONS.DELETE,
                    effect: EFFECTS.ALLOW,
                  }) &&
                    (authAdminAccess() ||
                      entityAccessControl({
                        users: data.created.by ? [data.created.by._id] : [],
                        effect: "Allow",
                      })) && (
                      <DropdownItem
                        onClick={(e) => {
                          e.stopPropagation();
                          warningWithConfirmMessage(
                            "This asset will be deleted",
                            () => {
                              handlePeopleDelete(data);
                            }
                          );
                        }}
                        id="delete"
                        tooltip="Delete"
                      >
                        {processing.action === "delete" &&
                        processing.id === data._id ? (
                          <Spinner size="sm" />
                        ) : (
                          "Delete"
                        )}
                      </DropdownItem>
                    )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          ),
        };
      }),
    [processing]
  );
  return (
    <>
      {alert}
      <div className="content">
        {processing[USER_ACTIONS.LOAD_PEOPLES].status && <Loading />}
        <ReactTable
          data={data}
          {...rest}
          resizable={false}
          columns={[
            {
              Header: "Reference",
              accessor: "reference",
            },
            {
              Header: authGlobalAccess() ? "Business unit" : "Business unit",
              accessor: "group",
            },
            {
              Header: "Name",
              accessor: "name",
            },
            {
              Header: "Role",
              accessor: "role",
            },
            {
              Header: "Responsibility",
              accessor: "responsibility",
            },
            {
              Header: "Actions",
              accessor: "actions",
              sortable: false,
              filterable: false,
            },
          ]}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
          isFilterable
          isSearchable
          peopleAssets={peopleAssets}
          pathname={pathname}
          onPageChange={fetchPeoples}
          tableToolbar={
            <React.Fragment>
              <CreatePeopleAsset />
              {"  "}
              <DrawerOpener drawerId="tags">
                <Button
                  color="primary"
                  outline
                  size="md"
                  className="shadow-sm--hover ms-3"
                >
                  <i className="ims-icons icon-icon-tag-24 me-1 p-0"></i>
                  {"  "} Categories
                </Button>
              </DrawerOpener>
            </React.Fragment>
          }
          filterToolbar={<PeopleFilter />}
          pagination={PeopleQueryTools.toolState.pagination}
          title={"People assets"}
          {...PeopleQueryTools}
          rowProps={function (row) {
            return {
              onClick: function (e) {
                visitPeople(row.original.data);
                openDrawer("people-asset-detail");
              },
            };
          }}
        />
        <DrawerRight
          onDrawerClose={() => {
            visitPeople(null);
          }}
          toolbar={<PeopleAssetToolbar />}
          drawerId="people-asset-detail"
        >
          <PeopleAssetDrawerDetail />
        </DrawerRight>
        <DrawerRight drawerId="edit-people-form">
          {people && <PeopleAssetDrawerForm />}
        </DrawerRight>
        <DrawerRight drawerId="create-people-asset-form">
          <PeopleAssetForm
            drawerView={true}
            processing={processing}
            onSubmit={async (data) => {
              await createPeople(data);
              closeDrawer("create-people-asset-form");
              openDrawer("people-asset-detail");
            }}
          />
        </DrawerRight>
        <DrawerRight drawerId="tags">
          <Index applicableModules="peopleassets" />
        </DrawerRight>
      </div>
    </>
  );
};

export default ReactTables;
