import { Button } from "ims-ui-kit";

const Index = ({ children, onSelect, disabled = false }) => {
  return (
    <span className="ims-faded-button">
      <Button disabled={disabled} onClick={onSelect}>
        {children}
      </Button>
    </span>
  );
};

export default Index;
