import useAlerts from "hooks/useAlerts";
import useModal from "hooks/useModal";
import { Card, CardBody, Col, Row } from "ims-ui-kit";
import React from "react";
import { Radar } from "react-chartjs-2";
import { Link } from "react-router-dom";
import DigitalMatrices from "../orgnaisationDashboard/BusinessDigitalMatrix/DigitalMaturityMatrices";
import ImsRadarChart from "components/charts/ImsRadarChart";
import useAccess from "hooks/useAccess";

const DigitalMaturity = ({ digitalMaturity, digitalMaturityScore, HoS }) => {
  let { authSuperUser } = useAccess();
  let { activateView, Modal } = useModal();
  let { alert, warningWithConfirmMessage, successAlert } = useAlerts();
  let calculateDigitalScore = () => {
    let sum = 0;
    digitalMaturityScore &&
      digitalMaturityScore.map((score) => (sum += score.point));
    return sum;
  };

  return (
    <React.Fragment>
      {alert}
      {/* {!HoS && (
        <h5 className="card-category">
          <Link
            to="#"
            onClick={() => {
              activateView();
            }}
            className="module-link"
          >
            All business units
          </Link>
        </h5>
      )} */}
      <Row>
        <Col sm="8" className="">
          <ImsRadarChart
            data={digitalMaturity.data}
            options={digitalMaturity.options}
          />

          {/* <Radar
            data={digitalMaturity.data}
            options={digitalMaturity.options}
          /> */}
        </Col>
        <Col sm="4">
          <Card variant="gradient" color="danger" hover={false}>
            <CardBody>
              <div className="d-flex align-items-center">
                <i
                  style={{
                    fontSize: "8px",
                  }}
                  class="fa-solid fa-circle text-primary"
                ></i>{" "}
                <span
                  style={{
                    color: "#152536",
                    fontSize: "16px",
                    marginLeft: "8px",
                  }}
                >
                  Digital Maturity
                </span>
              </div>
              <h4
                style={{
                  fontSize: "20px",
                }}
                className="mt-3"
              >
                {calculateDigitalScore()}/28
              </h4>
            </CardBody>
          </Card>
          {authSuperUser() && (
            <p
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                activateView();
              }}
              className="subtitle-primary text-center mt-2"
            >
              View All
              <i className="fa-solid fa-chevron-right ml-2"></i>
            </p>
          )}
        </Col>
        {/* <Col md="7" className="">
              <>
                <h5 className="text-primary">
                  Digital maturity score:{" "}
                  <span className="text-warning">
                    {calculateDigitalScore()}/28
                  </span>
                </h5>
              </>
              <Table className="mt-4 pt-3" borderless>
                <thead className="text-primary">
                  <tr>
                    <th>Status</th>
                    <th className="">Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span className="text-success">Optimised</span>
                    </td>
                    <td className="">
                      Automated and fully optimised workflow across the
                      organisation.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="text-info">Active</span>
                    </td>
                    <td className="">
                      Using digital solution to manage workflow
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="text-warning">Digital</span>
                    </td>
                    <td className="">
                      Implemented a digital solution for the workflow.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="text-danger">Manual</span>
                    </td>
                    <td className="">
                      Workflow is a manual process within the
                      business/organisation
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col> */}
      </Row>
      <Modal title="Digital Maturity">
        <DigitalMatrices />
      </Modal>
    </React.Fragment>
  );
};

export default DigitalMaturity;
