import ReactTable from "components/ReactTable/ReactTable";
import useAccess from "hooks/useAccess";
import useAlerts from "hooks/useAlerts";
import {
  DrawerRight,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
  useDrawer,
} from "ims-ui-kit";
import React from "react";
import { useHistory } from "react-router-dom";
import { ACTIONS, EFFECTS, IMS_SERVICES } from "rolesAndPermissions";
import BadgeStatus from "views/shared/StatusMapper/BadgeStatus";
import TimeDateComponent from "views/shared/TimeDateComponent";
import CampaignDrawerDetail from "./CampaignDrawerDetail";
import CampaignDrawerForm from "./CampaignDrawerForm";
import CampaignForm from "./CampaignForm";
import CampaignToolBar from "./CampaignToolBar";
import CreateCampaign from "./CreateCampaign";
import USER_ACTIONS from "./actions";
import { useCampaign } from "./store";
import Loading from "components/Loader/Loading";
const defaultdata = [["No data found"]];

const CampaignTable = () => {
  let {
    campaigns: dataTable,
    processing,
    visitCampaign,
    visitingCampaign,
    deleteCampaign,
    saveAndSendCampaign,
    createCampaign,
    fetchCampaigns,
    toolState,
    ...rest
  } = useCampaign();
  let { authUser } = useAccess();
  let { alert, warningWithConfirmMessage, successAlert } = useAlerts();
  let { openDrawer, closeDrawer } = useDrawer();

  let history = useHistory();
  dataTable = dataTable ? dataTable : defaultdata;
  const data = React.useMemo(
    () =>
      dataTable.map((data, key) => {
        return {
          id: data?._id,
          reference: `${data.reference}`,
          group: data?.group?.name,
          target: data.target.map((tr) => `${tr} `),
          subject: data.subject,
          status: data.status,
          createdOn: (
            <TimeDateComponent date={data.created && data.created.on} />
          ),
          data: data,
          created_by: data.created?.by?.name,
          actions: (
            <UncontrolledDropdown size="sm" direction="right">
              <DropdownToggle
                outline
                onClick={(e) => {
                  e.stopPropagation();
                }}
                data-display="static"
                className="border border rounded-circle"
              >
                <i className="fa-solid fa-ellipsis-h" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={(e) => {
                    history.push(`/admin/email-campaign/${data._id}`);
                  }}
                  id="detail"
                  tooltip="View Details"
                >
                  Details
                </DropdownItem>
                {authUser({
                  service: IMS_SERVICES.CRM,
                  action: ACTIONS.DELETE,
                  effect: EFFECTS.ALLOW,
                }) &&
                  data.status !== "Sent" && (
                    <DropdownItem
                      onClick={(e) => {
                        warningWithConfirmMessage(
                          "This campaign will be deleted",
                          () => {
                            deleteCampaign(data);
                          }
                        );
                        e.stopPropagation();
                      }}
                      name="delete"
                      id="delete"
                      tooltip="Delete"
                    >
                      {processing[USER_ACTIONS.REMOVE_EMAIL].status &&
                      processing[USER_ACTIONS.REMOVE_EMAIL].id === data._id ? (
                        <Spinner size="sm" />
                      ) : (
                        "Delete"
                      )}
                    </DropdownItem>
                  )}
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        };
      }),
    [processing]
  );
  return (
    <>
      {alert}
      <div className="content">
        {processing[USER_ACTIONS.LOAD_EMAILS].status && <Loading />}
        <ReactTable
          data={data}
          {...rest}
          resizable={false}
          columns={[
            {
              Header: "Reference",
              accessor: "reference",
            },
            {
              Header: "Business unit",
              accessor: "group",
            },
            {
              Header: "Subject",
              accessor: "subject",
            },
            {
              Header: "Target",
              accessor: "target",
            },
            {
              Header: "Status",
              accessor: "status",
              Cell: (item) => <BadgeStatus status={item.value} />,
            },
            {
              Header: "Created on",
              accessor: "createdOn",
            },
            {
              Header: "Created by",
              accessor: "created_by",
            },

            {
              Header: "Actions",
              accessor: "actions",
              sortable: false,
              filterable: false,
            },
          ]}
          tableToolbar={<CreateCampaign />}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
          isFilterable
          isSearchable
          title="Email campaigns"
          onPageChange={fetchCampaigns}
          pagination={toolState.pagination}
          rowProps={function (row) {
            return {
              onClick: function (e) {
                openDrawer("campaign-detail");
                visitCampaign(row.original.data);
              },
            };
          }}
        />
        <DrawerRight
          drawerId="campaign-detail"
          onDrawerClose={() => {
            visitCampaign(null);
          }}
          toolbar={<CampaignToolBar />}
        >
          {<CampaignDrawerDetail />}
        </DrawerRight>
        <DrawerRight drawerId="edit-campaign-form">
          {visitingCampaign && <CampaignDrawerForm />}
        </DrawerRight>
        <DrawerRight drawerId="create-campaign">
          <CampaignForm
            drawerView={true}
            onSubmit={async (data) => {
              await createCampaign(data);
            }}
            onCreateAndSend={async (data) => {
              await saveAndSendCampaign(data);
            }}
          />
        </DrawerRight>
      </div>
    </>
  );
};

export default CampaignTable;
