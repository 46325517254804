import Loading from "components/Loader/Loading";
import ReactTable from "components/ReactTable/ReactTable";
import NotificationContext from "contexts/notificationContext";
import useAccess from "hooks/useAccess";
import useAlert from "hooks/useAlerts";
import {
  Badge,
  DrawerRight,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
} from "ims-ui-kit";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import { ACTIONS, EFFECTS, IMS_SERVICES } from "rolesAndPermissions";
import USER_ACTIONS from "../repositories/store/actions";
import RepositoryForm from "../repository/RepositoryForm";
import CreateRepository from "./CreateRepository";
import { useRepositories } from "./store";
import filters from "./filters/filters";
const defaultdata = [["No data found"]];
const RepositoriesTable = ({ props }) => {
  let { authUser, authGlobalAccess, authAdminAccess, entityAccessControl } =
    useAccess();
  let {
    repositories,
    toolState,
    queryHandlers,
    createRepository,
    handleDelete,
    processing,
    isFetchingTable,
    fetchRepositories,
    ...rest
  } = useRepositories();
  let notify = React.useContext(NotificationContext);
  const history = useHistory();
  let { alert, warningWithConfirmMessage, popUpAlerts } = useAlert();

  //This table contains the next date of review
  const data = React.useMemo(
    () =>
      repositories.map((data, key) => {
        return {
          id: data._id,
          ID: data.reference,
          business_function: data.group ? data.group.name : "N/A",
          document_name: data.name || "Untitled repository",
          owners: (
            <p>
              {data?.owners?.map((owner) => (
                <Badge color={"primary"}>{owner.name}</Badge>
              ))}
            </p>
          ),
          interval: "Yearly",
          nextReview: moment(
            new Date(data.createdAt).getTime() + 86400 * 365 * 1000
          ).format("DD/MM/YYYY"),
          data: data,
          activateView: () => {
            history.push(`/admin/document-repositories/${data._id}`);
          },
          actions: (
            <UncontrolledDropdown size="sm" direction="right">
              <DropdownToggle
                data-display="static"
                outline
                onClick={(e) => e.stopPropagation()}
                className="border border rounded-circle"
              >
                <i className="fa-solid fa-ellipsis-h" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    history.push(`/admin/document-repositories/${data._id}`);
                  }}
                  id="detail"
                  tooltip="View Details"
                >
                  Details
                </DropdownItem>
                {authUser({
                  service: IMS_SERVICES.DOCUMENT_MANAGEMENT,
                  action: ACTIONS.DELETE,
                  effect: EFFECTS.ALLOW,
                }) &&
                (authAdminAccess() ||
                  entityAccessControl({
                    users: [data.created?.by?._id, data.owner?._id],
                    effect: "Allow",
                  })) ? (
                  <DropdownItem
                    tooltip="Delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      warningWithConfirmMessage(
                        `Repository ${data.reference} will be moved to recycle bin`,
                        () => {
                          handleDelete(data._id);
                        }
                      );
                    }}
                    name="delete"
                    id="delete"
                  >
                    {processing[USER_ACTIONS.SOFT_DELETE_REPOSITORY].status &&
                    processing[USER_ACTIONS.SOFT_DELETE_REPOSITORY].id ===
                      data._id ? (
                      <Spinner size="sm" />
                    ) : (
                      "Move to recycle bin"
                    )}
                  </DropdownItem>
                ) : null}
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        };
      }),
    [processing, repositories]
  );

  return (
    <>
      {alert}
      <div className="content">
        {isFetchingTable() && <Loading />}
        <ReactTable
          data={data}
          resizable={false}
          columns={[
            {
              Header: "Reference",
              accessor: "ID",
              width: 90,
              minWidth: 25,
            },
            {
              Header: authGlobalAccess() ? "Business unit" : "Business unit",
              accessor: "business_function",
            },
            {
              Header: "Repository name",
              accessor: "document_name",
            },
            {
              Header: "Owner(s)",
              accessor: "owners",
              width: 300,
              minWidth: 250,
            },
            {
              Header: "Review interval",
              accessor: "interval",
            },
            {
              Header: "Next review date",
              accessor: "nextReview",
            },
            {
              Header: "Actions",
              accessor: "actions",
              sortable: false,
              filterable: false,
            },
          ]}
          defaultPageSize={10}
          filters={filters}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
          isFilterable
          isSearchable
          {...queryHandlers}
          {...props}
          {...rest}
          pagination={toolState.pagination}
          onPageChange={fetchRepositories}
          tableToolbar={<CreateRepository />}
          title={"Repositories"}
          rowProps={function (row) {
            return {
              onClick: () =>
                history.push(
                  `/admin/document-repositories/${row.original?.id}`
                ),
            };
          }}
        />
      </div>
      <DrawerRight drawerId="create-repository">
        <RepositoryForm
          drawerView={true}
          onSubmit={async (data) => {
            await createRepository(data);
          }}
        />
      </DrawerRight>
    </>
  );
};

export default RepositoriesTable;
