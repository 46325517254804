import { Card, CardBody, Col, Row } from "ims-ui-kit";
import { useContext } from "react";
import { ViewContext } from "./contexts/ViewContext";
import SwitchButton from "./SwitchButton";

const ViewSwitcher = ({ children, ...rest }) => {
  let { editMode, canSwitch, viewTitle } = useContext(ViewContext);
  return (
    <Card className="shadow-none">
      <CardBody>
        <Row className="">
          <Col md="12">
            <h4 className="font-weight-bold my-2 pb-2 border-bottom">
              {viewTitle}
            </h4>
          </Col>
        </Row>
        {canSwitch && (
          /**
           * TODO: this switch button needs be dynamic enough to
           * be controlled by a property
           */
          <SwitchButton size="sm">
            {editMode ? (
              <span className="font-weight-bold text-danger">Cancel</span>
            ) : (
              <span className="font-weight-bold text-info">Edit</span>
            )}
          </SwitchButton>
        )}
        {children}
      </CardBody>
    </Card>
  );
};

export default ViewSwitcher;
