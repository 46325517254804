import { Col, Row, Button } from "ims-ui-kit";
import { useHistory } from "react-router-dom";
import EditRepository from "./EditRepository";
import useRepository from "./store/useRepository";
const RepositoryHeader = ({}) => {
  let { repository, totalFiles } = useRepository();
  let history = useHistory();
  return (
    <Row>
      <Col lg={6}>
        <div className="repo-info">
          <p className="font-weight-600 text-dark mb-2">{repository?.name}</p>
          <p className="font-weight-300">
            Reference: {repository?.reference}
            <span className="ml-2">{repository?.group?.name || "N/A"}</span>
          </p>
        </div>
      </Col>
      <Col lg={6} className="d-flex justify-content-lg-end align-items-center">
        <Button onClick={() => history.push("/admin/document-repositories")}>
          <i className="fa-solid fa-arrow-left" />
        </Button>
        <EditRepository />
      </Col>
      <Col lg={12} className="d-flex justify-content-lg-end align-items-center">
        <div className="d-flex justify-content-center align-items-center ml-4">
          <strong>{totalFiles}</strong>
          <p className="mb-0 ml-1">files total</p>
        </div>
      </Col>
    </Row>
  );
};

export default RepositoryHeader;
