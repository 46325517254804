import ReactTable from "components/ReactTable/ReactTable";
import useAccess from "hooks/useAccess";
import useAlert from "hooks/useAlerts";
import {
  Button,
  DrawerRight,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
  useDrawer,
} from "ims-ui-kit";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import { ACTIONS, EFFECTS, IMS_SERVICES } from "rolesAndPermissions";
import DocumentListOpener from "views/documentManagement/searchableList/components/DocumentListOpener";
import SearchableDocument from "views/documentManagement/searchableList/components/Index";
import BadgeStatus from "views/shared/StatusMapper/BadgeStatus";
import TimeDateComponent from "views/shared/TimeDateComponent";
import TaskForm from "views/taskManagement/TaskForm";
import { useTask } from "views/taskManagement/store";
import CreateReview from "./CreateReview";
import ReviewDrawerDetail from "./ReviewDrawerDetail";
import ReviewDrawerForm from "./ReviewDrawerForm";
import ManagmentReviewForm from "./ReviewForm";
import ReviewToolBar from "./ReviewToolBar";
import filters from "./filters";
import { useSchedule } from "./store";
import tables from "./tables";
import USER_ACTIONS from "./actions";
import Loading from "components/Loader/Loading";
import ReviewFilter from "./ReviewFilter";

const defaultdata = [["No data found"]];

const ReviewTable = ({ ...props }) => {
  let {
    managementReviews: dataTable,
    processing,
    visitingReview,
    fetchReviews,
    deleteReview,
    visitReview,
    ReviewQueryTools,
    isCompletedManagementReview,
    createReview,
    reloadReview,
    ...rest
  } = useSchedule();
  let { handleCreateTask } = useTask();
  let { authUser, entityAccessControl } = useAccess();
  let history = useHistory();
  let { openDrawer, closeDrawer } = useDrawer();
  let { alert, warningWithConfirmMessage } = useAlert();

  dataTable = dataTable ? dataTable : defaultdata;
  const data = React.useMemo(
    () =>
      dataTable.map((data, key) => {
        return {
          id: data._id,
          ID: "MR-" + data.ID,
          meeting_title: data.title,
          schedule_date: <TimeDateComponent date={data.date} />,
          completed_date: data.completed.status && (
            <TimeDateComponent date={data.completed?.on} />
          ),
          intervals: data.interval,
          data: data,
          time: moment(data.date).format("HH:mm"),
          status: (
            <BadgeStatus
              status={data.completed.status ? "Completed" : "Scheduled"}
            />
          ),
          actions: (
            <>
              <UncontrolledDropdown size="sm" direction="right">
                <DropdownToggle
                  outline
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  data-display="static"
                  className="border border rounded-circle"
                >
                  <i className="fa-solid fa-ellipsis-h" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={(e) => {
                      history.push(`/admin/management-reviews/${data._id}`);
                      e.stopPropagation();
                    }}
                    id="detail"
                    tooltip="View Details"
                  >
                    Details
                  </DropdownItem>
                  {!data.completed.status &&
                    authUser({
                      service: IMS_SERVICES.MANAGEMENT_REVIEW,
                      action: ACTIONS.DELETE,
                      effect: EFFECTS.ALLOW,
                    }) &&
                    entityAccessControl({
                      users: data.created.by ? [data.created.by._id] : [],
                      effect: "Allow",
                    }) && (
                      <DropdownItem
                        onClick={(e) => {
                          warningWithConfirmMessage(
                            "This management review will be deleted",
                            () => {
                              deleteReview(data);
                            }
                          );
                          e.stopPropagation();
                        }}
                        name="delete"
                        id="delete"
                        tooltip="Delete"
                      >
                        {processing.action === "delete" &&
                        processing.id === data._id ? (
                          <Spinner size="sm" />
                        ) : (
                          "Delete"
                        )}
                      </DropdownItem>
                    )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          ),
        };
      }),
    [processing]
  );
  let columns = React.useMemo(
    () => ReviewQueryTools.toolState.filter.tableState || tables.default,
    [ReviewQueryTools.toolState]
  );
  return (
    <>
      {alert}
      <SearchableDocument moduleTypes={["managementreviews"]} />
      <div className="content">
        {processing[USER_ACTIONS.LOAD_REVIEWS].status && <Loading />}
        <ReactTable
          data={data}
          {...rest}
          resizable={false}
          columns={columns.slice()}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
          title={"Management reviews"}
          tableToolbar={
            <React.Fragment>
              <CreateReview />
              {authUser({
                service: IMS_SERVICES.DOCUMENT_MANAGEMENT,
                action: ACTIONS.READ,
                effect: EFFECTS.ALLOW,
              }) && (
                <DocumentListOpener>
                  <Button
                    color="primary"
                    outline
                    size="md"
                    className="shadow-sm--hover ms-3"
                  >
                    Documents
                  </Button>
                </DocumentListOpener>
              )}
            </React.Fragment>
          }
          filterToolbar={<ReviewFilter />}
          isFilterable
          isSearchable
          filters={filters}
          rowProps={function (row) {
            return {
              onClick: function (e) {
                visitReview(row.original?.data);
                openDrawer("review-detail");
              },
            };
          }}
          {...ReviewQueryTools}
          {...props}
          onPageChange={fetchReviews}
          pagination={ReviewQueryTools.toolState.pagination}
        />
        <DrawerRight
          drawerId="review-detail"
          onDrawerClose={() => {
            visitReview(null);
          }}
          toolbar={<ReviewToolBar />}
        >
          {<ReviewDrawerDetail />}
        </DrawerRight>
        <DrawerRight drawerId="edit-review-form">
          {visitingReview && <ReviewDrawerForm />}
        </DrawerRight>
        <DrawerRight drawerId="add-task-form">
          {visitingReview && (
            <TaskForm
              drawerView={true}
              module={visitingReview._id}
              moduleType="managementreviews"
              onSubmit={async (data) => {
                await handleCreateTask(data);
                closeDrawer("add-task-form");
                reloadReview();
              }}
            />
          )}
        </DrawerRight>
        <DrawerRight drawerId="create-review">
          <ManagmentReviewForm
            drawerView={true}
            onSubmit={async (data) => {
              await createReview(data);
              closeDrawer("create-review");
              openDrawer("review-detail");
            }}
          />
        </DrawerRight>
      </div>
    </>
  );
};

export default ReviewTable;
