const USER_ACTIONS = {
    LOAD_NOTIFICATIONS: "load-notifications",
    UPDATE_READ_STATUS: 'update-read-status',
    UPDATE_SENT_STATUS: "update-sent-status",
    UPDATE_POPUP_STATUS: "update-popup-status",
    SEND_REPORT: "send-report",
    CLOCK_IN: "clock-in",
    CLOCK_OUT: "clock-out",
    PAUSE_UNPAUSE:"pause-unpause"
}
export default USER_ACTIONS