import React from "react";
import { ReactNotifications } from "react-notifications-component";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Redirect, Switch, useHistory, useLocation } from "react-router-dom";
import AlertContextProvider from "contexts/AlertContext";
import NotificationContext from "contexts/notificationContext";
import Footer from "components/Footer/Footer";
import AdminNavbar from "components/Navbars/Admin/AdminNavbar";
import ProtectedRoute from "components/Protected/ProtectedRout";
import { OmniplexJourneyProvider } from "components/omniplexGuide/index";
import SuperGlobalAdminProvider from "contexts/SuperGlobalContext";
import useAccess from "hooks/useAccess";
import useNotification from "hooks/useNotification";
import {
  Menu,
  MenuItem,
  Sidebar,
  SubMenu,
  menuClasses,
  sidebarClasses,
} from "react-pro-sidebar";
import routes from "routes.js";
import { useApplication } from "stores/applicationStore";
import allRoutes from "../../routes";

const menuStyles = {
  padding: "7px",
  [`.${menuClasses.button}`]: {
    borderRadius: "8px",
    height: "auto",
    paddingRight: "15px",
    paddingLeft: "15px",
    paddingBottom: "10px",
    paddingTop: "10px",
    color: "#152536",
    "&:hover": {
      backgroundColor: "#E4F4FF !important",
      color: "#1D8BF8",
    },
    ["&." + menuClasses.active]: {
      color: "#1D8BF8",
      fontWeight: "bold",
    },
  },
};

const Admin = (props) => {
  const [sidebarCollapsed, setSidebarCollapsed] = React.useState(false);
  const [sidebarToggled, setSidebarToggled] = React.useState(false);
  const location = useLocation();
  const { notify } = useNotification();
  const {
    authUser,
    authAdditionalModulesLicense,
    authComplianceToolkitLicense,
    authCarboCalcLicense,
  } = useAccess();
  const history = useHistory();
  const { membershipData } = useApplication();
  console.log(process.env.REACT_APP_CARBO_CARLC_CLIENT_URL)

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <ProtectedRoute
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            licenseRequirements={prop.licenseRequirements || null}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getAuthorisedRouts = (routes) => {
    return routes.filter((prop) => {
      if (!membershipData?.organization?.isCustomer) return false;
      if (prop.collapse) {
        if (prop.accessPolicy && !authUser(prop.accessPolicy || []))
          return false;
        if (
          prop.licenseRequirements?.additionalModule &&
          !authAdditionalModulesLicense(
            prop.licenseRequirements?.additionalModule
          )
        )
          return false;
        if (
          prop.licenseRequirements?.complianceTool &&
          !authComplianceToolkitLicense(
            prop.licenseRequirements?.complianceTool
          )
        )
          return false;
        prop.views = getAuthorisedRouts(prop.views);
        if (!prop.views.length) return false;
      }
      if (
        prop.licenseRequirements?.additionalModule &&
        !authAdditionalModulesLicense(
          prop.licenseRequirements?.additionalModule
        )
      )
        return false;
      if (
        prop.licenseRequirements?.complianceTool &&
        !authComplianceToolkitLicense(prop.licenseRequirements?.complianceTool)
      )
        return false;
      return authUser(prop.accessPolicy || []);
    });
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i];
        }
      }
    }
    return activeRoute;
  };

  const collapseSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };
  const handleSubmenuClick = () => {
    if (sidebarCollapsed) {
      setSidebarCollapsed(false);
    }
  };
  return (
    <OmniplexJourneyProvider>
      <SuperGlobalAdminProvider>
        <AlertContextProvider>
          <ReactNotifications />
          <NotificationContext.Provider value={notify}>
            <AdminNavbar
              {...props}
              onSibebarToggle={() => setSidebarToggled((t) => !t)}
              route={getActiveRoute(routes)}
            />
            <button
              color="primary"
              size="sm"
              style={{
                position: "absolute",
                top: "80px",
                left: sidebarCollapsed ? "66px" : "234px",
                zIndex: "1040",
                border: "none",
                borderRadius: "4px",
                backgroundColor: "#0098f0",
                color: "white",
                fontWeightL: "bold",
                // transition: "left 0.15s ease-in-out",
              }}
              onClick={() => collapseSidebar()}
              className="d-none d-md-inline"
            >
              {sidebarCollapsed ? (
                <i className="gns-icons-16 icon-icon-caretdoubleright-24" />
              ) : (
                <i className="gns-icons-16 icon-icon-caretdoubleleft-24" />
              )}
            </button>
            <div className="main-layout">
              <div className="main-content">
                <Sidebar
                  collapsed={sidebarCollapsed}
                  toggled={sidebarToggled}
                  customBreakPoint="764px"
                  onBackdropClick={() => setSidebarToggled(false)}
                  rootStyles={{
                    position: "sticky",
                    top: "66px",
                    height: "calc(100vh - 66px)",
                    [`.${sidebarClasses.container}`]: {
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      backgroundColor: "white",
                      position: "relative",
                      overflowY: "auto",
                      scrollbarWidth: "none",
                      "-ms-overflow-style": "none",
                    },
                    [`&.${sidebarClasses.broken}`]: {
                      zIndex: 1051,
                    },
                    [`.ps-submenu-expand-icon`]: {
                      display: sidebarCollapsed ? "none" : "block",
                    },
                  }}
                >
                  <Menu rootStyles={menuStyles}>
                    {allRoutes.map((menuitem) =>
                      menuitem.collapse ? (
                        <SubMenu
                          label={menuitem.name}
                          onClick={handleSubmenuClick}
                          active={menuitem?.views
                            .map((view) => view.layout + view.path)
                            .includes(location.pathname)}
                          icon={<i className={`${menuitem.icon}`} />}
                        >
                          {menuitem?.views?.map(
                            (view) =>
                              !view.invisible && (
                                <MenuItem
                                  key={view.name}
                                  active={
                                    location.pathname ===
                                    view.layout + view.path
                                  }
                                  className={"pl-3"}
                                  icon={<i className={`${view.icon}`} />}
                                  onClick={() =>
                                    history.push(view.layout + view.path)
                                  }
                                >
                                  {view.name}
                                </MenuItem>
                              )
                          )}
                        </SubMenu>
                      ) : (
                        !menuitem.invisible && (
                          <MenuItem
                            active={
                              location.pathname ===
                              menuitem.layout + menuitem.path
                            }
                            key={menuitem.path}
                            className="p-0"
                            icon={<i className={`${menuitem.icon}`} />}
                            onClick={() =>
                              history.push(menuitem.layout + menuitem.path)
                            }
                          >
                            {menuitem.name}
                          </MenuItem>
                        )
                      )
                    )}
                    {authCarboCalcLicense() && (
                      <MenuItem
                        className="p-0"
                        icon={
                          <i className={`ims-icons-20 icon-icon-cloud-20`} />
                        }
                        onClick={() =>
                          window.open(
                            process.env.REACT_APP_CARBO_CARLC_CLIENT_URL,
                            "_blank"
                          )
                        }
                      >
                        Carbon calculator
                      </MenuItem>
                    )}
                  </Menu>
                </Sidebar>
                <div className="main-container">
                  <div className="content-area">
                    <Switch>
                      {getRoutes(routes)}
                      <Redirect from="*" to="/admin/dashboard" />
                    </Switch>
                  </div>
                  <Footer fluid default />
                </div>
              </div>
            </div>
          </NotificationContext.Provider>
        </AlertContextProvider>
      </SuperGlobalAdminProvider>
    </OmniplexJourneyProvider>
  );
};

export default Admin;
