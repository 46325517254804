import classNames from "classnames";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from "ims-ui-kit";
import React from "react";
import { truncate } from "utils/truncate";

const InteractionAnalytics = ({ interactions }) => {
  let [interactionDuration, setInteractionDuration] = React.useState("Weekly");
  return (
    <div className="content">
      <Row>
        <Col xs="12">
          <Card variant="light">
            <CardHeader>
              <Row>
                <Col className="text-left" sm="6">
                  <h4
                    style={{
                      fontSize: "20px",
                    }}
                  >
                    {interactionDuration} interaction overview
                  </h4>
                </Col>
                <Col sm="6">
                  <ButtonGroup
                    className="btn-group-toggle float-right"
                    data-toggle="buttons"
                  >
                    <Button
                      size="sm"
                      color="primary"
                      outline
                      className={classNames("inline-block mt-3 ml-3 rounded", {
                        active: interactionDuration === "Weekly",
                      })}
                      onClick={() => setInteractionDuration("Weekly")}
                    >
                      <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                        Weekly
                      </span>
                      <span className="d-block d-sm-none">
                        <i className="fas fa-server" />
                      </span>
                    </Button>
                    <Button
                      size="sm"
                      color="primary"
                      outline
                      className={classNames("inline-block mt-3 ml-3 rounded", {
                        active: interactionDuration === "Monthly",
                      })}
                      onClick={() => setInteractionDuration("Monthly")}
                    >
                      <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                        Monthly
                      </span>
                      <span className="d-block d-sm-none">
                        <i className="tim-icons icon-laptop" />
                      </span>
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="mt-3">
              {interactionDuration === "Weekly" ? (
                <Row>
                  <Col xl="5">
                    <Row>
                      <Col lg="12" md="12">
                        <Card
                          style={{ height: "195px" }}
                          variant="gradient"
                          color="primary"
                        >
                          <CardBody>
                            <div className="">
                              <i
                                style={{
                                  width: "8px",
                                  height: "8px",
                                }}
                                class="fa-solid fa-circle text-primary mr-3"
                              ></i>{" "}
                              Total interaction
                            </div>
                            <hr />

                            <CardBody>
                              <Row>
                                <Col xs="10">
                                  <div className="numbers">
                                    <h1>
                                      {interactions.weekly?.totalInteractions}
                                    </h1>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col lg="12" md="12">
                        <Card
                          style={{ height: "195px" }}
                          variant="gradient"
                          color="danger"
                        >
                          <CardBody>
                            <div className="">
                              <i
                                style={{
                                  width: "8px",
                                  height: "8px",
                                }}
                                class="fa-solid fa-circle text-danger mr-3"
                              ></i>{" "}
                              Customer engaged
                            </div>
                            <hr />
                            <CardBody>
                              <Row>
                                <Col xs="10">
                                  <div className="numbers">
                                    <h1>
                                      {interactions.weekly?.customersEngaged}
                                    </h1>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl="7">
                    <Card variant="light">
                      <CardHeader>
                        <span className="card-category font-size-subtitle-2">Top customers engaged</span>
                      </CardHeader>
                      <CardBody>
                        <Table>
                          <thead className="text-primary">
                            <tr key={"1"}>
                              <th className="">Customer name</th>
                              <th className="text-right">
                                Number of interactions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {interactions.weekly?.topCustomersEngaged.map(
                              (interaction) => (
                                <tr key={interaction._id}>
                                  <td className="">
                                    {truncate(interaction.name, 13)}
                                  </td>
                                  <td className=" text-right">
                                    {interaction.interactionCount}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xl="5">
                    <Row>
                      <Col lg="12" md="12">
                        <Card
                          style={{ height: "195px" }}
                          variant="gradient"
                          color="primary"
                        >
                          <CardBody>
                            <div className="">
                              <i
                                style={{
                                  width: "8px",
                                  height: "8px",
                                }}
                                class="fa-solid fa-circle text-primary mr-3"
                              ></i>{" "}
                              Total interaction
                            </div>
                            <hr />

                            <CardBody>
                              <Row>
                                <Col xs="10">
                                  <div className="numbers">
                                    <h1>
                                      {interactions.monthly?.totalInteractions}
                                    </h1>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col lg="12" md="12">
                        <Card
                          style={{ height: "195px" }}
                          variant="gradient"
                          color="danger"
                        >
                          <CardBody>
                            <div className="">
                              <i
                                style={{
                                  width: "8px",
                                  height: "8px",
                                }}
                                class="fa-solid fa-circle text-danger mr-3"
                              ></i>{" "}
                              Customer engaged
                            </div>
                            <hr />
                            <CardBody>
                              <Row>
                                <Col xs="10">
                                  <div className="numbers">
                                    <h1>
                                      {interactions.monthly?.customersEngaged}
                                    </h1>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl="7">
                    <Card variant="light">
                      <CardHeader>
                        <span className="card-category">Top customers engaged</span>
                      </CardHeader>
                      <CardBody>
                        <Table>
                          <thead className="text-primary">
                            <tr key={"1"}>
                              <th className="">Customer name</th>
                              <th className="text-right">
                                Number of interactions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {interactions.monthly?.topCustomersEngaged.map(
                              (interaction) => (
                                <tr key={interaction._id}>
                                  <td className="">
                                    {truncate(interaction.name, 13)}
                                  </td>
                                  <td className=" text-right">
                                    {interaction.interactionCount}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default InteractionAnalytics;
{
}
