const USER_ACTIONS = {
    LOAD_CUSTOMERS: "load-customers",
    LOAD_OVERVIEW: 'load-overview',
    LOAD_CUSTOMER: "load-customer",
    ADD_CUSTOMER: "add-customer",
    LOAD_MY_OVERVIEW:"load-my-overview",
    GO_LIVE: 'go-live',
    AMEND_CUSTOMER: "amend-customer",
    REMOVE_CUSTOMER: "remove-customer",
    REMOVE_ATTACHMENT: "remove-attachment",
}
export default USER_ACTIONS