import Loading from "components/Loader/Loading";
import ReactTable from "components/ReactTable/ReactTable";
import NotificationContext from "contexts/notificationContext";
import useAccess from "hooks/useAccess";
import useAlert from "hooks/useAlerts";
import {
  Button,
  DrawerOpener,
  DrawerRight,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
  useDrawer,
} from "ims-ui-kit";
import React from "react";
import { useHistory } from "react-router-dom";
import { ACTIONS, EFFECTS, IMS_SERVICES } from "rolesAndPermissions";
import BadgeStatus from "views/shared/StatusMapper/BadgeStatus";
import TimeDateComponent from "views/shared/TimeDateComponent";
import TaskForm from "views/taskManagement/TaskForm";
import { useTask } from "views/taskManagement/store";
import CRMDrawerDetail from "./CRMDrawerDetail";
import CreateCustomer from "./CreateCustomer";
import CustomerDrawerForm from "./CustomerDrawerForm";
import CustomerForm from "./CustomerForm";
import CustomerToolBar from "./CustomerToolBar";
import USER_ACTIONS from "./actions";
import filters from "./filters";
import { useCRM } from "./store";
import tables from "./tables";
import Index from "views/tagsAndCategoriesManager/Index";
import CRMFilter from "./CRMFilter";
const defaultdata = [["No data found"]];

const CRMTable = ({ ...props }) => {
  let {
    customers: dataTable,
    processing,
    fetchCustomers,
    visitingCustomer,
    visitCustomer,
    deleteCustomer,
    createCustomer,
    CustomerQueryTools,
    ...rest
  } = useCRM();
  let { handleCreateTask } = useTask();
  let { openDrawer, closeDrawer } = useDrawer();
  let notify = React.useContext(NotificationContext);
  let { alert, warningWithConfirmMessage, popUpAlerts } = useAlert();

  let { authUser, authGlobalAccess, entityAccessControl } = useAccess();
  let history = useHistory();
  dataTable = dataTable ? dataTable : defaultdata;
  const data = React.useMemo(
    () =>
      dataTable.map((data, key) => {
        return {
          id: data?._id,
          ID: `${data.reference}`,
          group: data?.group?.name,
          oraganisation_name: data.name,
          account_manager: data?.accountManager?.name,
          account_number: data.accountNumber,
          contract_startDate: (
            <TimeDateComponent date={data.contractStartDate} />
          ),
          contract_endDate: <TimeDateComponent date={data.contractEndDate} />,
          reviewDate: <TimeDateComponent date={data.reviewDate} />,
          probability: data.probability,
          last_updated_on: <TimeDateComponent date={data.updated?.on} />,
          updatedBy: data.updated?.by?.name,
          stage: <BadgeStatus status={data.stage} />,
          status: data.status,
          source: data.source,
          primaryEmail: data.primaryEmail,
          contractValue: data.contractValue,
          data: data,
          actions: (
            <UncontrolledDropdown size="sm" direction="right">
              <DropdownToggle
                outline
                onClick={(e) => {
                  e.stopPropagation();
                }}
                data-display="static"
                className="border border rounded-circle"
              >
                <i className="fa-solid fa-ellipsis-h" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(`/admin/customers/${data._id}`);
                  }}
                  id="detail"
                  tooltip="View Details"
                >
                  Details
                </DropdownItem>
                {authUser({
                  service: IMS_SERVICES.CRM,
                  action: ACTIONS.DELETE,
                  effect: EFFECTS.ALLOW,
                }) &&
                  data.stage !== "Live" &&
                  (authGlobalAccess() ||
                    entityAccessControl({
                      users: data.created.by ? [data.created.by._id] : [],
                      effect: "Allow",
                    })) && (
                    <DropdownItem
                      onClick={(e) => {
                        warningWithConfirmMessage(
                          "This customer will be deleted",
                          () => {
                            deleteCustomer(data);
                          }
                        );
                        e.stopPropagation();
                      }}
                      name="delete"
                      id="delete"
                      tooltip="Delete"
                    >
                      {processing[USER_ACTIONS.REMOVE_CUSTOMER].status &&
                      processing[USER_ACTIONS.REMOVE_CUSTOMER].id ===
                        data._id ? (
                        <Spinner size="sm" />
                      ) : (
                        "Delete"
                      )}
                    </DropdownItem>
                  )}
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        };
      }),
    [processing]
  );
  let columns = React.useMemo(
    () => CustomerQueryTools.toolState.filter.tableState || tables.default,
    [CustomerQueryTools.toolState]
  );
  return (
    <div className="content">
      {alert}
      {processing[USER_ACTIONS.LOAD_CUSTOMERS].status && <Loading />}
      <ReactTable
        data={data}
        resizable={false}
        {...rest}
        columns={columns.slice()}
        defaultPageSize={10}
        className="-striped -highlight"
        isFilterable
        isSearchable
        title="Customers"
        filters={filters}
        tableToolbar={
          <React.Fragment>
            <CreateCustomer />
            {"  "}
            <DrawerOpener drawerId="tags">
              <Button
                color="primary"
                outline
                size="md"
                className="shadow-sm--hover ms-3"
              >
                <i className="ims-icons icon-icon-tag-24 me-1 p-0"></i>
                {"  "} Categories
              </Button>
            </DrawerOpener>
          </React.Fragment>
        }
        filterToolbar={<CRMFilter />}
        rowProps={function (row) {
          return {
            onClick: function (e) {
              visitCustomer(row.original?.data);
              openDrawer("customer-detail");
            },
          };
        }}
        {...CustomerQueryTools}
        {...props}
        onPageChange={fetchCustomers}
        pagination={CustomerQueryTools.toolState.pagination}
      />
      <DrawerRight
        size="55"
        drawerId="customer-detail"
        onDrawerClose={() => {
          visitCustomer(null);
        }}
        toolbar={<CustomerToolBar />}
      >
        {<CRMDrawerDetail />}
      </DrawerRight>
      <DrawerRight size="55" drawerId="edit-customer-form">
        {visitingCustomer && <CustomerDrawerForm />}
      </DrawerRight>
      <DrawerRight drawerId="add-task-form">
        {visitingCustomer && (
          <TaskForm
            drawerView={true}
            module={visitingCustomer._id}
            moduleType="customers"
            onSubmit={async (data) => {
              await handleCreateTask(data);
            }}
          />
        )}
      </DrawerRight>
      <DrawerRight drawerId="create-customer">
        <CustomerForm
          drawerView={true}
          onSubmit={async (data) => {
            await createCustomer(data);
            closeDrawer("create-customer");
            openDrawer("customer-detail");
          }}
        />
      </DrawerRight>
      <DrawerRight drawerId="tags">
        <Index applicableModules="customers" />
      </DrawerRight>
    </div>
  );
};

export default CRMTable;
