import Loading from "components/Loader/Loading";
import { Panel, Panels } from "components/Panel/HorizontalPanel";
import useAccess from "hooks/useAccess";
import { Col, Row } from "ims-ui-kit";
import React, { useState } from "react";
import { ACTIONS, EFFECTS, IMS_SERVICES } from "rolesAndPermissions";
import { getKpiObjectives } from "services/kpiObjectiveServices";
import { imsLogger } from "services/loggerService";
import { getCurrentUserInfo } from "services/userServices";
import KpiObjectiveActionsContextProvider from "./contexts/KpiObjectiveActionsContext";
import KpiObjectiveByBusinessFunctions from "./KpiObjectiveByBusinessFunctions";
import KpiObjectiveByOrganisation from "./KpiObjectiveByOrganisation";
import KpiObjectiveForm from "./KpiObjectiveForm";
import { ImsCard } from "ims-ui-kit";
const KpiObjectives = (props) => {
  const currentUser = getCurrentUserInfo();
  const { authUser } = useAccess(currentUser);
  let [processing, setProcessing] = useState({ action: "load-kpi", id: null });
  let [kpiObjectives, setKpiObjectives] = useState([]);
  let addToExisting = (kpiObjective) =>
    setKpiObjectives((prevKpis) => [kpiObjective, ...prevKpis]);
  let updateKpiInList = (kpiObjective) =>
    setKpiObjectives((prevKpis) =>
      prevKpis.map((kpi) => (kpi._id === kpiObjective._id ? kpiObjective : kpi))
    );
  let deleteFromList = (kpiObjective) =>
    setKpiObjectives((prevKpis) =>
      prevKpis.filter((kpi) => kpi._id !== kpiObjective._id)
    );

  React.useEffect(() => {
    async function fetchData() {
      try {
        let { data } = await getKpiObjectives();
        setKpiObjectives(data.kpiObjectives);
      } catch (ex) {
        imsLogger("KpiObjectives", ex, ex.response);
      }
      setProcessing({ action: null, id: null });
    }
    fetchData();
  }, []);
  return (
    <React.Fragment>
      <div className="content">
        <KpiObjectiveActionsContextProvider
          value={{
            setProcessing,
            processing,
            addToExisting,
            updateKpiInList,
            deleteFromList,
          }}
        >
          <Row>
            <Col md="12">
              <Panels
                defaultPanel={"Organisation"}
                navLinks={
                  authUser({
                    service: IMS_SERVICES.KPI_OBJECTIVE,
                    action: ACTIONS.CREATE,
                    effect: EFFECTS.ALLOW,
                  })
                    ? ["Add KPI", "Business units", "Organisation"]
                    : ["Business units", "Organisation"]
                }
              >
                <Panel panelId="Add KPI">
                  <ImsCard>
                    <KpiObjectiveForm
                      processing={processing}
                      setProcessing={setProcessing}
                    />
                  </ImsCard>
                </Panel>
                <Panel panelId="Business units">
                  <ImsCard>
                    {processing.action === "load-kpi" ? (
                      <Loading />
                    ) : (
                      <KpiObjectiveByBusinessFunctions
                        kpiObjectives={kpiObjectives}
                        processing={processing}
                        setProcessing={setProcessing}
                      />
                    )}
                  </ImsCard>
                </Panel>
                <Panel panelId="Organisation">
                  <ImsCard>
                    <KpiObjectiveByOrganisation kpiObjectives={kpiObjectives} />
                  </ImsCard>
                </Panel>
              </Panels>
            </Col>
          </Row>
        </KpiObjectiveActionsContextProvider>
      </div>
    </React.Fragment>
  );
};

export default KpiObjectives;
