import Loading from "components/Loader/Loading";
import { Panel, Panels } from "components/Panel/HorizontalPanel";
import { Button, ImsCard } from "ims-ui-kit";
import React from "react";
import { IMS_SERVICES } from "rolesAndPermissions";
import ErrorHandlerComponent from "views/shared/ErrorHandlerComponent";
import ComplianceToolTable from "../ComplianceToolTable";
import Analytics from "../Overview/Analytics";
import DsptFilter from "./DsptFilter";
import LOADER from "./actions";
import { useDSPT } from "./store";
import DocumentListOpener from "views/documentManagement/searchableList/components/DocumentListOpener";
import { ACTIONS } from "rolesAndPermissions";
import { EFFECTS } from "rolesAndPermissions";
import useAccess from "hooks/useAccess";
import SearchableDocument from "views/documentManagement/searchableList/components/Index";

const DsptTables = (props) => {
  let {
    processing,
    dsptToolControls,
    setDsptToolControls,
    dsptOverview,
    DSPTQueryTools,
    fetchDsptToolControls,
    updateDataTable,
  } = useDSPT();
  
  let { authUser } = useAccess();
  return (
    <>
      <div className="content">
        <Panels
          navLinks={["Overview", "DSPT (2022)"]}
          defaultPanel={"Overview"}
        >
          <Panel panelId="Overview">
            <ImsCard>
              <ErrorHandlerComponent
                hasError={processing[LOADER.LOAD_OVERVIEW_DSPT].error}
                errorMessage="This iso tool has been deleted or removed"
              >
                {processing[LOADER.LOAD_OVERVIEW_DSPT].status ? (
                  <Loading />
                ) : (
                  <Analytics
                    toolKit={IMS_SERVICES.DSPTNHS}
                    overview={dsptOverview}
                  />
                )}
              </ErrorHandlerComponent>
            </ImsCard>
          </Panel>
          <Panel panelId="DSPT (2022)">
            <ImsCard>
              <ErrorHandlerComponent
                hasError={processing[LOADER.LOAD_COMPLIANCE_DSPT].error}
                errorMessage="This iso tool has been deleted or removed"
              >
                {processing[LOADER.LOAD_COMPLIANCE_DSPT].status && <Loading />}
                <SearchableDocument
                  moduleTypes={["compliancecontrols"]}
                  complianceTools={["DSPT"]}
                />
                <ComplianceToolTable
                  toolkit="DSPT (Data Security and Protection Toolkit-2022)"
                  dataTable={dsptToolControls}
                  setDataTable={setDsptToolControls}
                  processing={processing[LOADER.LOAD_COMPLIANCE_DSPT]}
                  updateDataTable={updateDataTable}
                  onPageChange={fetchDsptToolControls}
                  pagination={DSPTQueryTools.toolState.pagination}
                  tableToolbar={
                    <React.Fragment>
                      {authUser({
                        service: IMS_SERVICES.DOCUMENT_MANAGEMENT,
                        action: ACTIONS.READ,
                        effect: EFFECTS.ALLOW,
                      }) && (
                        <DocumentListOpener>
                          <Button
                            color="primary"
                            outline
                            size="md"
                            className="shadow-sm--hover ms-3"
                          >
                            Documents
                          </Button>
                        </DocumentListOpener>
                      )}
                    </React.Fragment>
                  }
                  filterToolbar={
                    <DsptFilter/>
                      
                 
                  }
                  {...DSPTQueryTools}
                />
             
              </ErrorHandlerComponent>
            </ImsCard>
          </Panel>
        </Panels>
      </div>
    </>
  );
};

export default DsptTables;
