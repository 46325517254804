import Loading from "components/Loader/Loading";
import { Panel, Panels } from "components/Panel/HorizontalPanel";
import { Button, ImsCard } from "ims-ui-kit";

import React from "react";
import { IMS_SERVICES } from "rolesAndPermissions";
import ErrorHandlerComponent from "views/shared/ErrorHandlerComponent";
import ComplianceToolTable from "../ComplianceToolTable";
import Analytics from "../Overview/Analytics";
import LOADER from "./actions";
import { useISO45001 } from "./store";
import Iso45001Filter from "./Iso45001Filter";
import DocumentListOpener from "views/documentManagement/searchableList/components/DocumentListOpener";
import { ACTIONS } from "rolesAndPermissions";
import { EFFECTS } from "rolesAndPermissions";
import SearchableDocument from "views/documentManagement/searchableList/components/Index";
import useAccess from "hooks/useAccess";

const Iso45001 = (props) => {
  let {
    processing,
    iso45001Controls,
    setIso45001Controls,
    iso45001Overview,
    Iso45001QueryTools,
    fetchIso45001ToolControls,
    updateDataTable,
  } = useISO45001();

  let { authUser } = useAccess();
  return (
    <React.Fragment>
      <div className="content">
        <Panels
          navLinks={["Overview", "ISO 45001 (2018)"]}
          defaultPanel={"Overview"}
        >
          <Panel panelId="Overview">
            <ImsCard>
              <ErrorHandlerComponent
                hasError={processing[LOADER.LOAD_OVERVIEW_45001].error}
                errorMessage="This iso tool has been deleted or removed"
              >
                {processing[LOADER.LOAD_OVERVIEW_45001].status ? (
                  <Loading />
                ) : (
                  <Analytics
                    toolKit={IMS_SERVICES.ISO45001}
                    overview={iso45001Overview}
                  />
                )}
              </ErrorHandlerComponent>
            </ImsCard>
          </Panel>
          <Panel panelId="ISO 45001 (2018)">
            <ImsCard>
              <ErrorHandlerComponent
                hasError={processing[LOADER.LOAD_COMPLIANCE_45001].error}
                errorMessage="This iso tool has been deleted or removed"
              >
                <SearchableDocument
                  moduleTypes={["compliancecontrols"]}
                  complianceTools={["ISO 45001"]}
                />
                {processing[LOADER.LOAD_COMPLIANCE_45001].status && <Loading />}
                <ComplianceToolTable
                  toolkit="ISO 45001 (Occupational Health and Safety Management System - 2018)"
                  dataTable={iso45001Controls}
                  setDataTable={setIso45001Controls}
                  processing={processing[LOADER.LOAD_COMPLIANCE_45001]}
                  updateDataTable={updateDataTable}
                  pagination={Iso45001QueryTools.toolState.pagination}
                  tableToolbar={
                    <React.Fragment>
                      {authUser({
                        service: IMS_SERVICES.DOCUMENT_MANAGEMENT,
                        action: ACTIONS.READ,
                        effect: EFFECTS.ALLOW,
                      }) && (
                        <DocumentListOpener>
                          <Button
                            color="primary"
                            outline
                            size="md"
                            className="shadow-sm--hover ms-3"
                          >
                            Documents
                          </Button>
                        </DocumentListOpener>
                      )}
                    </React.Fragment>
                  }
                  filterToolbar={<Iso45001Filter />}
                  {...Iso45001QueryTools}
                />
              </ErrorHandlerComponent>
            </ImsCard>
          </Panel>
        </Panels>
      </div>
    </React.Fragment>
  );
};

/**
 * filter dynamic place holder (Seclect section)
 */

export default Iso45001;
