import React from "react";
import Tabs from "./Tabs";

const Index = ({ ...props }) => {
  return (
    <div>
      <Tabs {...props} />
    </div>
  );
};

export default Index;
