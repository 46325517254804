import Loading from "components/Loader/Loading";
import ReactTable from "components/ReactTable/ReactTable";
import useAccess from "hooks/useAccess";
import {
  Button,
  DrawerOpener,
  DrawerRight,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
  useDrawer,
} from "ims-ui-kit";
import React from "react";
import { ACTIONS, EFFECTS, IMS_SERVICES } from "rolesAndPermissions";
import CreatePremiseAsset from "./CreatePremiseAsset";
import PremiseAssetDrawerDetail from "./PremiseAssetDrawerDetail";
import PremiseAssetDrawerForm from "./PremiseAssetDrawerForm";
import PremiseAssetToolBar from "./PremiseAssetToolbar";
import USER_ACTIONS from "./actions";
import { usePremiseAssets } from "./store";
import { useHistory } from "react-router-dom";
import PremiseAssetForm from "./PremiseAssetForm";
import Index from "views/tagsAndCategoriesManager/Index";
import PremiseFilter from "./PremiseFilter";
const defaultdata = [["No data found"]];

const ReactTables = ({ ...props }) => {
  let { authUser, authGlobalAccess, authAdminAccess, entityAccessControl } =
    useAccess();
  let history = useHistory();
  let {
    alert,
    premise,
    premisesAssets,
    visitPremise,
    pathname,
    processing,
    handlePremiseDelete,
    createPremiseAsset,
    warningWithConfirmMessage,
    fetchPremises,
    PremiseQueryTools,
    ...rest
  } = usePremiseAssets();
  let { openDrawer, closeDrawer } = useDrawer();
  
  premisesAssets = premisesAssets ? premisesAssets : defaultdata;
  const data = React.useMemo(
    () =>
      premisesAssets.map((data, key) => {
        return {
          id: data._id,
          reference: data.reference,
          group: data.group && data.group.name,
          building_name: data.name,
          address: data.address,
          postal_code: data.location,
          // activateView: activateView,
          data: data,
          actions: (
            <>
              <UncontrolledDropdown size="sm" direction="right">
                <DropdownToggle
                  outline
                  onClick={(e) => {
                    e.stopPropagation();
                    visitPremise(data);
                  }}
                  data-display="static"
                  className="border border rounded-circle"
                >
                  <i className="fa-solid fa-ellipsis-h" />
                </DropdownToggle>
                <DropdownMenu>
                  <DrawerOpener
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    drawerId="premise-asset-detail"
                  >
                    <DropdownItem
                      id="detail"
                      tooltip="View Details"
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push(`/admin/inventory/premise/${data._id}`);
                      }}
                    >
                      Details
                    </DropdownItem>
                  </DrawerOpener>
                  {authUser({
                    service: IMS_SERVICES.INVENTORY,
                    action: ACTIONS.DELETE,
                    effect: EFFECTS.ALLOW,
                  }) &&
                    (authAdminAccess() ||
                      entityAccessControl({
                        users: data.created.by ? [data.created.by._id] : [],
                        effect: "Allow",
                      })) && (
                      <DropdownItem
                        onClick={(e) => {
                          warningWithConfirmMessage(
                            "This asset will be deleted",
                            () => {
                              handlePremiseDelete(data);
                            }
                          );
                          e.stopPropagation();
                        }}
                        id="delete"
                        tooltip="Delete"
                      >
                        {processing[USER_ACTIONS.DELETE_PREMISE] &&
                        processing[USER_ACTIONS.DELETE_PREMISE].id ===
                          data._id ? (
                          <Spinner size="sm" />
                        ) : (
                          "Delete"
                        )}
                      </DropdownItem>
                    )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          ),
        };
      }),
    [processing]
  );
  return (
    <>
      {alert}
      <div className="content">
        {processing[USER_ACTIONS.LOAD_PREMISES].status && <Loading />}
        <ReactTable
          data={data}
          {...rest}
          resizable={false}
          columns={[
            {
              Header: "Reference",
              accessor: "reference",
            },
            {
              Header: authGlobalAccess() ? "Business unit" : "Business unit",
              accessor: "group",
            },
            {
              Header: "Building name",
              accessor: "building_name",
            },
            {
              Header: "Address",
              accessor: "address",
            },
            {
              Header: "Postal code",
              accessor: "postal_code",
            },
            {
              Header: "Actions",
              accessor: "actions",
              sortable: false,
              filterable: false,
            },
          ]}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
          isFilterable
          isSearchable
          tableToolbar={
            <React.Fragment>
              <CreatePremiseAsset />
              {"  "}
              <DrawerOpener drawerId="tags">
                <Button
                  color="primary"
                  outline
                  size="md"
                  className="shadow-sm--hover ms-3"
                >
                  <i className="ims-icons icon-icon-tag-24 me-1 p-0"></i>
                  {"  "} Categories
                </Button>
              </DrawerOpener>
            </React.Fragment>
          }
          filterToolbar={<PremiseFilter />}
          pathname={pathname}
          processing={processing}
          onPageChange={fetchPremises}
          pagination={PremiseQueryTools.toolState.pagination}
          {...PremiseQueryTools}
          title={"Premise assets"}
          rowProps={function (row) {
            return {
              onClick: function (e) {
                visitPremise(row.original.data);
                openDrawer("premise-asset-detail");
              },
            };
          }}
        />
        <DrawerRight
          toolbar={<PremiseAssetToolBar />}
          drawerId="premise-asset-detail"
          onDrawerClose={() => {
            visitPremise(null);
          }}
        >
          <PremiseAssetDrawerDetail />
        </DrawerRight>
        <DrawerRight drawerId="edit-premise-asset-form">
          {premise && <PremiseAssetDrawerForm />}
        </DrawerRight>
        <DrawerRight drawerId="create-premise-asset-form">
          <PremiseAssetForm
            drawerView={true}
            processing={processing}
            onSubmit={async (data) => {
              await createPremiseAsset(data);
              closeDrawer("create-premise-asset-form");
              openDrawer("premise-asset-detail");
            }}
          />
        </DrawerRight>
        <DrawerRight drawerId="tags">
          <Index applicableModules="premiseassets" />
        </DrawerRight>
      </div>
    </>
  );
};

export default ReactTables;
