import { DrawerContextProvider } from "ims-ui-kit";
import RiskTable from "./RiskTable";
import { RiskContextProvider } from "./store";
import { TaskContextProvider } from "views/taskManagement/store";
import { TagsAndCategoriesContextProvider } from "views/tagsAndCategoriesManager/store";
const RiskManagement = (props) => {
  return (
    <div className="content">
      <DrawerContextProvider>
        <RiskContextProvider {...props}>
          <TaskContextProvider>
            <TagsAndCategoriesContextProvider
              applicableModules={"risks"}
            >
              <RiskTable {...props} />
            </TagsAndCategoriesContextProvider>
          </TaskContextProvider>
        </RiskContextProvider>
      </DrawerContextProvider>
    </div>
  );
};

export default RiskManagement;
