const USER_ACTIONS = {
    LOAD_SUPPLIERS: "load-suppliers",
    LOAD_SUPPLIER: "load-supplier",
    CREATE_SUPPLIER: "create-supplier",
    AMEND_SUPPLIER: "amend-supplier",
    DELETE_SUPPLIER: "delete-supplier",
    NUDGE_OWNER: "nudge-owner",
    DELETE_CONTRACT:"delete-contact",
    DELETE_SLAS:"delete-slas",
    DELETE_ONBOARDING:"delete-onboarding",
    ADD_KPI:"add-kpi",
    AMEND_KPI:"amend-kpi",
    DELETE_KPI:"delete-kpi",
  };
  export default USER_ACTIONS;
  