import { DrawerContextProvider } from "ims-ui-kit";
import SoftwareTable from "./SoftwareTable";
import { SoftwareAssetsContextProvider } from "./store";
import { TagsAndCategoriesContextProvider } from "views/tagsAndCategoriesManager/store";

const SoftwareAsset = (props) => {
  return (
    <>
      <DrawerContextProvider>
        <SoftwareAssetsContextProvider {...props}>
          <TagsAndCategoriesContextProvider
            applicableModules={"softwareassets"}
          >
            <SoftwareTable {...props} />
          </TagsAndCategoriesContextProvider>
        </SoftwareAssetsContextProvider>
      </DrawerContextProvider>
    </>
  );
};

export default SoftwareAsset;
