import ReactTable from "components/ReactTable/ReactTable";
import NotificationContext from "contexts/notificationContext";
import useAccess from "hooks/useAccess";
import useAlerts from "hooks/useAlerts";
import useModal from "hooks/useModal";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "ims-ui-kit";
import React from "react";
import { IMS_POLICIES } from "rolesAndPermissions";
import { imsLogger } from "services/loggerService";
import GroupProfile from "./GroupProfile";
import LOADERS from "./LoadingActions";

const defaultdata = [
  {
    _id: "no-data",
    name: "",
    responsibility: "",
    type: "",
  },
];

const GroupTable = ({
  dataTable,
  pathname,
  setGroups,
  processing,
  dispatch,
  fetchData,
  toolState,
  ...rest
}) => {
  dataTable = dataTable ? dataTable : defaultdata;
  let notify = React.useContext(NotificationContext);

  let { authGlobalAccess } = useAccess();
  let { alert, warningWithConfirmMessage, successAlert, infoAlert } =
    useAlerts();
  let updateDataTable = (updatedGroup) => {
    dispatch({
      [LOADERS.DELETE_GROUP]: {
        status: true,
        error: false,
        id: updatedGroup._id,
      },
    });
    setGroups((prevGroups) =>
      prevGroups.map((group) =>
        group._id === updatedGroup._id ? updatedGroup : group
      )
    );
    dispatch({
      [LOADERS.DELETE_GROUP]: { status: false, error: false, id: null },
    });
  };
  let { activateView, Modal, isOpen } = useModal({ onUpdate: updateDataTable });
  async function handleTableButton(e, group) {
    try {
    } catch (ex) {
      imsLogger("GroupTable", ex.response || ex);
      notify("Could not complete the operation, error occurred", "danger");
    }
  }
  imsLogger("GroupTable", dataTable);

  const data = React.useMemo(
    () =>
      dataTable.map((group, key) => {
        return {
          // checkbox: (
          //   <input
          //     className="table-header-input"
          //     // onChange={(e) => {
          //     //   handleAllChecked(e);
          //     //   if (e.target.checked) {
          //     //     setCheckedIds(page.map((item) => item.original.id));
          //     //   } else if (e.target.checked === false) {
          //     //     setCheckedIds([]);
          //     //   }
          //     // }}
          //     // checked={allChecked}
          //     type="checkbox"
          //     name=""
          //     id=""
          //   />
          // ),
          id: group._id,
          name: group.name,
          responsibility: group.responsibility,
          type: group.type,
          activateView: activateView,
          data: group,
          numberOfMembers: group.totalMembers,
          actions: (
            <>
              <UncontrolledDropdown size="sm" direction="right">
                <DropdownToggle
                  outline
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  data-display="static"
                  className="border border rounded-circle"
                >
                  <i className="fa-solid fa-ellipsis-h" />
                </DropdownToggle>
                <DropdownMenu>
                  {group.name !== IMS_POLICIES.IMS_SYSTEM_ADMINISTRATION ? (
                    <>
                      <DropdownItem
                        onClick={(e) => {
                          activateView(group);
                          e.stopPropagation();
                        }}
                        id="detail"
                        tooltip="View Details"
                      >
                        Details
                      </DropdownItem>
                    </>
                  ) : (
                    <span>Default</span>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          ),
        };
      }),
    [processing]
  );
  return (
    <>
      {alert}
      <div className="content">
        <ReactTable
          data={data}
          // hasBulkActions={true}
          {...rest}
          resizable={false}
          columns={[
            // {
            //   Header: (
            //     <input
            //       className="table-header-input"
            //       // onChange={(e) => {
            //       //   handleAllChecked(e);
            //       //   if (e.target.checked) {
            //       //     setCheckedIds(page.map((item) => item.original.id));
            //       //   } else if (e.target.checked === false) {
            //       //     setCheckedIds([]);
            //       //   }
            //       // }}
            //       // checked={allChecked}
            //       type="checkbox"
            //       name=""
            //       id=""
            //     />
            //   ),
            //   accessor: "checkbox",
            //   sortable:   false,
            //   filterable: false,
            //   minWidth : 40,
            //   maxWidth: 40,
            //   width : 40
            // },
            {
              Header: "Name",
              accessor: "name",
            },
            {
              Header: "Type",
              accessor: "type",
            },
            {
              Header: "Responsibility",
              accessor: "responsibility",
            },
            {
              Header: "Number of members",
              accessor: "numberOfMembers",
            },
            {
              Header: "Actions",
              accessor: "actions",
              sortable: false,
              filterable: false,
            },
          ]}
          rowProps={function (row) {
            return {
              onClick: function (e) {
                activateView(row.original.data);
              },
            };
          }}
          title="Business units"
          defaultPageSize={10}
          showPaginationTop
          pagination={toolState.pagination}
          onPageChange={fetchData}
          showPaginationBottom={false}
          className="-striped -highlight"
          isFilterable
          isSearchable
        />
        <Modal title="Business unit">
          <GroupProfile isModalOpen={isOpen} />
        </Modal>
      </div>
    </>
  );
};

export default GroupTable;
