import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  ImsCard,
  Progress,
  Row,
  Table,
} from "ims-ui-kit";
import { Pie } from "react-chartjs-2";
import summaries from "../Summaries";

const Analytics = ({ toolKit, overview }) => {
  return (
    <Row>
      <Col md="6" className="mb-4">
        <h4>{toolKit}</h4>
        <>{summaries.find((s) => s.toolKit === toolKit).summary}</>
      </Col>
      <Col md="6"></Col>
      {overview?.controls && (
        <Col md="12">
          <Table>
            <thead className="text-primary">
              <tr>
                <th>Section</th>
                <th className="text-center">Progress</th>
                <th className="text-right">Percentage</th>
              </tr>
            </thead>
            <tbody>
              {overview?.controls.map((control) => (
                <tr key={control._id}>
                  <td>
                    {control?.control?.clause + " " + control?.control?.title}
                  </td>
                  <td className="">
                    <Progress value={control?.compliancePercentage} />
                  </td>
                  <td className="text-right">{`${control?.compliancePercentage}%`}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      )}
      <Col md="6">
        <Card className="card-chart card-chart-pie">
          <CardHeader>
            <span className="card-category">
              Controls selected vs controls implemented
            </span>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <div className="chart-area">
                  <Pie
                    data={overview?.overall?.selectedVsImplemented?.data}
                    options={overview?.overall?.selectedVsImplemented?.options}
                  />
                </div>
              </Col>
              <Col md="6">
                <CardTitle tag="h4">
                  <i className="ims-icons-20 icon-icon-arrowelbowright-24 text-success" />{" "}
                  Controls implemented {overview?.overall?.controlsImplemented}
                </CardTitle>
                <p className="category">
                  <i className="ims-icons-20 icon-icon-nudge-24" />
                  Controls selected {overview?.overall?.controlsSelected}
                </p>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col md="6">
        <Card className="card-chart card-chart-pie">
          <CardHeader>
            <span className="card-category">Overall compliance</span>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <div className="chart-area">
                  <Pie
                    data={overview?.overall?.ComplianceVsNotCompliance.data}
                    options={overview?.overall?.ComplianceVsNotCompliance.options}
                  />
                </div>
              </Col>
              <Col md="6">
                <CardTitle tag="h4">
                  <i className="ims-icons-20 icon-icon-info-24 text-success" />{" "}
                  Compliance percentage {overview?.overall?.compliance}%
                </CardTitle>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Analytics;
