import Loading from "components/Loader/Loading";
import { Panel, Panels } from "components/Panel/HorizontalPanel";
import { Button, ImsCard } from "ims-ui-kit";
import React from "react";
import { IMS_SERVICES } from "rolesAndPermissions";
import ErrorHandlerComponent from "views/shared/ErrorHandlerComponent";
import ComplianceToolTable from "../ComplianceToolTable";
import Analytics from "../Overview/Analytics";
import LOADER from "./actions";
import { useISO27001_Annex_A } from "./store";
import Iso27001_2022AnnexAFilter from "./Iso27001_2022Filter";
import { ACTIONS } from "rolesAndPermissions";
import { EFFECTS } from "rolesAndPermissions";
import DocumentListOpener from "views/documentManagement/searchableList/components/DocumentListOpener";
import SearchableDocument from "views/documentManagement/searchableList/components/Index";
import useAccess from "hooks/useAccess";

const Iso27001_2022AnnexACompliance = (props) => {
  let {
    processing,
    iso27001_Annex_AControls,
    setIso27001_Annex_AControls,
    iso27001_Annex_AOverview,
    Iso27001_Annex_AQueryTools,
    updateDataTable,
  } = useISO27001_Annex_A();

  let { authUser } = useAccess();
  return (
    <React.Fragment>
      <div className="content">
        <Panels
          navLinks={["Overview", "ISO 27001 (2022 Annex A)"]}
          defaultPanel={"Overview"}
        >
          <Panel panelId="Overview">
            <ImsCard>
              <ErrorHandlerComponent
                hasError={processing[LOADER.LOAD_OVERVIEW_27001_ANNEX_A].error}
                errorMessage="This iso tool has been deleted or removed"
              >
                {processing[LOADER.LOAD_OVERVIEW_27001_ANNEX_A].status ? (
                  <Loading />
                ) : (
                  <Analytics
                    toolKit={IMS_SERVICES.ISO27001_2022_ANNEX_A}
                    overview={iso27001_Annex_AOverview}
                  />
                )}
              </ErrorHandlerComponent>
            </ImsCard>
          </Panel>
          <Panel panelId="ISO 27001 (2022 Annex A)">
            <ImsCard>
              <ErrorHandlerComponent
                hasError={
                  processing[LOADER.LOAD_COMPLIANCE_27001_ANNEX_A].error
                }
                errorMessage="This iso tool has been deleted or removed"
              >
                {processing[LOADER.LOAD_COMPLIANCE_27001_ANNEX_A].status && (
                  <Loading />
                )}
                <SearchableDocument
                  moduleTypes={["compliancecontrols"]}
                  complianceTools={["ISO 27001 (2022 Annex A)"]}
                />
                <ComplianceToolTable
                  toolkit="ISO 27001 Annex A (Information Security Management System)"
                  dataTable={iso27001_Annex_AControls}
                  setDataTable={setIso27001_Annex_AControls}
                  updateDataTable={updateDataTable}
                  processing={processing[LOADER.LOAD_COMPLIANCE_27001_ANNEX_A]}
                  pagination={Iso27001_Annex_AQueryTools.toolState.pagination}
                  tableToolbar={
                    <React.Fragment>
                      {authUser({
                        service: IMS_SERVICES.DOCUMENT_MANAGEMENT,
                        action: ACTIONS.READ,
                        effect: EFFECTS.ALLOW,
                      }) && (
                        <DocumentListOpener>
                          <Button
                            color="primary"
                            outline
                            size="md"
                            className="shadow-sm--hover ms-3"
                          >
                            Documents
                          </Button>
                        </DocumentListOpener>
                      )}
                    </React.Fragment>
                  }
                  filterToolbar={<Iso27001_2022AnnexAFilter />}
                  {...Iso27001_Annex_AQueryTools}
                />
              </ErrorHandlerComponent>
            </ImsCard>
          </Panel>
        </Panels>
      </div>
    </React.Fragment>
  );
};

export default Iso27001_2022AnnexACompliance;
