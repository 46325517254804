import { TaskContextProvider } from "views/taskManagement/store";
import ContinualImprovementPlanTable from "./ContinualImprovementPlanTable";
import { CipContextProvider } from "./store";
import { DrawerContextProvider } from "ims-ui-kit";

const CIP = (props) => {
  return (
    <div className="content">
      <DrawerContextProvider>
        <CipContextProvider {...props}>
          <TaskContextProvider>
            <ContinualImprovementPlanTable {...props} />
          </TaskContextProvider>
        </CipContextProvider>
      </DrawerContextProvider>
    </div>
  );
};

export default CIP;
