import { Card, CardBody, Col, Row } from "ims-ui-kit";
import React from "react";
import { Calendar as BigCalendar } from "react-big-calendar";
import CreateEventModal from "./CreateEventModal";
import EditEventModal from "./EditEventModal";
import { useCalender } from "./store";

const CalenderContent = ({ children }) => {
  const {
    events,
    openEventModel,
    toggleOpenEventModel,
    openEditEventModel,
    toggleEditEventModal,
    setSlotInfo,
    selectedEvent,
    eventColors,
    localizer,
  } = useCalender();
  return (
    <React.Fragment>
      <Row>
        {/* <Col md="2">
          <ColorIndication />
        </Col> */}
        <Col className="ml-auto mr-auto" md="12">
          <Card className="card-calendar">
            <CardBody>
              <BigCalendar
                selectable
                localizer={localizer}
                events={events}
                defaultView="month"
                scrollToTime={new Date(1970, 1, 1, 6)}
                defaultDate={new Date()}
                onSelectEvent={(event) => {
                  selectedEvent(event);
                  toggleEditEventModal();
                }}
                on
                onSelectSlot={(slotInfo) => {
                  setSlotInfo(slotInfo);
                  toggleOpenEventModel();
                }}
                eventPropGetter={eventColors}
              />
            </CardBody>
          </Card>
        </Col>
        <CreateEventModal openEventModel={openEventModel} />
        <EditEventModal openEditEventModel={openEditEventModel} />
      </Row>
    </React.Fragment>
  );
};

export default CalenderContent;
