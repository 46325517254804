import Loading from "components/Loader/Loading";
import ReactTable from "components/ReactTable/ReactTable";
import useAccess from "hooks/useAccess";
import {
  Button,
  DrawerOpener,
  DrawerRight,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
  useDrawer,
} from "ims-ui-kit";
import React from "react";
import { ACTIONS, EFFECTS, IMS_SERVICES } from "rolesAndPermissions";
import CreateSoftwareAsset from "./CreateSoftwareAsset";
import SoftwareAssetDrawerDetail from "./SoftwareAssetDrawerDetail";
import SoftwareAssetDrawerForm from "./SoftwareAssetDrawerForm";
import SoftwareAssetToolbar from "./SoftwareAssetToolbar";
import USER_ACTIONS from "./actions";
import Index from "views/tagsAndCategoriesManager/Index";
import { useSoftwareAssets } from "./store";
import { useHistory } from "react-router-dom";
import SoftwareAssetForm from "./SoftwareAssetForm";
import SoftwareFilter from "./SoftwareFilter";
const defaultdata = [["No data found"]];

const ReactTables = ({ ...props }) => {
  let { authUser, authGlobalAccess, authAdminAccess, entityAccessControl } =
    useAccess();

  let {
    alert,
    warningWithConfirmMessage,
    handleSoftwareDelete,
    software,
    softwareAssets,
    visitSoftware,
    createSoftwareAsset,
    processing,
    SoftwareQueryTools,
    fetchSoftwares,
    pathname,
    ...rest
  } = useSoftwareAssets();

  let { toggle, closeDrawer, openDrawer } = useDrawer();

  softwareAssets = softwareAssets ? softwareAssets : defaultdata;
  let history = useHistory();

  const data = React.useMemo(
    () =>
      softwareAssets.map((data, key) => {
        return {
          id: data._id,
          reference: data.reference,
          software_name: data.name,
          group: data.group && data.group.name,
          license: data.numberOfLicenses,
          installs: data.numberOfInstalls,
          data: data,
          actions: (
            <>
              <UncontrolledDropdown size="sm" direction="right">
                <DropdownToggle
                  outline
                  onClick={(e) => {
                    e.stopPropagation();
                    visitSoftware(data);
                  }}
                  data-display="static"
                  className="border border rounded-circle"
                >
                  <i className="fa-solid fa-ellipsis-h" />
                </DropdownToggle>
                <DropdownMenu>
                  <DrawerOpener drawerId="software-asset-detail">
                    <DropdownItem
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push(`/admin/inventory/software/${data._id}`);
                      }}
                      id="detail"
                      tooltip="View Details"
                    >
                      Details
                    </DropdownItem>
                  </DrawerOpener>
                  {authUser({
                    service: IMS_SERVICES.INVENTORY,
                    action: ACTIONS.DELETE,
                    effect: EFFECTS.ALLOW,
                  }) &&
                    (authAdminAccess() ||
                      entityAccessControl({
                        users: data.created.by ? [data.created.by._id] : [],
                        effect: "Allow",
                      })) && (
                      <DropdownItem
                        onClick={(e) => {
                          warningWithConfirmMessage(
                            "This asset will be deleted",
                            () => {
                              handleSoftwareDelete(data._id);
                            }
                          );
                          e.stopPropagation();
                        }}
                        id="delete"
                        tooltip="Delete"
                      >
                        {processing[USER_ACTIONS.DELETE_SOFTWARE] &&
                        processing[USER_ACTIONS.DELETE_SOFTWARE].id ===
                          data._id ? (
                          <Spinner size="sm" />
                        ) : (
                          "Delete"
                        )}
                      </DropdownItem>
                    )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          ),
        };
      }),
    [processing]
  );
  return (
    <React.Fragment>
      {alert}
      <div className="content">
        {processing[USER_ACTIONS.LOAD_SOFTWARES].status && <Loading />}
        <ReactTable
          data={data}
          {...rest}
          resizable={false}
          columns={[
            {
              Header: "Reference",
              accessor: "reference",
            },
            {
              Header: "Business unit",
              accessor: "group",
            },
            {
              Header: "Software name",
              accessor: "software_name",
            },
            {
              Header: "Licence",
              accessor: "license",
            },
            {
              Header: "Installs",
              accessor: "installs",
            },
            {
              Header: "Actions",
              accessor: "actions",
              sortable: false,
              filterable: false,
            },
          ]}
          pathname={pathname}
          processing={processing}
          onPageChange={fetchSoftwares}
          pagination={SoftwareQueryTools.toolState.pagination}
          {...SoftwareQueryTools}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
          isFilterable
          isSearchable
          title={"Software assets"}
          tableToolbar={
            <React.Fragment>
              <CreateSoftwareAsset />
              {"  "}
              <DrawerOpener drawerId="tags">
                <Button
                  color="primary"
                  outline
                  size="md"
                  className="shadow-sm--hover ms-3"
                >
                  <i className="ims-icons icon-icon-tag-24 me-1 p-0"></i>
                  {"  "} Categories
                </Button>
              </DrawerOpener>
            </React.Fragment>
          }
          filterToolbar={<SoftwareFilter />}
          rowProps={function (row) {
            return {
              onClick: function (e) {
                visitSoftware(row.original.data);
                openDrawer("software-asset-detail");
              },
            };
          }}
        />
      </div>
      <DrawerRight
        drawerId="software-asset-detail"
        toolbar={
          <React.Fragment>
            <SoftwareAssetToolbar />
          </React.Fragment>
        }
        onDrawerClose={() => {
          visitSoftware(null);
        }}
      >
        <SoftwareAssetDrawerDetail />
      </DrawerRight>
      <DrawerRight drawerId="edit-software-asset-form">
        {software && <SoftwareAssetDrawerForm />}
      </DrawerRight>
      <DrawerRight drawerId="create-software-asset-form">
        <SoftwareAssetForm
          drawerView={true}
          processing={processing}
          onSubmit={async (data) => {
            await createSoftwareAsset(data);
            closeDrawer("create-software-asset-form");
            openDrawer("software-asset-detail");
          }}
        />
      </DrawerRight>
      <DrawerRight drawerId="tags">
        <Index applicableModules="softwareassets" />
      </DrawerRight>
    </React.Fragment>
  );
};

export default ReactTables;
