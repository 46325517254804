import FormatedContents from "components/Editors/TextEditor/FormattedContents";
import { linkGenerator } from "utils/formatLinkGenerator";
import useRepository from "./store/useRepository";

const RepositoryDescription = ({}) => {
  const { repository } = useRepository();
  return (
    <div className="repo-description-container">
      <div className="repo-description">
        <h4>Description:</h4>
        <p>
          {repository && repository?.description ? (
            <FormatedContents
              value={repository?.description}
              readOnly={true}
              mediaLinkGeneratorFn={linkGenerator}
            />
          ) : (
            `Add some description for this repository`
          )}
        </p>
      </div>
    </div>
  );
};

export default RepositoryDescription;
