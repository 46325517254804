export const byString = function (object, accessString) {
  accessString = accessString.replace(/\[(\w+)\]/g, '.$1');
  accessString = accessString.replace(/^\./, '');
  let accessKeys = accessString.split('.');
  for (let i = 0, n = accessKeys.length; i < n; ++i) {
    let key = accessKeys[i];
    if (key in object) {
      object = object[key];
    } else {
      return;
    }
  }
  return object;
}