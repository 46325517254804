import Loading from "components/Loader/Loading";
import ReactTable from "components/ReactTable/ReactTable";
import useAccess from "hooks/useAccess";
import {
  Button,
  DrawerRight,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
  useDrawer,
} from "ims-ui-kit";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import { ACTIONS, EFFECTS, IMS_SERVICES } from "rolesAndPermissions";
import SearchableCompliance from "views/compliance/searchableList/components/Index";
import DocumentListOpener from "views/documentManagement/searchableList/components/DocumentListOpener";
import SearchableDocument from "views/documentManagement/searchableList/components/Index";
import BadgeStatus from "views/shared/StatusMapper/BadgeStatus";
import TimeDateComponent from "views/shared/TimeDateComponent";
import TaskForm from "views/taskManagement/TaskForm";
import { useTask } from "views/taskManagement/store";
import AnalyticalAssistant from "../shared/ai/analyticalAssistant/Index";
import { audit_analyser } from "../shared/ai/analyticalAssistant/templates";
import AuditDrawerDetail from "./AuditDrawerDetails";
import AuditDrawerUpdateForm from "./AuditDrawerUpdateForm";
import AuditForm from "./AuditForm";
import AuditToolBar from "./AuditToolbar";
import CreateAudit from "./CreateAudit";
import ExtractReport from "./ExtractReport";
import USER_ACTIONS from "./actions";
import filters from "./filters";
import useAudits from "./store/useAudits";
import tables from "./tables";
import AuditFilter from "./AuditFilter";
const defaultdata = [["No data found"]];

const ReactTables = ({ ...props }) => {
  let {
    audits: dataTable,
    alert,
    processing,
    AuditQueryTools,
    fetchAudits,
    visitAudit,
    visitingAudit,
    auditType,
    deleteAudit,
    warningWithConfirmMessage,
    successAlert,
    linkISOControl,
    removeISOControl,
    createAudit,
    controlsOnVisitingAudit,
    typeAudit,
    sendAuditReport,
    reloadAudit,
    ...rest
  } = useAudits();
  let { handleCreateTask } = useTask();
  let history = useHistory();
  let { authUser, authSuperUser, entityAccessControl } = useAccess();
  let { openDrawer, closeDrawer } = useDrawer();
  dataTable = dataTable ? dataTable : defaultdata;
  const data = React.useMemo(
    () =>
      dataTable.map((data, key) => {
        return {
          ID: "AUD-" + data.ID,
          id: data._id,
          group: data.group && data.group.name,
          complianceBody: data.complianceBody && data.complianceBody.name,
          title: data.title,
          auditors_name: data.auditor && data.auditor.name,
          schedule_date: <TimeDateComponent date={data.startDate} />,
          time: moment(data.startDate).format("HH:mm"),
          data: data,
          interval: data.interval,
          status: (
            <>
              {data.completed.status ? (
                <BadgeStatus status="Completed" />
              ) : (
                <BadgeStatus status="Scheduled" />
              )}
            </>
          ),
          completed_date: <TimeDateComponent date={data.completed?.on} />,
          actions: (
            <UncontrolledDropdown size="sm" direction="right">
              <DropdownToggle
                outline
                onClick={(e) => {
                  e.stopPropagation();
                }}
                data-display="static"
                className="border border rounded-circle"
              >
                <i className="fa-solid fa-ellipsis-h" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(`/admin/audits/${typeAudit}/${data?._id}`);
                  }}
                  id="detail"
                  tooltip="View Details"
                >
                  Details
                </DropdownItem>
                {authUser({
                  service: IMS_SERVICES.AUDIT,
                  action: ACTIONS.DELETE,
                  effect: EFFECTS.ALLOW,
                }) &&
                  (authSuperUser() ||
                    entityAccessControl({
                      users: data.created.by ? [data.created.by._id] : [],
                      effect: "Allow",
                    })) &&
                  !data.completed.status && (
                    <DropdownItem
                      onClick={(e) => {
                        warningWithConfirmMessage(
                          "This audit will be deleted",
                          () => {
                            deleteAudit(data);
                          }
                        );
                        e.stopPropagation();
                      }}
                      name="delete"
                      id="delete"
                      tooltip="Delete"
                    >
                      {processing[USER_ACTIONS.DELETE_AUDIT].status &&
                      processing[USER_ACTIONS.DELETE_AUDIT].id === data._id ? (
                        <Spinner size="sm" />
                      ) : (
                        "Delete"
                      )}
                    </DropdownItem>
                  )}
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        };
      }),
    [processing, dataTable]
  );
  let columns = React.useMemo(
    () => AuditQueryTools.toolState.filter.tableState || tables.default,
    [AuditQueryTools.toolState]
  );
  return (
    <React.Fragment>
      {alert}
      <SearchableDocument moduleTypes={["audits"]} />
      <div className="content">
        {processing[USER_ACTIONS.LOAD_AUDITS].status && <Loading />}
        <ReactTable
          data={data}
          {...rest}
          resizable={false}
          columns={columns.slice()}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          filters={filters}
          tableToolbar={
            <React.Fragment>
              <CreateAudit />
              {authUser({
                service: IMS_SERVICES.DOCUMENT_MANAGEMENT,
                action: ACTIONS.READ,
                effect: EFFECTS.ALLOW,
              }) && (
                <DocumentListOpener>
                  <Button
                    color="primary"
                    outline
                    size="md"
                    className="shadow-sm--hover ms-3"
                  >
                    Documents
                  </Button>
                </DocumentListOpener>
              )}
            </React.Fragment>
          }
          filterToolbar={<AuditFilter />}
          title={`${auditType} audits`}
          className="-striped -highlight"
          rowProps={function (row) {
            return {
              onClick: function (e) {
                visitAudit(row.original?.data);
                openDrawer("audit-detail");
              },
            };
          }}
          {...AuditQueryTools}
          {...props}
          {...rest}
          isFilterable
          isSearchable
          onPageChange={fetchAudits}
          pagination={AuditQueryTools.toolState.pagination}
        />

        <DrawerRight
          drawerId="audit-detail"
          onDrawerClose={() => {
            visitAudit(null);
          }}
          toolbar={<AuditToolBar />}
        >
          <AuditDrawerDetail />
        </DrawerRight>
        <DrawerRight drawerId="edit-audit-form">
          {visitingAudit && <AuditDrawerUpdateForm />}
        </DrawerRight>
        <DrawerRight drawerId="send-audit-report">
          {visitingAudit && (
            <ExtractReport
              drawerView={true}
              onSubmit={async (data) => {
                await sendAuditReport(data);
                closeDrawer("send-audit-report");
                openDrawer("audit-detail");
              }}
            />
          )}
        </DrawerRight>
        <DrawerRight drawerId="compliance-control-picker">
          <SearchableCompliance
            onNewSelection={(data) =>
              linkISOControl({
                controls: [data?.control?._id],
                toolkits: [],
              })
            }
            onDeselection={(data) => {
              removeISOControl({
                controls: [data?.control?._id],
                toolkits: [],
              });
            }}
            preSelectedControls={controlsOnVisitingAudit}
          />
        </DrawerRight>
        <DrawerRight drawerId="add-task-form">
          {visitingAudit && (
            <TaskForm
              drawerView={true}
              module={visitingAudit._id}
              moduleType="audits"
              onSubmit={async (data) => {
                await handleCreateTask(data);
                closeDrawer("add-task-form");
                reloadAudit();
              }}
            />
          )}
        </DrawerRight>
        <DrawerRight drawerId="create-audit">
          <AuditForm
            type={auditType}
            drawerView={true}
            onSubmit={async (data) => {
              await createAudit(data);
              closeDrawer("create-audit");
              openDrawer("audit-detail");
            }}
          />
        </DrawerRight>
        <DrawerRight drawerId="audit-analyser">
          {visitingAudit && (
            <AnalyticalAssistant
              template={audit_analyser}
              source={{
                moduleType: "audits",
                module: visitingAudit?._id,
              }}
              data={visitingAudit}
              onCreateNewTask={reloadAudit}
            />
          )}
        </DrawerRight>
      </div>
    </React.Fragment>
  );
};

export default ReactTables;
