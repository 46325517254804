const USER_ACTIONS = {
    LOAD_REVIEWS: "load-reviews",
    LOAD_REVIEW: "load-review",
    CREATE_REVIEW: "create-review",
    AMEND_REVIEW: "amend-review",
    DELETE_REVIEW: "delete-review",
    COMPLETE_REVIEW: "complete-review",
    LOAD_REVIEW_DRAWER:"load-review-drawer",
    DELETE_AGENDA:"delete-agenda",
    DELETE_MINUTE:"delete-minute",

  };
  export default USER_ACTIONS;
  