import ImsBarChart from "components/charts/ImsBarChart";
import ImsLineChart from "components/charts/ImsLineChart";
import { Card, CardBody } from "ims-ui-kit";
import React from "react";
import { Link } from "react-router-dom";

const IncidentManagement = ({ HoS, dataSet }) => {

  return (
    <React.Fragment>
      {HoS ? (
        <Card variant="secondary">
          <h4
            style={{
              fontSize: "20px",
            }}
          >
            Incident Management
          </h4>
          <span className="font-size-subtitle-1">
            <Link to="/admin/incidentmanagement" className="module-link">
              Open vs resolved vs escalated incidents
            </Link>
          </span>
          <CardBody>
            <div
              style={{
                minHeight: "350px",
              }}
              className="chart-area"
            >
              <ImsLineChart
                data={dataSet.incidentsByStatus.data}
                options={dataSet.incidentsByStatus.options}
              />
            </div>
          </CardBody>
        </Card>
      ) : (
        <Card variant="secondary">
          <h4
            style={{
              fontSize: "20px",
            }}
          >
            Incident Management
          </h4>
          <span className="font-size-subtitle-1">
            <Link to="/admin/incidentmanagement" className="module-link">
              Raised vs resolved
            </Link>
          </span>

          <CardBody>
            <div
              style={{
                minHeight: "308px",
              }}
              className="chart-area"
            >
              <ImsBarChart
                data={dataSet?.businessFunctionsWithMostIncidents.data}
                options={dataSet?.businessFunctionsWithMostIncidents.options}
              />
            </div>
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  );
};

export default IncidentManagement;
