import TooltipButton from "components/Tooltip/TooltipButton";
import NotificationContext from "contexts/notificationContext";
import useAccess from "hooks/useAccess";
import useAlerts from "hooks/useAlerts";
import { Card, DrawerOpener, DrawerRight, Spinner, useDrawer } from "ims-ui-kit";
import React from "react";
import { useIncident } from "./store";
import USER_ACTIONS from "./actions";
import { useTask } from "views/taskManagement/store";
import TaskForm from "views/taskManagement/TaskForm";

import SearchableCompliance from "views/compliance/searchableList/components/Index";
import authCompliance from "utils/complianceAuthCheck";

const IncidentActions = () => {
  let {
    visitingIncident: incident,
    processing,
    escalateIncident,
    nudgeIncident,
    linkISOControl,
    removeISOControl,
    controlsOnVisitingIncident,
    reloadIncident,
  } = useIncident();
  let { handleCreateTask } = useTask();
  let { authUser } = useAccess();
  let notify = React.useContext(NotificationContext);
  let { alert, warningWithConfirmMessage, successAlert } = useAlerts();
  let { closeDrawer } = useDrawer();
  return (
    <>
      {alert}
      <Card className="bg-light shadow-none">
        <div className="d-flex justify-content-center align-items-center mb-3">
          <TooltipButton
            onClick={(e) => {
              warningWithConfirmMessage(
                `${incident.owner.name} will be nudged to look at ${incident?.reference} ${incident?.title}`,
                () => {
                  nudgeIncident(incident);
                }
              );
            }}
            size="lg"
            name="nudge"
            tooltip={
              new Date(incident?.nextNudgeAt) > new Date()
                ? `Already nudged ${incident.owner?.name}`
                : `Nudge ${incident.owner?.name}`
            }
            className="border border-0 btn-link-primary"
            color="link"
            id="nudge"
          >
            <i className="ims-icons-20 icon-icon-nudge-24" />
          </TooltipButton>
          {!incident?.resolved?.status && (
            <TooltipButton
              name="escalate"
              id="escalate"
              color="link"
              size="lg"
              className="border border-0 btn-link-danger"
              tooltip={
                incident?.escalated?.status ? "Already escalated" : "Escalate"
              }
              // disabled={incident?.escalated?.status}
              onClick={(e) => {
                if (incident?.escalated?.status) {
                  notify("Incident already escalated", "danger");
                  return;
                }
                warningWithConfirmMessage(
                  "This incident will be escalated",
                  () => {
                    escalateIncident(incident);
                  }
                );
              }}
            >
              {processing[USER_ACTIONS.ESCALATE_INCIDENT].status &&
              processing[USER_ACTIONS.ESCALATE_INCIDENT].id == incident._id ? (
                <Spinner size="sm" />
              ) : (
                <i className="ims-icons-20 icon-icon-warning-24" />
              )}
            </TooltipButton>
          )}
          {authUser(authCompliance()) && (
            <DrawerOpener drawerId="compliance-control-picker">
              <TooltipButton
                size="lg"
                name="nudge"
                id="nudge"
                color="link"
                tooltip="Select Compliance Control(s)"
                className="btn-link-primary"
              >
                <i className="ims-icons-20 icon-icon-shieldcheck-24" />
              </TooltipButton>
            </DrawerOpener>
          )}
          <DrawerOpener drawerId="add-task-form">
            <TooltipButton
              size="lg"
              name="nudge"
              id="nudge"
              color="link"
              tooltip="Link task"
              className="btn-link-primary"
            >
              <i className="ims-icons-20 icon-icon-notepad-24" />
            </TooltipButton>
          </DrawerOpener>
        </div>
        <DrawerRight drawerId="compliance-control-picker">
          <SearchableCompliance
            onNewSelection={(data) =>
              linkISOControl({
                controls: [data?.control?._id],
                toolkits: [],
              })
            }
            onDeselection={(data) => {
              removeISOControl({
                controls: [data?.control?._id],
                toolkits: [],
              });
            }}
            preSelectedControls={controlsOnVisitingIncident}
          />
        </DrawerRight>

        <DrawerRight drawerId="add-task-form">
          {incident && (
            <TaskForm
              drawerView={true}
              module={incident._id}
              moduleType="incidents"
              onSubmit={async (data) => {
                await handleCreateTask(data);
                closeDrawer("add-task-form");
                reloadIncident();
              }}
            />
          )}
        </DrawerRight>
      </Card>
    </>
  );
};

export default IncidentActions;
