import React from "react";
import DetailsDrawerHeader from "views/shared/DetailComponents/DetailsDrawerHeader";
import RiskForm from "./RiskForm";
import { useRisk } from "./store";
import { useDrawer } from "ims-ui-kit";

const RiskDrawerForm = () => {
  const { visitingRisk, updateRisk } = useRisk();
  const { closeDrawer } = useDrawer();
  return (
    <React.Fragment>
      <DetailsDrawerHeader data={visitingRisk} />
      <RiskForm
        visitingRisk={visitingRisk}
        drawerView
        onSubmit={async (data) => {
          await updateRisk(data);
          closeDrawer("edit-risk-form");
        }}
      />
    </React.Fragment>
  );
};

export default RiskDrawerForm;
