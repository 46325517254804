import Loading from "components/Loader/Loading";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  PanelTab,
  PanelTabs,
  PanelWindow,
  Row,
  UncontrolledDropdown,
} from "ims-ui-kit";
import React from "react";
import AuditShortDetail from "views/audits/AuditShortDetail";
import ComplianceStripe from "views/compliance/searchableList/components/ComplianceStripe";
import { Attachments } from "views/shared/Attachments/Index";
import DetailsDrawerHeader from "views/shared/DetailComponents/DetailsDrawerHeader";
import DetailsWrapper from "views/shared/DetailComponents/DetailsWrapper";
import { DetailsSectionHeader } from "views/shared/DetailsSectionHeader";
import TaskManagement from "views/taskManagement/TaskManagement";
import TimeLine from "../shared/Timeline/Timeline";
import AttachmentButtons from "./AttachmentButtons";
import RiskOverview from "./RiskOverview";
import RiskScore from "./RiskScore";
import RiskStatus from "./RiskStatus";
import USER_ACTIONS from "./actions";
import { useRisk } from "./store";

const RiskDrawerDetail = (props) => {
  let {
    processing,
    visitingRisk: risk,
    controlsOnVisitingRisk,
    removeISOControl,
    isMitigatedRisk,
    warningWithConfirmMessage,
  } = useRisk();
  return (
    <React.Fragment>
      {processing[USER_ACTIONS.LOAD_RISK]?.status ? (
        <Loading />
      ) : (
        risk && (
          <React.Fragment>
            <DetailsDrawerHeader data={risk} />
            <React.Fragment>
              <PanelTabs variant="outline" activeTab={0}>
                <PanelTab>
                  <i className="ims-icons-20 icon-icon-notebook-24 mr-1"></i>
                  Overview
                </PanelTab>
                <PanelTab>
                  <i className="ims-icons-20 icon-icon-list-24 mr-1"></i>
                  Details
                </PanelTab>
                <PanelTab>
                  <i
                    className="ims-icons-20 icon-icon-activity-24 mr-1
                        "
                  ></i>
                  Activity
                </PanelTab>
                <PanelTab>
                  <i className="ims-icons-20 icon-icon-clock-24 mr-1"></i>
                  Life Cycle
                </PanelTab>
                <PanelTab>
                  <i className="ims-icons-20 icon-icon-notepad-24 mr-1"></i>
                  Tasks
                </PanelTab>
                <PanelTab>
                  <i className="ims-icons-20 icon-icon-shieldcheck-24 mr-1"></i>
                  Linked controls
                </PanelTab>
                <PanelWindow tabId={0}>
                  {/* <RiskActions risk={risk} /> */}
                  <RiskOverview />
                </PanelWindow>
                <PanelWindow tabId={1}>
                  <Row>
                    <Col md="12" className="mx-2">
                      <DetailsWrapper
                        label={"Description:"}
                        iconClass={"tim-icons icon-pencil"}
                        value={risk.description}
                        labelClass={"pr-2"}
                      />
                    </Col>
                    <Col md="12" className="mx-2">
                      <DetailsWrapper
                        label={"Mitigations:"}
                        iconClass={"tim-icons icon-pencil"}
                        value={risk.controlsAndMitigation}
                        labelClass={"pr-2"}
                      />
                    </Col>

                    <Col md="12" className="mx-2">
                      <DetailsWrapper
                        label={"Acceptance rationale:"}
                        iconClass={"tim-icons icon-pencil"}
                        value={risk.acceptanceRational}
                        labelClass={"pr-2"}
                      />
                    </Col>
                    {risk?.tagsAndCategories && (
                      <Col md="12" className="mx-2">
                        <DetailsWrapper
                          label={"Additional Information:"}
                          iconClass={"tim-icons icon-pencil"}
                          value={`Category: ${risk?.tagsAndCategories?.name}`}
                          labelClass={"pr-2"}
                        />
                      </Col>
                    )}

                    {risk?.attachments?.length > 0 && (
                      <Col md="12" className="mx-2">
                        <DetailsWrapper
                          label={"Attachments"}
                          iconClass={"tim-icons icon-pencil"}
                          value={null}
                          labelClass={"pr-2"}
                        />
                        <Attachments s3Information={risk?.attachments}>
                          <AttachmentButtons />
                        </Attachments>
                      </Col>
                    )}
                    <Col md="12" className=" my-2 mx-2">
                      <DetailsWrapper label={"Risk Score"} />
                      {/* <h4 className="fw-medium">Risk Score</h4> */}
                      <RiskScore />
                    </Col>
                    {risk?.source?.moduleType === "audits" && (
                      <React.Fragment>
                        <DetailsSectionHeader title={`Audit detail`} />
                        <Row>
                          <Col md="12" className="mx-2">
                            <AuditShortDetail audit={risk?.source?.module} />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </Row>
                </PanelWindow>
                <PanelWindow tabId={2}>
                  {/* <DetailsSectionHeader title="Activity" /> */}
                  {isMitigatedRisk(risk) ? (
                    <TimeLine
                      readOnly={true}
                      horizontalSpacing={false}
                      containerClass="mx-auto sm-12"
                      moduleType="risks"
                      moduleId={risk?._id}
                    />
                  ) : (
                    <TimeLine
                      editLabel="Comment"
                      editPlaceholder="New comment"
                      horizontalSpacing={true}
                      containerClass="mx-auto sm-12"
                      moduleType="risks"
                      moduleId={risk?._id}
                      isHorizontal={false}
                    />
                  )}
                </PanelWindow>
                <PanelWindow tabId={3}>
                  <RiskStatus />
                </PanelWindow>
                <PanelWindow tabId={4}>
                  <TaskManagement moduleType="risks" module={risk._id} />
                </PanelWindow>
                <PanelWindow tabId={5}>
                  {processing[USER_ACTIONS.LOAD_LINKED_CONTROLS_TO_RISK]
                    .status && <Loading />}
                  {controlsOnVisitingRisk?.length > 0 &&
                  !processing[USER_ACTIONS.LOAD_LINKED_CONTROLS_TO_RISK]
                    .status ? (
                    controlsOnVisitingRisk.map((data) => (
                      <ComplianceStripe
                        warningWithConfirmMessage={warningWithConfirmMessage}
                        key={data._id}
                        compliance={data}
                        actions={
                          <UncontrolledDropdown size="sm" direction="right">
                            <DropdownToggle
                              outline
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              className="border"
                            >
                              <i className="fa-solid fa-ellipsis-h" />
                            </DropdownToggle>
                            <DropdownMenu bottom>
                              <DropdownItem
                                onClick={(e) => {
                                  removeISOControl({
                                    toolkits: [],
                                    controls: [data?.control?._id],
                                  });
                                }}
                              >
                                Remove
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        }
                      />
                    ))
                  ) : (
                    <p className="text-secondary text-center">
                      There are no controls linked to this
                    </p>
                  )}
                </PanelWindow>
              </PanelTabs>
            </React.Fragment>
          </React.Fragment>
        )
      )}
    </React.Fragment>
  );
};

export default RiskDrawerDetail;
