import classNames from "classnames";
import useDualStateController from "hooks/useDualStateController";
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from "ims-ui-kit";
import { SignatureplacePicker } from "views/shared/SignaturePlacePicker/Index";
import useDocument from "../store/useDocument";
import AddExternalEmails from "./AddExternalEmails";
import AddInternalUsers from "./AddInternalUsers";
import FinalList from "./FinalList";
import MessageForm from "./MessageForm";
import useRequestSignature from "./store/useRequestSignature";
const Content = () => {
  const { isOpen: isAddInternalSigneeOpen, toggle: toggleInternalSignee } =
    useDualStateController();
  const { isOpen: isAddExternalnalSigneeOpen, toggle: toggleExterrnalSignee } =
    useDualStateController();
  const { isOpen: isCordSelectorOpen, toggle: toggleCordsSelector } =
    useDualStateController();
  const {
    selectedInternalUserIds,
    selectedExternalEmails,
    hasSignaureConfigModifed,
    areAllConditionsComplete,
    message,
    signaturePageNumber,
    signatureCords,
    modifySignatureConfig,
    onComplete,
  } = useRequestSignature();
  const { document, addExternalSignee, addInternalSignee } = useDocument();
  return (
    <>
     <h5 className="text-center mt-2 mb-2">Request signatures</h5>
      <span className="text-center">
        {" "}
        Select your audience and location of signature.
      </span>
      <Row>
        <Col
          md="12"
          className="d-flex justify-content-center align-items-center"
        >
          <Button
            onClick={toggleInternalSignee}
            className={classNames("", {
              "text-success": selectedInternalUserIds.length,
            })}
          >
            {selectedInternalUserIds.length ? (
              <i className="fa-solid fa-circle-check" />
            ) : (
              <i className="fa-regular fa-circle" />
            )}{" "}
            Internal
          </Button>
          <Modal
            isOpen={isAddInternalSigneeOpen}
            toggle={toggleInternalSignee}
            centered
          >
            <ModalBody>
              <AddInternalUsers />
            </ModalBody>
            <ModalFooter>
              <Button
                size="sm"
                color="danger"
                className=" btn-block ml-auto"
                onClick={toggleInternalSignee}
              >
                Done
              </Button>
            </ModalFooter>
          </Modal>
          <Button
            onClick={toggleExterrnalSignee}
            className={classNames("", {
              "text-success": selectedExternalEmails.length,
            })}
          >
            {selectedExternalEmails.length ? (
              <i className="fa-solid fa-circle-check" />
            ) : (
              <i className="fa-regular fa-circle" />
            )}{" "}
            External
          </Button>
          <Modal
            isOpen={isAddExternalnalSigneeOpen}
            toggle={toggleExterrnalSignee}
            centered
          >
            <ModalBody>
              <AddExternalEmails />
            </ModalBody>
            <ModalFooter>
              <Button
                size="sm"
                color="danger"
                className=" btn-block ml-auto"
                onClick={toggleExterrnalSignee}
              >
                Done
              </Button>
            </ModalFooter>
          </Modal>{" "}
          <Button
            onClick={toggleCordsSelector}
            className={classNames("", {
              "text-success": hasSignaureConfigModifed(),
            })}
          >
            {hasSignaureConfigModifed() ? (
              <i className="fa-solid fa-circle-check" />
            ) : (
              <i className="fa-regular fa-circle" />
            )}{" "}
            Location
          </Button>
          <Modal
            isOpen={isCordSelectorOpen}
            toggle={toggleCordsSelector}
            centered
          >
            <ModalBody>
              <SignatureplacePicker
                fileDetails={document?.documentData?.storageInfo}
                onConfirm={(data) => {
                  modifySignatureConfig(data);
                  toggleCordsSelector();
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                size="sm"
                color="danger"
                className=" btn-block ml-auto"
                onClick={toggleCordsSelector}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </Col>
        <Col md="12">
          <MessageForm />
          <FinalList />
        </Col>
        <Col md="12 mt-2">
          <Button
            size="sm"
            color="primary"
            className="btn-block"
            disabled={!areAllConditionsComplete()}
            onClick={() => {
              addExternalSignee({
                emails: selectedExternalEmails,
                startX: signatureCords.pageX,
                startY: signatureCords.pageY,
                message: message,
                pageNumber: signaturePageNumber,
              });
              addInternalSignee({
                users: selectedInternalUserIds,
                startX: signatureCords.pageX,
                startY: signatureCords.pageY,
                message: message,
                pageNumber: signaturePageNumber,
              });
              onComplete({ selectedInternalUserIds, selectedExternalEmails });
            }}
          >
            Send this document for signature{" "}
            {areAllConditionsComplete() ? (
              <i className="fa-solid fa-circle-check" />
            ) : null}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Content;
