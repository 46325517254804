import classNames from "classnames";
import {
  Button,
  Col,
  Form,
  ImsInputSelect,
  ImsInputText,
  Row,
  useForm,
} from "ims-ui-kit";
import React from "react";
import { useUiManager } from "../uiManager";
import { PAYMENT_METHODS, useCreateOrganisation } from "./store";
import * as yup from "yup";
import { COMPLIANCE_TOOLS } from "rolesAndPermissions";

const StepBasic = () => {
  const { goinglive, paymentMethod, chosePaymentMethod, updateLicenses } =
    useCreateOrganisation();
  const { nextStep } = useUiManager();
  const dataSet = {
    paymentType: "",
    users: 0,
    groups: 0,
    superUser: 1,
    complianceTools: [],
    additionalModules: [],
  };
  // Validation rules ....
  const schema = yup.object({
    paymentType: yup
      .string()
      .oneOf([PAYMENT_METHODS.CARD, PAYMENT_METHODS.INVOICE])
      .required()
      .label("Payment method"),
    users: yup.number().min(0).required().label("Users"),
    groups: yup.number().min(0).required().label("Business units"),
    superUser: yup.number().min(1).required().label("Super user"),
    complianceTools: yup.array().label("Compliance tools"),
    additionalModules: yup.array().label("Additional modules"),
  });
  const {
    dataModel,
    handleChange,
    handleSubmit,
    isFormValid,
    validationErrors,
    isBusy,
  } = useForm(dataSet, schema);
  function calculateMonthlyPrice() {
    let licensePrice =
      dataModel.superUser * 50 + dataModel.groups * 30 + dataModel.users * 17;
    return licensePrice + Math.ceil(licensePrice * 0.25);
  }
  return (
    <React.Fragment>
      <Col md="6">
        <h3>License setup</h3>
        <p className="mb-2">You can always request for more licenses later.</p>
        <Form>
          <Row>
            <Col md="6">
              <ImsInputText
                label="Business units (£30  PCM)"
                type="number"
                placeholder="i.e. 20"
                value={dataModel.groups}
                onChange={(e) =>
                  handleChange({
                    field: "groups",
                    value: e.currentTarget.value,
                  })
                }
                error={validationErrors.groups}
              />
            </Col>
            <Col md="6">
              <ImsInputText
                label="Users (£17 PCM)"
                type="number"
                placeholder="i.e. 100"
                value={dataModel.users}
                onChange={(e) =>
                  handleChange({
                    field: "users",
                    value: e.currentTarget.value,
                  })
                }
                error={validationErrors.users}
              />
            </Col>
          </Row>
          <ImsInputText
            label="Super admins (£50 PCM)"
            type="number"
            placeholder="i.e. 3"
            mandatory={true}
            min="1"
            value={dataModel.superUser}
            onChange={(e) =>
              handleChange({
                field: "superUser",
                value: e.currentTarget.value,
              })
            }
            error={validationErrors.superUser}
          />
          <ImsInputSelect
            label="Compliance toolkits (£500 one off)"
            placeholder="i.e. ISO 27001 ISO 45001 ISO 14001"
            isMulti
            className="react-select default"
            classNamePrefix="react-select"
            options={Object.values(COMPLIANCE_TOOLS).map((t) => ({
              value: t,
              label: t,
            }))}
            onChange={(e) =>
              handleChange({
                field: "complianceTools",
                value: e.currentTarget.value.map((v) => v.value),
              })
            }
            error={validationErrors.complianceTools}
          />
          <ImsInputSelect
            label="Additional modules"
            placeholder="i.e. CRM"
            className="react-select default"
            classNamePrefix="react-select"
            isMulti
            options={[{ value: "CRM", label: "CRM" }]}
            onChange={(e) =>
              handleChange({
                field: "additionalModules",
                value: e.currentTarget.value.map((v) => v.value),
              })
            }
            error={validationErrors.additionalModules}
          />
        </Form>
      </Col>
      <Col md="6" className="d-flex flex-column">
        <div>
          <h3>Payment method</h3>
          <p className="mb-2">
            Choose the a payment method a your convenience.
          </p>
        </div>
        <div className="flex-grow-1 d-flex justify-content-between flex-column">
          <div>
            <div
              className={classNames(
                "p-4 rounded-3 payment-option mt-4 text-primary",
                {
                  " active": paymentMethod === PAYMENT_METHODS.CARD,
                }
              )}
              onClick={() => {
                chosePaymentMethod(PAYMENT_METHODS.CARD);
                handleChange({
                  field: "paymentType",
                  value: PAYMENT_METHODS.CARD,
                });
              }}
            >
              <span className="selectetion-box">Selected</span>
              <span className="">
                <b>Card payment</b>
              </span>
              <span className="selectetion-dot float-right">
                {" "}
                <i className={`ims-icons-20 icon-icon-checkcircle-24`} />
              </span>
            </div>
            <div
              className={classNames(
                "p-4 rounded-3 payment-option mt-4 text-primary",
                {
                  " active": paymentMethod === PAYMENT_METHODS.INVOICE,
                }
              )}
              onClick={() => {
                chosePaymentMethod(PAYMENT_METHODS.INVOICE);
                handleChange({
                  field: "paymentType",
                  value: PAYMENT_METHODS.INVOICE,
                });
              }}
            >
              <span className="selectetion-box">Selected</span>
              <span className="text-primary">
                <b>Monthly invoice</b>
              </span>
              <span className="selectetion-dot float-right">
                <i className={`ims-icons-20 icon-icon-checkcircle-24`} />
              </span>
            </div>
          </div>
          {validationErrors.paymentType && (
            <p className="text-danger">
              <small>{validationErrors.paymentType}</small>
            </p>
          )}
          <div>
            <hr style={{ background: "#ced4da" }}></hr>
            <p className="my-2 text-dark">
              Initial payment{" "}
              <span className="float-right">
                {"£" +
                  (dataModel.complianceTools.length * 500 +
                    dataModel.additionalModules.length * 500)}
              </span>
            </p>
            <p className="my-2 text-dark">
              Monthly{" "}
              <span className="float-right">
                {"£" + calculateMonthlyPrice()}
              </span>
            </p>
            <Button
              disabled={!isFormValid() || goinglive}
              color="primary"
              className="mb-3"
              block
              onClick={(e) =>
                handleSubmit(e, () => {
                  updateLicenses(dataModel);
                  nextStep();
                })
              }
            >
              {goinglive ? "Creating iMS Systems" : "Create my iMS Systems"}
              <i className={`ims-icons-20 icon-icon-arrowright-24`} />
            </Button>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default StepBasic;
