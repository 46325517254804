import { DrawerContextProvider } from "ims-ui-kit";
import OrganisationTable from "./OrganisationTable";
import { OrganizationAssetsContextProvider } from "./store";
import { TagsAndCategoriesContextProvider } from "views/tagsAndCategoriesManager/store";

const OrganizationAssets = (props) => {
  return (
    <>
      <DrawerContextProvider>
        <OrganizationAssetsContextProvider {...props}>
          <TagsAndCategoriesContextProvider
            applicableModules={"informationassets"}
          >
            <OrganisationTable {...props} />
          </TagsAndCategoriesContextProvider>
        </OrganizationAssetsContextProvider>
      </DrawerContextProvider>
    </>
  );
};

export default OrganizationAssets;
