import Loading from "components/Loader/Loading";
import { Panel, Panels } from "components/Panel/HorizontalPanel";
import SwitchableView from "components/SwitchableView/Index";
import PrimaryWrapperChild from "components/SwitchableView/PrimaryWrapperChild";
import SecondaryWrapperChild from "components/SwitchableView/SecondaryWrapperChild";
import useAccess from "hooks/useAccess";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ImsCard,
  Row,
  UncontrolledDropdown,
} from "ims-ui-kit";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { ACTIONS, EFFECTS, IMS_SERVICES } from "rolesAndPermissions";
import { Attachments } from "views/shared/Attachments/Index";
import DetailsSidebar from "views/shared/DetailComponents/DetailsSidebar";
import DetailsSectionContent from "views/shared/DetailsSectionContent";
import { DetailsSectionHeader } from "views/shared/DetailsSectionHeader";
import ErrorHandlerComponent from "views/shared/ErrorHandlerComponent";
import { AttachmentButtons } from "../Attachments";
import AuditOverview from "../AuditOverview";
import AuditStatus from "../AuditStatus";
import ExtractReport from "../ExtractReport";
import USER_ACTIONS from "../actions";
import AuditFormContainer from "./AuditFormContainer";
import { useAudits } from "../store";
import AuditActions from "../AuditActions";
import ComplianceStripe from "views/compliance/searchableList/components/ComplianceStripe";
import DetailsWrapper from "views/shared/DetailComponents/DetailsWrapper";
import useAlerts from "hooks/useAlerts";
import TaskManagement from "views/taskManagement/TaskManagement";

const AuditDetail = () => {
  let {
    visitingAudit: audit,
    auditType,
    processing,
    linkISOControl,
    removeISOControl,
    controlsOnVisitingAudit,
    sendAuditReport,
  } = useAudits();
  let {
    authUser,
    authSuperUser,
    authInternalUser,
    authExternalUser,
    entityAccessControl,
  } = useAccess();

  let { warningWithConfirmMessage } = useAlerts();

  function accessControlAmendTab() {
    let permitted = ["Amend audit"];
    let notPermitted = [];
    if (
      authUser({
        service: IMS_SERVICES.AUDIT,
        action: ACTIONS.CREATE,
        effect: EFFECTS.ALLOW,
      })
    ) {
      if (authSuperUser()) return permitted;
      if (auditType === "Internal" && authInternalUser()) return permitted;
      if (auditType === "External" && authExternalUser()) return permitted;
    }
    return notPermitted;
  }
  function _accessControlAmendTab() {
    return authUser({
      service: IMS_SERVICES.AUDIT,
      action: ACTIONS.CREATE,
      effect: EFFECTS.ALLOW,
    })
      ? ["Extract report"]
      : [];
  }
  return (
    <React.Fragment>
      <div className="content">
        <Panels
          defaultPanel={"Details"}
          navLinks={["Details", ..._accessControlAmendTab()]}
        >
          <h4 className="mb-3 text-primary fw-bold">
            {auditType} audit details
          </h4>
          <Panel panelId="Details">
            <ErrorHandlerComponent
              hasError={processing[USER_ACTIONS.LOAD_AUDIT].error}
              errorMessage="This audit has been deleted or removed"
            >
              {processing[USER_ACTIONS.LOAD_AUDIT].status ? (
                <Loading />
              ) : (
                audit && (
                  <Row>
                    <Col xl="4" md="4" sm="12">
                      <DetailsSidebar
                        title="Details"
                        iconClass="ims-icons-20 icon-document-regular"
                        label={`Raised on ${moment(audit?.created?.on).format(
                          "DD/MM/YYYY"
                        )}`}
                      >
                        <AuditActions />
                        <AuditOverview />
                        <AuditStatus />
                      </DetailsSidebar>
                    </Col>
                    <Col xl="8" md="8" sm="12" className="mb-3">
                      <SwitchableView
                        viewTitle={audit.title}
                        canSwitch={
                          !audit.completed.status &&
                          authUser({
                            service: IMS_SERVICES.AUDIT,
                            action: ACTIONS.CREATE,
                            effect: EFFECTS.ALLOW,
                          }) &&
                          ((auditType === "Internal" && authInternalUser()) ||
                            (auditType === "External" && authExternalUser()) ||
                            authSuperUser()) &&
                          entityAccessControl({
                            users: [
                              audit.created.by && audit.created.by._id,
                              audit.auditor && audit.auditor._id,
                            ],
                            effect: "Allow",
                          })
                        }
                      >
                        <SecondaryWrapperChild>
                          <AuditFormContainer />
                        </SecondaryWrapperChild>
                        <PrimaryWrapperChild>
                          <DetailsWrapper
                            label={"Non-conformities:"}
                            value={null}
                            labelClass={"pr-2"}
                          />
                          <Row>
                            <Col md="12" className="mb-4">
                              {audit.identifications.length > 0
                                ? audit.identifications.map(
                                    (identification) => (
                                      <Row key={identification?._id}>
                                        <Col md="12">
                                          <p className="text-secondary">
                                            <span>
                                              <strong>
                                                {" "}
                                                <i className="fas fa-ban" />{" "}
                                                Non-conformity:
                                              </strong>
                                            </span>{" "}
                                            {identification?.nonConformity}
                                          </p>

                                          {/* </span> */}
                                        </Col>
                                        <Col md="12">
                                          {/* <DetailsSectionContent
                                        label={"Root causes:"}
                                        value={identification?.rootCause}
                                      /> */}
                                          <span>
                                            <strong>Root Causes:</strong>
                                          </span>
                                          <p>{identification?.rootCause}</p>
                                          {audit?.completed?.status && (
                                            <Link
                                              className="text-info"
                                              to={`/admin/incidentmanagement/${identification?._id}`}
                                            >
                                              {" "}
                                              View detail...
                                            </Link>
                                          )}
                                        </Col>
                                      </Row>
                                    )
                                  )
                                : "None"}
                              <br></br>
                              <p className="text-secondary">
                                <span>
                                  <strong>Risks:</strong>
                                </span>{" "}
                              </p>
                              <Row>
                                <Col md="12" className="mb-4">
                                  {audit?.risks && audit?.risks.length > 0 ? (
                                    <React.Fragment>
                                      {audit.risks.map((risk) => (
                                        <Row key={risk?._id}>
                                          <Col md="12">
                                            <span className="text-link">
                                              <strong>
                                                <i className="ims-icons-20 icon-icon-alert-circle-24" />{" "}
                                                Title:{" "}
                                              </strong>
                                              <span className="text-secondary">
                                                {risk?.title}
                                              </span>
                                            </span>
                                          </Col>
                                          <Col md="5">
                                            <span className="">
                                              Likelihood:{" "}
                                              {risk.score.likelihood < 3 ? (
                                                <span className="text-success">
                                                  {risk.score.likelihood}
                                                </span>
                                              ) : risk.score.likelihood < 5 ? (
                                                <span className="text-warning">
                                                  {risk.score.likelihood}
                                                </span>
                                              ) : (
                                                <span className="text-danger">
                                                  {risk.score.likelihood}
                                                </span>
                                              )}
                                            </span>
                                          </Col>
                                          <Col md="5">
                                            <span className="">
                                              Consequences:{" "}
                                              {risk.score.consequence < 3 ? (
                                                <span className="text-success">
                                                  {risk.score.consequence}
                                                </span>
                                              ) : risk.score.consequence < 5 ? (
                                                <span className="text-warning">
                                                  {risk.score.consequence}
                                                </span>
                                              ) : (
                                                <span className="text-danger">
                                                  {risk.score.consequence}
                                                </span>
                                              )}
                                            </span>
                                          </Col>
                                          <Col md="12">
                                            <span>
                                              <strong>Description:</strong>
                                            </span>
                                            <p>{risk.description}</p>
                                            {audit?.completed?.status && (
                                              <Link
                                                className="text-info"
                                                to={`/admin/risks/${risk?._id}`}
                                              >
                                                {" "}
                                                View detail...
                                              </Link>
                                            )}
                                          </Col>
                                        </Row>
                                      ))}
                                    </React.Fragment>
                                  ) : (
                                    "None"
                                  )}
                                </Col>
                              </Row>
                              <DetailsWrapper
                                label={"Opportunity for improvements:"}
                                value={null}
                                labelClass={"pr-2"}
                              />
                              <Row>
                                <Col md="12" className="mb-4">
                                  {audit.cips.length > 0
                                    ? audit.cips.map((cip) => (
                                        <Row key={cip?._id}>
                                          <Col md="12">
                                            {/* <span className=""> */}
                                            <p className="text-secondary">
                                              <span>
                                                <strong>
                                                  {" "}
                                                  <i className="fas fa-ban" />{" "}
                                                  Title:
                                                </strong>
                                              </span>{" "}
                                              {cip?.title}
                                            </p>
                                          </Col>
                                          <Col md="12">
                                            {/* <DetailsSectionContent
                                        label={"Root causes:"}
                                        value={identification?.rootCause}
                                      /> */}
                                            <span>
                                              <strong>Focus area:</strong>
                                            </span>
                                            <p>
                                              {cip?.opportunityForImprovement}
                                            </p>

                                            {audit?.completed?.status && (
                                              <Link
                                                className="text-info"
                                                to={`/admin/cip/${cip?._id}`}
                                              >
                                                {" "}
                                                View detail...
                                              </Link>
                                            )}
                                          </Col>
                                        </Row>
                                      ))
                                    : "None"}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <p className="text-secondary">
                            <span>
                              <strong>Audit summary:</strong>
                            </span>{" "}
                          </p>
                          <Row>
                            <Col md="12">
                              <DetailsSectionContent
                                label={""}
                                value={audit?.comment}
                              />
                            </Col>
                            <Col md="12" className="">
                              <DetailsWrapper
                                label={"Linked compliance controls:"}
                                iconClass={"tim-icons icon-pencil"}
                                labelClass={"pr-2"}
                              />
                              {processing[
                                USER_ACTIONS.LOAD_LINKED_CONTROLS_TO_AUDIT
                              ].status && <Loading />}
                              {controlsOnVisitingAudit?.length > 0 &&
                                controlsOnVisitingAudit.map((data) => (
                                  <ComplianceStripe
                                    key={data._id}
                                    compliance={data}
                                    warningWithConfirmMessage={
                                      warningWithConfirmMessage
                                    }
                                    actions={
                                      <UncontrolledDropdown
                                        size="sm"
                                        direction="right"
                                      >
                                        <DropdownToggle
                                          outline
                                          onClick={(e) => {
                                            e.stopPropagation();
                                          }}
                                          className="border"
                                        >
                                          <i className="fa-solid fa-ellipsis-h" />
                                        </DropdownToggle>
                                        <DropdownMenu bottom>
                                          <DropdownItem
                                            onClick={(e) => {
                                              removeISOControl({
                                                toolkits: [],
                                                controls: [data?.control?._id],
                                              });
                                            }}
                                          >
                                            Remove
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    }
                                  />
                                ))}
                            </Col>
                            <Col md="12">
                              <TaskManagement
                                moduleType="audits"
                                module={audit?._id}
                              />
                            </Col>
                          </Row>
                          <DetailsSectionHeader
                            title={`Attachments and evidences:`}
                          />
                          <Row>
                            <Col md="12">
                              <Attachments s3Information={audit?.attachments}>
                                <AttachmentButtons />
                              </Attachments>
                            </Col>
                          </Row>
                        </PrimaryWrapperChild>
                      </SwitchableView>
                    </Col>
                  </Row>
                )
              )}
            </ErrorHandlerComponent>
          </Panel>
          <Panel panelId="Extract report">
            <ImsCard>
              <ErrorHandlerComponent
                hasError={processing[USER_ACTIONS.LOAD_AUDIT].error}
                errorMessage="This audit has been deleted or removed"
              >
                <ExtractReport
                  onSubmit={async (data) => {
                    await sendAuditReport(data);
                  }}
                />
              </ErrorHandlerComponent>
            </ImsCard>
          </Panel>
        </Panels>
      </div>
    </React.Fragment>
  );
};

export default AuditDetail;
