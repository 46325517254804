import { Badge, Button, Card, CardBody } from "ims-ui-kit";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { downloadFile } from "services/fileHandlerService";
import useDocument from "./store/useDocument";
import ImageNameWrapper from "../../shared/DetailComponents/ImageNameWrapper";
const MetaInformation = ({}) => {
  const { document, repository } = useDocument();
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="border-bottom pb-1">
            <p className="font-weight-bold font-size-subtitle-1">
              Purpose:{" "}
              <span className="">{document?.documentData.purpose}</span>{" "}
              <Button
                size="sm"
                className="pull-right border-0"
                onClick={() =>
                  downloadFile(document?.documentData?.storageInfo)
                }
              >
                <i className="fa-solid fa-download" />
              </Button>
            </p>
          </div>
          <div className="py-2">
            <p>
              <b>Document name</b>:{" "}
              <span className="text-secondary">
                {document?.reference} {document?.name}
              </span>
            </p>
            <p>
              <b>Repository</b>:{" "}
              <Link
                to={`/admin/document-repositories/${document?.repository?._id}`}
              >
                {document?.repository?.name}
              </Link>
            </p>

            <p>
              <b>Version control</b>:{" "}
              {document?.documentData?.dvID !== 0 && (
                <span className="text-secondary">
                  V{document?.documentData?.dvID}
                </span>
              )}{" "}
              <Badge
                color={
                  document?.status === "Published" ? "primary" : "secondary"
                }
              >
                {document?.status}
              </Badge>
            </p>
            <p>
              <b>Document uploaded</b>:{" "}
              <span className="text-secondary">
                {moment(document?.created?.on).format("DD/MM/YYYY")}
              </span>
            </p>
            <p>
              <b>Audience</b>:{" "}
              <span className="text-secondary">
                {repository?.group?.name || repository?.privacy}
              </span>
            </p>
            {document?.documentData?.applicableModules.length > 0 && (
              <p>
                <b>Linked Modules</b>:{" "}
                {document.documentData.applicableModules.map(
                  (applicableModule) => (
                    <Badge color={"primary"}>
                      {applicableModule === "compliancecontrols"
                        ? "Compliance"
                        : applicableModule}
                    </Badge>
                  )
                )}
              </p>
            )}
            {document?.documentData?.complianceTools.length > 0 && (
              <p>
                <b>Compliance standards</b>:{" "}
                {document.documentData.complianceTools.map((complianceTool) => (
                  <Badge color={"primary"}>{complianceTool}</Badge>
                ))}
              </p>
            )}
            <div className="border-bottom pb-1 mb-2">
              <p className="font-weight-bold font-size-subtitle-1 mt-3">
                Document owners:{" "}
              </p>
            </div>
            {document?.documentData?.owners.length > 0 && (
              <React.Fragment>
                {document.documentData.owners.map((owner) => (
                  <React.Fragment>
                    <ImageNameWrapper
                      name={owner.name}
                      img={owner.profileImageSrc}
                    />
                    <br />
                  </React.Fragment>
                ))}
              </React.Fragment>
            )}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default MetaInformation;
