import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  UncontrolledAlert,
  useDualStateController,
} from "ims-ui-kit";
import golivevector from "../../../../assets/img/go-live.jpg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const GoLivePromo = () => {
  const { isOpen: isOpen, toggle: toggle } = useDualStateController();
  const history = useHistory();
  return (
    <div className="my-3">
      <UncontrolledAlert color="primary">
        You currently have no iMS created for your own organisation.{" "}
        <Button
          color="primary"
          outline
          className="border-0"
          size="sm"
          onClick={toggle}
        >
          View
        </Button>
      </UncontrolledAlert>
      <img className="text-center w-50 mx-auto d-block" src={golivevector} />
      <Button
        block
        color="primary"
        onClick={() => history.push("/auth/onboard/go-live")}
      >
        Go Live <i className="ims-icons-20 icon-icon-arrowright-24" />
      </Button>
      <Modal isOpen={isOpen} centered toggle={toggle} backdrop={false}>
        <ModalBody>
          <iframe
            style={{ borderRadius: 10 }}
            width="100%"
            height="514"
            src="https://www.youtube.com/embed/8uMOQuFMdY8"
            title="iMS Systems Explainer Video"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </ModalBody>
        <ModalFooter>
          <Button
            size="sm"
            color="danger"
            className=" btn-block ml-auto"
            onClick={toggle}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default GoLivePromo;
