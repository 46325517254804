import Loading from "components/Loader/Loading";
import { Panel, Panels } from "components/Panel/HorizontalPanel";
import { Button, ImsCard } from "ims-ui-kit";
import React from "react";
import { IMS_SERVICES } from "rolesAndPermissions";
import ErrorHandlerComponent from "views/shared/ErrorHandlerComponent";
import ComplianceToolTable from "../ComplianceToolTable";
import Analytics from "../Overview/Analytics";
import LOADER from "./actions";
import { useISO15686 } from "./store";
import Iso15686Filter from "./Iso15686Filter";
import SearchableDocument from "views/documentManagement/searchableList/components/Index";
import { ACTIONS } from "rolesAndPermissions";
import { EFFECTS } from "rolesAndPermissions";
import DocumentListOpener from "views/documentManagement/searchableList/components/DocumentListOpener";
import useAccess from "hooks/useAccess";

const Iso15686 = ({ pathname }) => {
  let {
    processing,
    iso15686Controls,
    setIso15686Controls,
    iso15686Overview,
    updateDataTable,
    Iso15686QueryTools,
  } = useISO15686();

  let { authUser } = useAccess();

  return (
    <React.Fragment>
      <div className="content">
        <Panels
          navLinks={["Overview", "ISO 15686-5 (2017)"]}
          defaultPanel={"Overview"}
        >
          <Panel panelId="Overview">
            <ImsCard>
              <ErrorHandlerComponent
                hasError={processing[LOADER.LOAD_OVERVIEW_15686].error}
                errorMessage="This iso tool has been deleted or removed"
              >
                {processing[LOADER.LOAD_OVERVIEW_15686].status ? (
                  <Loading />
                ) : (
                  <Analytics
                    toolKit={IMS_SERVICES.ISO15686_5}
                    overview={iso15686Overview}
                  />
                )}
              </ErrorHandlerComponent>
            </ImsCard>
          </Panel>
          <Panel panelId="ISO 15686-5 (2017)">
            <ImsCard>
              <ErrorHandlerComponent
                hasError={processing[LOADER.LOAD_COMPLIANCE_15686].error}
                errorMessage="This iso tool has been deleted or removed"
              >
                {processing[LOADER.LOAD_COMPLIANCE_15686].status && <Loading />}
                <SearchableDocument
                  moduleTypes={["compliancecontrols"]}
                  complianceTools={["ISO 15686-5"]}
                />
                <ComplianceToolTable
                  toolkit="ISO 15686-5 (Life-cycle costing-2017)"
                  dataTable={iso15686Controls}
                  setDataTable={setIso15686Controls}
                  processing={processing[LOADER.LOAD_COMPLIANCE_15686]}
                  updateDataTable={updateDataTable}
                  pagination={Iso15686QueryTools.toolState.pagination}
                  tableToolbar={
                    <React.Fragment>
                      {authUser({
                        service: IMS_SERVICES.DOCUMENT_MANAGEMENT,
                        action: ACTIONS.READ,
                        effect: EFFECTS.ALLOW,
                      }) && (
                        <DocumentListOpener>
                          <Button
                            color="primary"
                            outline
                            size="md"
                            className="shadow-sm--hover ms-3"
                          >
                            Documents
                          </Button>
                        </DocumentListOpener>
                      )}
                    </React.Fragment>
                  }
                  filterToolbar={<Iso15686Filter />}
                  {...Iso15686QueryTools}
                />
              </ErrorHandlerComponent>
            </ImsCard>
          </Panel>
        </Panels>
      </div>
    </React.Fragment>
  );
};

/**
 * filter dynamic place holder (Seclect section)
 */

export default Iso15686;
