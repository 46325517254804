import { Button, Row } from "ims-ui-kit";
import { useHistory } from "react-router";
import errorImage from "../../assets/img/404-error-1.png";

const ErrorHandlerComponent = ({ hasError, errorMessage, children }) => {
  let history = useHistory();
  return (
    <div className="content">
      {hasError ? (
        <div className="text-center mt-3">
          <img src={errorImage} style={{ height: "150px" }} />
          <h3 className="text-bold mt-3">Page Not Found</h3>
          <h4 className="text-danger text-center">{errorMessage}</h4>
          <Row className="justify-content-center">
            <Button onClick={() => history.goBack()} className=" btn-simple">
              Go back
            </Button>
          </Row>
        </div>
      ) : (
        <div>{children}</div>
      )}
    </div>
  );
};

export default ErrorHandlerComponent;
