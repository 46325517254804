import { useDrawer } from "ims-ui-kit";
import React from "react";
import DetailsDrawerHeader from "views/shared/DetailComponents/DetailsDrawerHeader";
import IncidentForm from "./IncidentForm";
import { useIncident } from "./store";

const IncidentDrawerForm = () => {
  let { visitingIncident, updateIncident, suppliers } = useIncident();
  const { closeDrawer, openDrawer } = useDrawer();
  return (
    <React.Fragment>
      <DetailsDrawerHeader data={visitingIncident} />
      <IncidentForm
        visitingIncident={visitingIncident}
        drawerView
        suppliers={suppliers}
        onSubmit={async (data) => {
          await updateIncident(data);
          closeDrawer("edit-incident-form");
        }}
      />
    </React.Fragment>
  );
};

export default IncidentDrawerForm;
