import Loading from "components/Loader/Loading";
import { Col, PanelTab, PanelTabs, PanelWindow, Row } from "ims-ui-kit";
import React from "react";
import { Attachments } from "views/shared/Attachments/Index";
import DetailsDrawerHeader from "views/shared/DetailComponents/DetailsDrawerHeader";
import { DetailsSectionHeader } from "views/shared/DetailsSectionHeader";
import TaskManagement from "views/taskManagement/TaskManagement";
import AgendaButtons from "./AgendaButtons";
import MinutesButtons from "./MinutesButtons";
import ReviewOverview from "./ReviewOverview";
import ReviewStatus from "./ReviewStatus";
import USER_ACTIONS from "./actions";
import { useSchedule } from "./store";

const ReviewDrawerDetail = () => {
  let { processing, visitingReview: managementReview } = useSchedule();
  return (
    <React.Fragment>
      {processing[USER_ACTIONS.LOAD_REVIEW]?.status ? (
        <Loading />
      ) : (
        managementReview && (
          <React.Fragment>
            <DetailsDrawerHeader data={managementReview} />
            <React.Fragment>
              <PanelTabs variant="outline" activeTab={0}>
                <PanelTab>
                  <i className="ims-icons-20 icon-icon-notebook-24 mr-1"></i>
                  Overview
                </PanelTab>
                <PanelTab>
                  <i className="ims-icons-20 icon-icon-list-24 mr-1"></i>
                  Details
                </PanelTab>
                <PanelTab>
                  <i className="ims-icons-20 icon-icon-clock-24 mr-1"></i>
                  Life Cycle
                </PanelTab>
                <PanelTab>
                  <i className="ims-icons-20 icon-icon-notepad-24 mr-1"></i>
                  Tasks
                </PanelTab>
                <PanelWindow tabId={0}>
                  <ReviewOverview />
                </PanelWindow>
                <PanelWindow tabId={1}>
                  <Row>
                    <DetailsSectionHeader title={`Attendees:`} />
                    <Col md="12" className="mb-4">
                      {managementReview.attendees.map((attendee) => (
                        <p>{attendee.name}</p>
                      ))}
                    </Col>
                  </Row>
                  <DetailsSectionHeader title={`Agenda`} />
                  <Row>
                    <Col md="12" className="mb-4">
                      <Attachments s3Information={managementReview.agenda}>
                        <AgendaButtons />
                      </Attachments>
                    </Col>
                  </Row>
                  <DetailsSectionHeader title={`Minutes`} />
                  <Row>
                    <Col md="12">
                      <Attachments s3Information={managementReview.minutes}>
                        <MinutesButtons />
                      </Attachments>
                    </Col>
                  </Row>
                </PanelWindow>
                <PanelWindow tabId={2}>
                  <ReviewStatus />
                </PanelWindow>
                <PanelWindow tabId={3}>
                  <TaskManagement
                    moduleType="managementreviews"
                    module={managementReview._id}
                  />
                </PanelWindow>
              </PanelTabs>
            </React.Fragment>
          </React.Fragment>
        )
      )}
    </React.Fragment>
  );
};

export default ReviewDrawerDetail;
