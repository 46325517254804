const USER_ACTIONS = {
  LOAD_INCIDENTS: "load-incidents",
  LOAD_INCIDENT: "load-incident",
  CREATE_INCIDENT: "create-incident",
  AMEND_INCIDENT: "amend-incident",
  DELETE_INCIDENT: "delete-incident",
  ESCALATE_INCIDENT: "escalate-incident",
  NUDGE_OWNER: "nudge-owner",
  DELETE_ATTACHMENT: "delete-attachment",
  LINK_ISO_CONTROL: "link-iso-control",
  REMOVE_ISO_CONTROL: "remove-iso-control",
  LOAD_LINKED_CONTROLS_TO_INCIDENT: "load-linked-controls-to-incident",
};
export default USER_ACTIONS;
