import useDualStateController from "hooks/useDualStateController";
import { Button, Modal, ModalBody, ModalFooter } from "ims-ui-kit";
import React from "react";
import RepositoryForm from "./RepositoryForm";
import useRepository from "./store/useRepository";
import Can from "components/Can/Can";
import { IMS_SERVICES, ACTIONS } from "rolesAndPermissions";
import { useApplication } from "stores/applicationStore";
const EditRepository = ({}) => {
  const { repository, amendRepository, hasRepositoryOwnership } =
    useRepository();
  const { isOpen: isRepositoryFormOpen, toggle: toggleRepositoryForm } =
    useDualStateController();
  const { currentUserData } = useApplication();
  return (
    <Can
      policy={{
        service: IMS_SERVICES.DOCUMENT_MANAGEMENT,
        action: ACTIONS.UPDATE,
      }}
    >
      {hasRepositoryOwnership(currentUserData?._id) && (
        <Button onClick={toggleRepositoryForm} className="ml-md-3">
          <i className="ims-icons-20 icon-icon-pencil-24 mr-2"></i>
          Edit Repository
        </Button>
      )}
      <Modal isOpen={isRepositoryFormOpen} toggle={toggleRepositoryForm}>
        <ModalBody>
          <RepositoryForm
            repository={repository}
            onSubmit={(data) => {
              amendRepository(data);
              toggleRepositoryForm();
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            className=" btn-block ml-auto"
            onClick={toggleRepositoryForm}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Can>
  );
};

export default EditRepository;
