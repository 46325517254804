const USER_ACTIONS = {
  LOAD_STAFF_REPORTS: "load-staff-reports",
  LOAD_EMPLOYEES: "load-employees",
  LOAD_EXPENSEREPORTS: "load-expense-reports",
  LOAD_EXPENSEREPORT: "load-expense-report",
  CREATE_EXPENSEREPORT: "create-expense-report",
  AMEND_EXPENSEREPORT: "amend-expense-report",
  DELETE_EXPENSEREPORT: "remove-expense-report",
  REMOVE_ATTACHMENT: "remove-attachment",
  LOAD_EXPENSES: "load-expenses",
  LOAD_EXPENSE: "load-expense",
  INCLUDE_EXPENSE: "include-expense",
  AMEND_EXPENSE: "amend-expense",
  DELETE_EXPENSE: "delete-expense",
  DELETE_EXPENSE_ATTACHMENT: "delete-expense-attachment",
  DOWNLOAD_EXPENSE_ATTACHMENT: "download-expense-attachment",
  LOAD_TRAVELS: "load-travels",
  LOAD_TRAVEL: "load-travel",
  INCLUDE_TRAVEL: "include-travel",
  AMEND_TRAVEL: "amend-travel",
  DELETE_TRAVEL: "delete-travel",
  DELETE_TRAVEL_ATTACHMENT: "delete-travel-attachment",
  DOWNLOAD_TRAVEL_ATTACHMENT: "download-travel-attachment",
  LOAD_ACCOMMODATIONS: "load-accommodations",
  LOAD_ACCOMMODATION: "load-accommodation",
  INCLUDE_ACCOMMODATION: "include-accommodation",
  AMEND_ACCOMMODATION: "amend-accommodation",
  DELETE_ACCOMMODATION: "delete-accommodation",
  DELETE_ACCOMMODATION_ATTACHMENT: "delete-accommodation-attachment",
  DOWNLOAD_ACCOMMODATION_ATTACHMENT: "download-accommodation-attachment",
  SUBMIT_REPORT: "submit-report",
  HANDLE_REPORT_SUBMISSION: "handle-report-submission",
  CANCEL_REPORT_SUBMISSION: "cancel-report-submission",
  LOAD_EXPENSE_REPORT_DRAWER:"load-expense-report-drawer",
};
export default USER_ACTIONS;
