import logo from "assets/img/ims-systems-full-logo-white.png";
import classNames from "classnames";
import useAccess from "hooks/useAccess";
import {
  Button,
  Collapse,
  DrawerContextProvider,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Tooltip,
} from "ims-ui-kit";
import React from "react";
import AccountDropDown from "./AccountDropDown";
import Clockin from "./ClockIn";
import Notifications from "./Notifications";
import QuickActions from "./QuickActions";
import GlobalSearch from "./GlobalSearch";
import SendMailModal from "./SendMailModal";
import SettingGear from "./SettingGear";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useApplication } from "stores/applicationStore";
import aliceLogoHeadFilled from "../../../assets/img/alice-logo-head-filled.png";
const AdminNavbar = (props) => {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [modalSend, setModalSend] = React.useState(false);
  const [openClockIn, setOpenClockIn] = React.useState(false);
  const [color, setColor] = React.useState("bg-white");
  const { authGlobalAccess } = useAccess();
  const { membershipData } = useApplication();
  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("bg-white");
    }
  };
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const dropdownRef = React.useRef(null);

  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("bg-white");
    }
    setCollapseOpen(!collapseOpen);
  };
  // this function is to open the Search modal
  const toggleModalSend = () => {
    setModalSend(!modalSend);
  };

  const toggleModalClockIn = () => {
    setOpenClockIn(!openClockIn);
  };

  return (
    <React.Fragment>
      <Navbar fixed="top" className="admin-navbar" expand="lg">
        <div className="d-flex align-items-center">
          <Button
            size="sm"
            outline
            className="border-0 text-white d-md-none bg-transparent"
            onClick={props.onSibebarToggle && props.onSibebarToggle}
          >
            <i className="ims-icons-20 icon-icon-squaresfour-24"></i>
          </Button>
          <NavbarBrand>
            <a href="https://imssystems.tech/" target="_blank" rel="noreferrer">
              <img alt="logo" className="" src={logo} />
            </a>
          </NavbarBrand>
        </div>
        <NavbarToggler onClick={toggleCollapse} />
        <Collapse navbar isOpen={collapseOpen}>
          <GlobalSearch />
          {membershipData?.organization?.isCustomer && (
            <nav className="button-group mb-0 mt-lg-0 mt-2  d-flex">
              <a
                href="https://alice.imssystems.tech"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  style={{ cursor: "pointer" }}
                  className="avatar border my-1 mx-4"
                  src={aliceLogoHeadFilled}
                />
              </a>
              <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle
                  ref={dropdownRef}
                  color="primary"
                  className="m-0 p-0 rounded-left bg-transparent border-0 shadow-none "
                >
                  <Button
                    color="primary"
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                    className="py-2 px-3 rounded-left "
                  >
                    <i class="fa-solid fa-bolt pr-2" />
                    Quick Actions
                  </Button>

                  <Button color="primary" className="py-2 px-3">
                    <i
                      className={classNames("fa-solid", {
                        "fa-angle-up": dropdownOpen,
                        "fa-angle-down": !dropdownOpen,
                      })}
                    ></i>
                  </Button>
                </DropdownToggle>
                <DropdownMenu>
                  {/* <DropdownItem onClick={toggleModalClockIn}>
                    <Button
                      size="sm"
                      className="btn-icon d-flex align-items-center m-0"
                    >
                      <i className="ims-icons-20 icon-icon-clockclockwise-24" />
                      <span className="px-2 ml-0">Work log</span>
                    </Button>
                  </DropdownItem> */}
                  <DropdownItem
                    data-target="#searchModal"
                    data-toggle="modal"
                    onClick={toggleModalSend}
                  >
                    <Button
                      size="sm"
                      className="btn-icon d-flex align-items-center m-0"
                    >
                      <i className="ims-icons-20 icon-icon-paperplanetilt-24" />
                      <span className="px-2 ml-0">Send Report</span>
                    </Button>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </nav>
          )}
          <Nav navbar>
            <DrawerContextProvider>
              <Notifications />
            </DrawerContextProvider>

            <AccountDropDown />

            <li className="separator d-lg-none" />
          </Nav>
        </Collapse>
      </Navbar>
      <SendMailModal modalSend={modalSend} toggleModalSend={toggleModalSend} />
      <Clockin
        openClockIn={openClockIn}
        toggleModalClockIn={toggleModalClockIn}
      />
    </React.Fragment>
  );
};

export default AdminNavbar;
