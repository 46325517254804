import Loading from "components/Loader/Loading";
import { Panel, Panels } from "components/Panel/HorizontalPanel";
import {
  Button,
  DrawerContextProvider,
  DrawerOpener,
  DrawerRight,
  ImsCard,
  UncontrolledAlert,
} from "ims-ui-kit";
import React from "react";
import { Link } from "react-router-dom";
import { IMS_SERVICES } from "rolesAndPermissions";
import ErrorHandlerComponent from "views/shared/ErrorHandlerComponent";
import ComplianceToolTable from "../ComplianceToolTable";
import ESGGuidelines from "../ESGGuidelines";
import Analytics from "../Overview/Analytics";
import LOADER from "./actions";
import { useESG } from "./store";
import EnvironmentFilter from "./EnvironmentFilter";
import SocialFilter from "./SocialFilter";
import GovernanceFilter from "./GovernanceFilter";
import DocumentListOpener from "views/documentManagement/searchableList/components/DocumentListOpener";
import { ACTIONS } from "rolesAndPermissions";
import { EFFECTS } from "rolesAndPermissions";
import SearchableDocument from "views/documentManagement/searchableList/components/Index";
import useAccess from "hooks/useAccess";

const ESG = () => {
  let {
    processing,
    environmentalOverview,
    socialOverview,
    governanceOverview,
    environmentalDataTable,
    setEnvironmentalDataTable,
    socialDataTable,
    setSocialDataTable,
    governanceDataTable,
    setGovernanceDataTable,
    updateEnvironmentalDataTable,
    updateGovernanceDataTable,
    updateSocialDataTable,
    EnvironmentalQueryTools,
    SocialQueryTools,
    GovernanceQueryTools,
  } = useESG();

  let { authUser } = useAccess();
  return (
    <DrawerContextProvider>
      <React.Fragment>
        <div className="content">
          <Panels
            navLinks={["Overview", "Environmental", "Social", "Governance"]}
            defaultPanel={"Overview"}
          >
            <Panel panelId="Overview">
              <ImsCard>
                <ErrorHandlerComponent
                  hasError={
                    processing[LOADER.LOAD_ENVIRONMENTAL_OVERVIEW].error
                  }
                  errorMessage="This iso tool has been deleted or removed"
                >
                  {processing[LOADER.LOAD_ENVIRONMENTAL_OVERVIEW].status ? (
                    <Loading />
                  ) : (
                    <React.Fragment>
                      <Analytics
                        toolKit={IMS_SERVICES.ESG_ENVIRONMENTAL}
                        overview={environmentalOverview}
                        subcategory={"Environmental"}
                      />
                      <Analytics
                        toolKit={IMS_SERVICES.ESG_SOCIAL}
                        overview={socialOverview}
                        subcategory={"Social"}
                      />
                      <Analytics
                        toolKit={IMS_SERVICES.ESG_GOVERNANCE}
                        overview={governanceOverview}
                        subcategory={"Governance"}
                      />
                    </React.Fragment>
                  )}
                </ErrorHandlerComponent>
              </ImsCard>
            </Panel>
            <Panel panelId="Environmental">
              <ImsCard>
                <ErrorHandlerComponent
                  hasError={processing[LOADER.LOAD_ENVIRONMENTAL].error}
                  errorMessage="This toolkit has been deleted or removed"
                >
                  {processing[LOADER.LOAD_ENVIRONMENTAL].status && <Loading />}
                  <UncontrolledAlert color="primary">
                    Please review this guidelines and best practices to manage
                    this toolkit efficiently{" "}
                    <DrawerOpener drawerId="guideline-button">
                      <Link to="#" className=" py-0 alert-link">
                        Guidelines <i className="fa-solid fa-pen-nib" />
                      </Link>
                    </DrawerOpener>
                  </UncontrolledAlert>
                  <SearchableDocument
                    moduleTypes={["compliancecontrols"]}
                    complianceTools={["ESG Toolkit - Environmental"]}
                  />
                  <ComplianceToolTable
                    toolkit="Environmental & Social Governance"
                    dataTable={environmentalDataTable}
                    setDataTable={setEnvironmentalDataTable}
                    updateDataTable={updateEnvironmentalDataTable}
                    processing={processing[LOADER.LOAD_ENVIRONMENTAL]}
                    pagination={EnvironmentalQueryTools.toolState.pagination}
                    tableToolbar={
                      <React.Fragment>
                        {authUser({
                          service: IMS_SERVICES.DOCUMENT_MANAGEMENT,
                          action: ACTIONS.READ,
                          effect: EFFECTS.ALLOW,
                        }) && (
                          <DocumentListOpener>
                            <Button
                              color="primary"
                              outline
                              size="md"
                              className="shadow-sm--hover ms-3"
                            >
                              Documents
                            </Button>
                          </DocumentListOpener>
                        )}
                      </React.Fragment>
                    }
                    filterToolbar={<EnvironmentFilter />}
                    {...EnvironmentalQueryTools}
                  />
                </ErrorHandlerComponent>
              </ImsCard>
            </Panel>
            <Panel panelId="Social">
              <ImsCard>
                <ErrorHandlerComponent
                  hasError={processing[LOADER.LOAD_SOCIAL].error}
                  errorMessage="This toolkit has been deleted or removed"
                >
                  {processing[LOADER.LOAD_SOCIAL].status && <Loading />}
                  <UncontrolledAlert color="primary">
                    Please review this guidelines and best practices to manage
                    this toolkit efficiently{" "}
                    <DrawerOpener drawerId="guideline-button">
                      <Link to="#" className=" py-0 alert-link">
                        Guidelines <i className="fa-solid fa-pen-nib" />
                      </Link>
                    </DrawerOpener>
                  </UncontrolledAlert>
                  <SearchableDocument
                    moduleTypes={["compliancecontrols"]}
                    complianceTools={["ESG Toolkit - Social"]}
                  />
                  <ComplianceToolTable
                    toolkit="Environmental & Social Governance"
                    dataTable={socialDataTable}
                    setDataTable={setSocialDataTable}
                    updateDataTable={updateSocialDataTable}
                    processing={processing[LOADER.LOAD_SOCIAL]}
                    pagination={SocialQueryTools.toolState.pagination}
                    tableToolbar={
                      <React.Fragment>
                        {authUser({
                          service: IMS_SERVICES.DOCUMENT_MANAGEMENT,
                          action: ACTIONS.READ,
                          effect: EFFECTS.ALLOW,
                        }) && (
                          <DocumentListOpener>
                            <Button
                              color="primary"
                              outline
                              size="md"
                              className="shadow-sm--hover ms-3"
                            >
                              Documents
                            </Button>
                          </DocumentListOpener>
                        )}
                      </React.Fragment>
                    }
                    filterToolbar={<SocialFilter />}
                    {...SocialQueryTools}
                  />
                </ErrorHandlerComponent>
              </ImsCard>
            </Panel>
            <Panel panelId="Governance">
              <ImsCard>
                <ErrorHandlerComponent
                  hasError={processing[LOADER.LOAD_GOVERNANCE].error}
                  errorMessage="This toolkit has been deleted or removed"
                >
                  {processing[LOADER.LOAD_GOVERNANCE].status && <Loading />}
                  <UncontrolledAlert color="primary">
                    Please review this guidelines and best practices to manage
                    this toolkit efficiently{" "}
                    <DrawerOpener drawerId="guideline-button">
                      <Link to="#" className=" py-0 alert-link">
                        Guidelines <i className="fa-solid fa-pen-nib" />
                      </Link>
                    </DrawerOpener>
                  </UncontrolledAlert>
                  <SearchableDocument
                    moduleTypes={["compliancecontrols"]}
                    complianceTools={["ESG Toolkit - Governance"]}
                  />
                  <ComplianceToolTable
                    toolkit="Environmental & Social Governance"
                    dataTable={governanceDataTable}
                    setDataTable={setGovernanceDataTable}
                    updateDataTable={updateGovernanceDataTable}
                    processing={processing[LOADER.LOAD_GOVERNANCE]}
                    pagination={GovernanceQueryTools.toolState.pagination}
                    tableToolbar={
                      <React.Fragment>
                        {authUser({
                          service: IMS_SERVICES.DOCUMENT_MANAGEMENT,
                          action: ACTIONS.READ,
                          effect: EFFECTS.ALLOW,
                        }) && (
                          <DocumentListOpener>
                            <Button
                              color="primary"
                              outline
                              size="md"
                              className="shadow-sm--hover ms-3"
                            >
                              Documents
                            </Button>
                          </DocumentListOpener>
                        )}
                      </React.Fragment>
                    }
                    filterToolbar={<GovernanceFilter />}
                    {...GovernanceQueryTools}
                  />
                </ErrorHandlerComponent>
              </ImsCard>
            </Panel>
          </Panels>
        </div>
        <DrawerRight drawerId="guideline-button">
          {<ESGGuidelines />}
        </DrawerRight>
      </React.Fragment>
    </DrawerContextProvider>
  );
};

export default ESG;
