import Loading from "components/Loader/Loading";
import useAccess from "hooks/useAccess";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  PanelTab,
  PanelTabs,
  PanelWindow,
  Row,
  UncontrolledDropdown,
} from "ims-ui-kit";
import React from "react";
import AuditShortDetail from "views/audits/AuditShortDetail";
import ComplianceStripe from "views/compliance/searchableList/components/ComplianceStripe";
import { Attachments } from "views/shared/Attachments/Index";
import DetailsDrawerHeader from "views/shared/DetailComponents/DetailsDrawerHeader";
import DetailsWrapper from "views/shared/DetailComponents/DetailsWrapper";
import { DetailsSectionHeader } from "views/shared/DetailsSectionHeader";
import TaskManagement from "views/taskManagement/TaskManagement";
import TimeLine from "../shared/Timeline/Timeline";
import AttachmentsButtons from "./AttachmentsButtons";
import ContinualImprovementOverview from "./ContinualImprovementOverview";
import ContinualImprovementStatus from "./ContinualImprovementStatus";
import USER_ACTIONS from "./actions";
import { useCip } from "./store";

const CipDrawerDetail = () => {
  let {
    processing,
    visitingCip: cip,
    isImplementedCip,
    controlsOnVisitingCip,
    removeISOControl,
  } = useCip();
  let { authUser } = useAccess();
  return (
    <React.Fragment>
      {processing[USER_ACTIONS.LOAD_CIP]?.status ? (
        <Loading />
      ) : (
        cip && (
          <React.Fragment>
            <DetailsDrawerHeader data={cip} />
            <React.Fragment>
              <PanelTabs variant="outline" activeTab={0}>
                <PanelTab>
                  <i className="ims-icons-20 icon-icon-notebook-24 mr-1"></i>
                  Overview
                </PanelTab>
                <PanelTab>
                  <i className="ims-icons-20 icon-icon-list-24 mr-1"></i>
                  Details
                </PanelTab>
                <PanelTab>
                  <i
                    className="ims-icons-20 icon-icon-activity-24 mr-1
                        "
                  ></i>
                  Activity
                </PanelTab>
                <PanelTab>
                  <i className="ims-icons-20 icon-icon-clock-24 mr-1"></i>
                  Life Cycle
                </PanelTab>
                <PanelTab>
                  <i className="ims-icons-20 icon-icon-notepad-24 mr-1"></i>
                  Tasks
                </PanelTab>
                <PanelTab>
                  <i className="ims-icons-20 icon-icon-shieldcheck-24 mr-1"></i>
                  Linked controls
                </PanelTab>
                <PanelWindow tabId={0}>
                  <ContinualImprovementOverview />
                </PanelWindow>
                <PanelWindow tabId={1}>
                  <Row>
                    <Col md="12" className="">
                      <DetailsWrapper
                        label={"Opportunity for improvement:"}
                        iconClass={"tim-icons icon-pencil"}
                        value={cip.opportunityForImprovement}
                        labelClass={"pr-2"}
                      />
                    </Col>

                    <Col md="12">
                      <DetailsWrapper
                        label={"Attachments:"}
                        labelClass={"pr-2"}
                      />
                      <Row>
                        <Col md="12">
                          <Attachments s3Information={cip.attachments}>
                            <AttachmentsButtons />
                          </Attachments>
                        </Col>
                      </Row>
                    </Col>

                    {cip?.source?.moduleType === "audits" && (
                      <React.Fragment>
                        <DetailsSectionHeader title={`Audit detail`} />
                        <Row>
                          <Col md="12" className="mx-2">
                            <AuditShortDetail audit={cip?.source?.module} />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </Row>
                </PanelWindow>
                <PanelWindow tabId={2}>
                  {isImplementedCip(cip) ? (
                    <TimeLine
                      readOnly={true}
                      horizontalSpacing={false}
                      containerClass="mx-auto sm-12"
                      moduleType="cips"
                      moduleId={cip?._id}
                      module={cip}
                    />
                  ) : (
                    <TimeLine
                      editLabel="Actions"
                      editPlaceholder="New comment"
                      horizontalSpacing={true}
                      containerClass="mx-auto sm-12"
                      moduleType="cips"
                      moduleId={cip?._id}
                      module={cip}
                      isHorizontal={false}
                    />
                  )}
                </PanelWindow>
                <PanelWindow tabId={3}>
                  <>
                    <ContinualImprovementStatus />
                  </>
                </PanelWindow>
                <PanelWindow tabId={4}>
                  <TaskManagement moduleType="cips" moduleId={cip._id} />
                </PanelWindow>
                <PanelWindow tabId={5}>
                  {processing[USER_ACTIONS.LOAD_LINKED_CONTROLS_TO_CIP]
                    .status && <Loading />}
                  {controlsOnVisitingCip.length > 0 &&
                  !processing[USER_ACTIONS.LOAD_LINKED_CONTROLS_TO_CIP]
                    .status ? (
                    controlsOnVisitingCip.map((data) => (
                      <ComplianceStripe
                        key={data._id}
                        compliance={data}
                        actions={
                          <UncontrolledDropdown size="sm" direction="right">
                            <DropdownToggle
                              outline
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <i className="ims-icons-20 icon-icon-dotsthreeoutline-24" />
                            </DropdownToggle>
                            <DropdownMenu bottom>
                              <DropdownItem
                                onClick={(e) => {
                                  removeISOControl({
                                    toolkits: [],
                                    controls: [data?.control?._id],
                                  });
                                }}
                              >
                                Remove
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        }
                      />
                    ))
                  ) : (
                    <p className="text-secondary text-center">
                      There are no controls linked to this
                    </p>
                  )}
                </PanelWindow>
              </PanelTabs>
            </React.Fragment>
          </React.Fragment>
        )
      )}
    </React.Fragment>
  );
};

export default CipDrawerDetail;
