const USER_ACTIONS = {
  CREATE_USER: "create-user",
  AMEND_USER: "amend-user",
  LOAD_USERS: "load-users",
  LOAD_USER: "load-user",
  DELETE_USER: "delete-user",
  GRANT_ACCESS: "grant-access",
  REVOKE_ACCESS: "revoke-access",
  RESEND_VERIFICATION: "resend-verification",
  LOAD_MEMBERSHIP: "load-membership",
  UPDATE_MEMBERSHIP: "update-membership",


  TRANSFER_OWNERSHIP: "transfer-ownership",
};
export default USER_ACTIONS;
