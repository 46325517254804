import { Card, CardBody, CardTitle } from "ims-ui-kit";

const OrgState = ({ dataSet }) => {
  return (
    <Card
      style={{
        minHeight: "150px",
      }}
      variant="gradient"
      color="success"
    >
      <CardBody>
        <div className="numbers">
          <div className="d-flex align-items-center">
            <i
              style={{
                fontSize: "8px",
                color: "#FFC107",
              }}
              class="fa-solid fa-circle mr-2"
            ></i>{" "}
            <p className="card-category">Organisational State</p>
          </div>
          <CardTitle>
            <h3>{dataSet.organizationalState}</h3>
          </CardTitle>
        </div>
      </CardBody>
    </Card>
  );
};

export default OrgState;
