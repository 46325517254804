import { DrawerContextProvider } from "ims-ui-kit";
import PeopleTable from "./PeopleTable";
import { PeopleAssetsContextProvider } from "./store";
import { TagsAndCategoriesContextProvider } from "views/tagsAndCategoriesManager/store";

const PeopleAsset = (props) => {
  return (
    <>
      <DrawerContextProvider>
        <PeopleAssetsContextProvider {...props}>
        <TagsAndCategoriesContextProvider
            applicableModules={"peopleassets"}
          >
          <PeopleTable {...props} />
          </TagsAndCategoriesContextProvider>
        </PeopleAssetsContextProvider>
      </DrawerContextProvider>
    </>
  );
};

export default PeopleAsset;
