import React from "react";
import CalenderContent from "./CalenderContent";
import { CalenderContextProvider } from "./store";
const Calendar = () => {
  // Need to change the whole calendar soon
  return (
    <React.Fragment>
      <div className="content">
        <CalenderContextProvider>
          <CalenderContent />
        </CalenderContextProvider>
      </div>
    </React.Fragment>
  );
};

export default Calendar;
