import logo from "assets/img/ims-systems-full-logo.png";
import loginDots from "assets/img/login-dots.svg";
import loginHumanBg from "assets/img/login-human.svg";
import loginLightBg from "assets/img/login-light.svg";
import btnLogoImg from "assets/img/login-logo-btn.svg";
import { Card, CardBody, Form } from "ims-ui-kit";
import React from "react";
import { Col, Row } from "reactstrap";

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <div className="auth-container container-fluid">
        <a
          target={"_blank"}
          rel="noopener noreferrer"
          href="https://imssystems.tech"
        >
          <img
            className="auth-page-parent-logo-img-btn"
            src={btnLogoImg}
            alt=""
          />
        </a>
        <Row className="h-100">
          <Col xl="6" xs="12" className="p-0 d-flex align-items-center  ">
            <div className="auth-box-content">
              <div className="d-flex flex-column justify-content-center align-items-center mb-xl-5 ">
                <Form className="form ">
                  <Card className="shadow-none border-0 auth-card">
                    <CardBody>
                      <div className="auth-ims-logo-img">
                        <img src={logo} alt="" />
                      </div>
                      {children}
                    </CardBody>
                  </Card>
                </Form>
              </div>
            </div>
          </Col>
          <Col xl="6" className="p-0 d-xl-block d-none ">
            <img className="login-human-bg" src={loginHumanBg} alt="" />
            <div className="auth-bg-box">
              <img src={loginLightBg} alt="" />
            </div>
            <img className="auth-dots-bg" src={loginDots} alt="" />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Layout;
