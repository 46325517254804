import Loading from "components/Loader/Loading";
import { Panel, Panels } from "components/Panel/HorizontalPanel";
import { Button, ImsCard } from "ims-ui-kit";

import React from "react";
import { IMS_SERVICES } from "rolesAndPermissions";
import ErrorHandlerComponent from "views/shared/ErrorHandlerComponent";
import ComplianceToolTable from "../ComplianceToolTable";
import Analytics from "../Overview/Analytics";
import LOADER from "./actions";
import { useISO27002 } from "./store";
import Iso27002Filter from "./Iso27002Filter";
import DocumentListOpener from "views/documentManagement/searchableList/components/DocumentListOpener";
import SearchableDocument from "views/documentManagement/searchableList/components/Index";
import { ACTIONS } from "rolesAndPermissions";
import { EFFECTS } from "rolesAndPermissions";
import useAccess from "hooks/useAccess";

const Iso27002Compliance = (props) => {
  let {
    processing,
    iso27002Controls,
    setIso27002Controls,
    iso27002Overview,
    Iso27002QueryTools,
    updateDataTable,
    fetchIso27002ToolControls,
  } = useISO27002();

  let { authUser } = useAccess();
  return (
    <React.Fragment>
      <div className="content">
        <Panels
          navLinks={["Overview", "ISO 27002 (2013)"]}
          defaultPanel={"Overview"}
        >
          <Panel panelId="Overview">
            <ImsCard>
              <ErrorHandlerComponent
                hasError={processing[LOADER.LOAD_OVERVIEW_27002].error}
                errorMessage="This iso tool has been deleted or removed"
              >
                {processing[LOADER.LOAD_OVERVIEW_27002].status ? (
                  <Loading />
                ) : (
                  <Analytics
                    toolKit={IMS_SERVICES.ISO27002}
                    overview={iso27002Overview}
                  />
                )}
              </ErrorHandlerComponent>
            </ImsCard>
          </Panel>
          <Panel panelId="ISO 27002 (2013)">
            <ImsCard>
              <ErrorHandlerComponent
                hasError={processing[LOADER.LOAD_COMPLIANCE_27002].error}
                errorMessage="This iso tool has been deleted or removed"
              >
                {processing[LOADER.LOAD_COMPLIANCE_27002].status && <Loading />}
                <SearchableDocument
                  moduleTypes={["compliancecontrols"]}
                  complianceTools={["ISO 27002"]}
                />
                <ComplianceToolTable
                  toolkit="ISO 27002 (2013)"
                  dataTable={iso27002Controls}
                  updateDataTable={updateDataTable}
                  setDataTable={setIso27002Controls}
                  processing={processing[LOADER.LOAD_COMPLIANCE_27002]}
                  pagination={Iso27002QueryTools.toolState.pagination}
                  tableToolbar={
                    <React.Fragment>
                      {authUser({
                        service: IMS_SERVICES.DOCUMENT_MANAGEMENT,
                        action: ACTIONS.READ,
                        effect: EFFECTS.ALLOW,
                      }) && (
                        <DocumentListOpener>
                          <Button
                            color="primary"
                            outline
                            size="md"
                            className="shadow-sm--hover ms-3"
                          >
                            Documents
                          </Button>
                        </DocumentListOpener>
                      )}
                    </React.Fragment>
                  }
                  filterToolbar={<Iso27002Filter />}
                  {...Iso27002QueryTools}
                />
              </ErrorHandlerComponent>
            </ImsCard>
          </Panel>
        </Panels>
      </div>
    </React.Fragment>
  );
};

export default Iso27002Compliance;
