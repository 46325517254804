const USER_ACTIONS = {
  LOAD_OVERVIEW: "load-overview",
  LOAD_REPOSITORIES: "load-repositories",
  LOAD_DELETED_REPOSITORIES:"load-deleted-repositories",
  CREATE_REPOSITORY:"create-repository",
  SOFT_DELETE_REPOSITORY:"soft-delete-repository",
  HARD_DELETE_REPOSITORY:"hard-delete-repository",
  RESTORE_REPOSITORY:"restore-repository",
};
export default USER_ACTIONS;
