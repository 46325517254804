import Loading from "components/Loader/Loading";
import { Panel, Panels } from "components/Panel/HorizontalPanel";
import { Button, ImsCard } from "ims-ui-kit";
import React from "react";
import { IMS_SERVICES } from "rolesAndPermissions";
import ErrorHandlerComponent from "views/shared/ErrorHandlerComponent";
import ComplianceToolTable from "../ComplianceToolTable";
import Analytics from "../Overview/Analytics";
import LOADER from "./actions";
import { useISO14001 } from "./store";
import Iso14001Filter from "./Iso14001Filter";
import SearchableDocument from "views/documentManagement/searchableList/components/Index";
import DocumentListOpener from "views/documentManagement/searchableList/components/DocumentListOpener";
import { ACTIONS } from "rolesAndPermissions";
import { EFFECTS } from "rolesAndPermissions";
import useAccess from "hooks/useAccess";

const Iso14001 = (props) => {
  let {
    processing,
    Iso14001QueryTools,
    iso14001Controls,
    setIso14001Controls,
    iso14001Overview,
    updateDataTable,
  } = useISO14001();

  let { authUser } = useAccess();

  return (
    <React.Fragment>
      <div className="content">
        <Panels
          navLinks={["Overview", "ISO 14001 (2015)"]}
          defaultPanel={"Overview"}
        >
          <Panel panelId="Overview">
            <ImsCard>
              <ErrorHandlerComponent
                hasError={processing[LOADER.LOAD_OVERVIEW_14001].error}
                errorMessage="This iso tool has been deleted or removed"
              >
                {processing[LOADER.LOAD_OVERVIEW_14001].status ? (
                  <Loading />
                ) : (
                  <Analytics
                    toolKit={IMS_SERVICES.ISO14001}
                    overview={iso14001Overview}
                  />
                )}
              </ErrorHandlerComponent>
            </ImsCard>
          </Panel>
          <Panel panelId="ISO 14001 (2015)">
            <ImsCard>
              <ErrorHandlerComponent
                hasError={processing[LOADER.LOAD_COMPLIANCE_14001].error}
                errorMessage="This iso tool has been deleted or removed"
              >
                {processing[LOADER.LOAD_COMPLIANCE_14001].status && <Loading />}
                <SearchableDocument
                  moduleTypes={["compliancecontrols"]}
                  complianceTools={["ISO 14001"]}
                />
                <ComplianceToolTable
                  toolkit="ISO 14001 (2015)"
                  dataTable={iso14001Controls}
                  setDataTable={setIso14001Controls}
                  updateDataTable={updateDataTable}
                  processing={processing[LOADER.LOAD_COMPLIANCE_14001]}
                  pagination={Iso14001QueryTools.toolState.pagination}
                  tableToolbar={
                    <React.Fragment>
                      {authUser({
                        service: IMS_SERVICES.DOCUMENT_MANAGEMENT,
                        action: ACTIONS.READ,
                        effect: EFFECTS.ALLOW,
                      }) && (
                        <DocumentListOpener>
                          <Button
                            color="primary"
                            outline
                            size="md"
                            className="shadow-sm--hover ms-3"
                          >
                            Documents
                          </Button>
                        </DocumentListOpener>
                      )}
                    </React.Fragment>
                  }
                  filterToolbar={<Iso14001Filter />}
                  {...Iso14001QueryTools}
                />
              </ErrorHandlerComponent>
            </ImsCard>
          </Panel>
        </Panels>
      </div>
    </React.Fragment>
  );
};

export default Iso14001;
