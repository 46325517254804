import Loading from "components/Loader/Loading";
import { Panel, Panels } from "components/Panel/HorizontalPanel";
import useAccess from "hooks/useAccess";
import useQuery from "hooks/useQuery/index.js";
import React, { useEffect } from "react";
import { ACTIONS, EFFECTS, IMS_SERVICES } from "rolesAndPermissions";
import { getGroupPremises } from "services/iamGroupPremisesServices";
import PremisesForm from "./PremisesForm";
import PremisesTable from "./PremisesTable";
import { ImsCard } from "ims-ui-kit";
import { imsLogger } from "services/loggerService";

const Premises = (props) => {
  let [premises, setPremises] = React.useState([]);
  let [processing, setProcessing] = React.useState({
    action: "load-premises",
    id: null,
  });

  let { query, toolState, getQuery, updatePagination, ...queryHandlers } =
    useQuery();

  let { authUser } = useAccess();
  const addToTable = (premise) =>
    setPremises((prevPremises) => [premise, ...prevPremises]);

  const fetchData = async (qStr) => {
    try {
      setProcessing({ action: "load-premises", id: null });
      let { data } = await getGroupPremises({ query: `${qStr}` });
      setPremises(data.iamGroupPremises);
      updatePagination(data.pagination);
    } catch (error) {
      imsLogger(error.response);
    }
    setProcessing({ action: null, id: null });
  };

  useEffect(() => {
    fetchData(getQuery());
  }, [query]);
  return (
    <div className="content">
      <Panels
        defaultPanel={"Premises"}
        navLinks={
          authUser({
            service: IMS_SERVICES.IAM_PREMISES,
            action: ACTIONS.CREATE,
            effect: EFFECTS.ALLOW,
          })
            ? ["Create Premise", "Premises"]
            : ["Premises"]
        }
      >
        <Panel panelId="Create Premise">
          <ImsCard>
            <PremisesForm
              {...props}
              setProcessing={setProcessing}
              processing={processing}
              addToTable={addToTable}
            />
          </ImsCard>
        </Panel>
        <Panel panelId="Premises">
          <ImsCard>
            {processing.action === "load-premises" && <Loading />}
            <PremisesTable
              dataTable={premises}
              processing={processing}
              setProcessing={setProcessing}
              setPremises={setPremises}
              pathname={props.match.url}
              pagination={toolState.pagination}
              {...queryHandlers}
            />
          </ImsCard>
        </Panel>
      </Panels>
    </div>
  );
};

export default Premises;
