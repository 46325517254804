export const IMPORTER_STATE_ACTIONS = {
  UPDATE_SELECTED_FILES: "update-selected-files",
  UPDATE_SHEETS: "update-sheets",
  UPDATE_IMS_SCHEMA: "update-ims-schema",
  UPDATE_CONFIG: "update-config",
  UPDATE_VALIDATIONS: "update-validations",
  ADD_DATA_MAP: "add-data-map",
  REMOVE_DATA_MAP: "remove-data-map",
  RESET_IMPORTER: "reset-importer",
  SUBMISSION_SUCCESS: "submission-success",
  VALIDATION_DATA_TRASFER: "validation-data-transfer",
  IMPORT_DATA_TRASFER: "import-data-transfer",
};
export const USER_ACTIONS = {
  VALIDATION: "validation",
  IMPORT: "import",
};
