import Loading from "components/Loader/Loading";
import RepositoriesContextProvider from "./store/Context";
import RepositoriesTable from "./RepositoriesTable";
import useRepositories from "./store/useStore";
import { DrawerContextProvider } from "ims-ui-kit";


const RepositoriesTableIndex = (props) => {
  // return <div className="content"><p className="text-center my-5">Document management is currently under maintenance.</p></div>
  return (
    <DrawerContextProvider {...props}>
      <RepositoriesContextProvider {...props}>
        <div className="content">
          <RepositoriesTable {...props} />
        </div>
      </RepositoriesContextProvider>
    </DrawerContextProvider>
  );
};

export default RepositoriesTableIndex;
