const LOADER = {
    LOAD_ENVIRONMENTAL: "load-environmental",
    LOAD_SOCIAL: "load-social",
    LOAD_GOVERNANCE: "load-governance",
    LOAD_OVERVIEW: "load-overview",
    LOAD_SECTION: "load-section",
    LOAD_ENVIRONMENTAL_OVERVIEW: "load-environmental-overview",
    LOAD_SOCIAL_OVERVIEW: "load-social-overview",
    LOAD_GOVERNANCE_OVERVIEW: "load-governance-overview",
};
export default LOADER;