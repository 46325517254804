import Loading from "components/Loader/Loading";
import ReactTable from "components/ReactTable/ReactTable";
import { SuperGlobalContext } from "contexts/SuperGlobalContext";
import NotificationContext from "contexts/notificationContext";
import useAccess from "hooks/useAccess";
import Index from "views/tagsAndCategoriesManager/Index";
import {
  Button,
  DrawerOpener,
  DrawerRight,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
  useDrawer,
} from "ims-ui-kit";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import { ACTIONS, EFFECTS, IMS_SERVICES } from "rolesAndPermissions";
import SearchableDocument, {
  DocumentListOpener,
} from "views/documentManagement/searchableList/components/Index";
import BadgeStatus from "views/shared/StatusMapper/BadgeStatus";
import TimeDateComponent from "views/shared/TimeDateComponent";
import TaskForm from "views/taskManagement/TaskForm";
import { useTask } from "views/taskManagement/store";
import SearchableCompliance from "../compliance/searchableList/components/Index";
import AnalyticalAssistant from "../shared/ai/analyticalAssistant/Index";
import { risk_analyser } from "../shared/ai/analyticalAssistant/templates";
import CreateRisk from "./CreateRisk";
import RiskDrawerDetail from "./RiskDrawerDetail";
import RiskDrawerForm from "./RiskDrawerForm";
import RiskForm from "./RiskForm";
import RiskToolBar from "./RiskToolBar";
import USER_ACTIONS from "./actions";
import filters from "./filters";
import useRisk from "./store/useRisk";
import tables from "./tables";
import RiskFilter from "./RiskFilter";
const defaultdata = [["No data found"]];

const ReactTables = ({ ...props }) => {
  let {
    risks: dataTable,
    processing,
    fetchRisks,
    isMitigatedRisk,
    RiskQueryTools,
    updateRisk,
    nudgeRisk,
    escalateRisk,
    deleteRisk,
    visitRisk,
    visitingRisk,
    linkISOControl,
    removeISOControl,
    controlsOnVisitingRisk,
    createRisk,
    pathname,
    alert,
    warningWithConfirmMessage,
    reloadRisk,
    ...rest
  } = useRisk();
  let { handleCreateTask } = useTask();
  let { authUser, authAdminAccess, entityAccessControl } = useAccess();
  let notify = React.useContext(NotificationContext);
  let { organisation } = React.useContext(SuperGlobalContext);
  let history = useHistory();

  dataTable = dataTable ? dataTable : defaultdata;
  let { openDrawer, closeDrawer } = useDrawer();

  const data = React.useMemo(
    () =>
      dataTable.map((risk, key) => {
        return {
          id: risk._id,
          ID: risk.reference,
          group: risk.group && risk.group.name,
          risk: risk.title,
          owner: risk.owner?.name,
          score: (
            <span
              className={
                risk.score.total.current <= 10
                  ? "text-success"
                  : risk.score.total.current > 10 &&
                    risk.score.total.current <= 15
                  ? "text-warning"
                  : "text-danger"
              }
            >
              {risk.score.total.current}
            </span>
          ),
          created_on: <TimeDateComponent date={risk.created.on} />,
          escalated_on:
            risk.escalated.status &&
            moment(risk.escalated?.on).format("DD/MM/YYYY"),
          mitigated_on:
            visitingRisk && moment(risk.mitigated?.on).format("DD/MM/YYYY"),
          accepted_on:
            risk.accepted.status &&
            moment(risk.accepted?.on).format("DD/MM/YYYY"),
          shouldHighlight: !risk.mitigated.status && risk.escalated.status,
          // activateView: activateView,
          data: risk,
          status: risk.mitigated.status ? (
            <BadgeStatus status="Mitigated" />
          ) : risk.accepted.status ? (
            <BadgeStatus status="Accepted" />
          ) : (!isMitigatedRisk(risk) || !risk?.accepted?.status) &&
            risk.escalated.status ? (
            <BadgeStatus status="Escalated" />
          ) : (
            <BadgeStatus status="Open" />
          ),

          actions: (
            <>
              <UncontrolledDropdown size="sm" direction="right">
                <DropdownToggle
                  outline
                  onClick={(e) => {
                    e.stopPropagation();
                    // setRiskId(risk._id);
                  }}
                  data-display="static"
                  className="border border rounded-circle"
                >
                  <i className="fa-solid fa-ellipsis-h" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    id="detail"
                    tooltip="View Details"
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(`/admin/risks/${risk?._id}`);
                    }}
                  >
                    Details
                  </DropdownItem>

                  {!isMitigatedRisk(risk) && (
                    <DropdownItem
                      onClick={(e) => {
                        if (!risk.owner) {
                          notify("Risk owner is not assigned", "danger");
                          return;
                        }
                        warningWithConfirmMessage(
                          `${risk.owner.name} will be nudged to look at ${risk.reference} ${risk.title}`,
                          () => {
                            nudgeRisk(risk);
                          }
                        );
                        e.stopPropagation();
                      }}
                      name="nudge"
                      id="nudge"
                    >
                      {processing[USER_ACTIONS.NUDGE_OWNER].status &&
                      processing[USER_ACTIONS.NUDGE_OWNER].id === risk._id ? (
                        <Spinner size="sm" />
                      ) : (
                        "Nudge"
                      )}
                    </DropdownItem>
                  )}
                  {/* <Tooltip placement="top" target="nudge" trigger="hover">
                    {new Date(risk.nextNudgeAt) > new Date()
                      ? `Already nudged ${risk.owner?.name}`
                      : `Nudge ${risk.owner?.name}`}
                  </Tooltip> */}
                  {!isMitigatedRisk(risk) &&
                    authUser({
                      service: IMS_SERVICES.RISK_MANAGEMENT,
                      action: ACTIONS.DELETE,
                      effect: EFFECTS.ALLOW,
                    }) && (
                      <DropdownItem
                        name="escalate"
                        id="escalate"
                        onClick={(e) => {
                          if (risk.escalated.status) {
                            e.stopPropagation();
                            notify("Risk already escalated", "danger");
                            return;
                          }
                          warningWithConfirmMessage(
                            "This risk will be escalated",
                            () => {
                              escalateRisk(risk);
                            }
                          );
                          e.stopPropagation();
                        }}
                      >
                        {processing[USER_ACTIONS.ESCALATE_RISK].status &&
                        processing[USER_ACTIONS.ESCALATE_RISK].id ===
                          risk._id ? (
                          <Spinner size="sm" />
                        ) : (
                          "Escalate"
                        )}
                      </DropdownItem>
                    )}
                  {/* <Tooltip placement="top" target="escalate" trigger="hover">
                    Escalate
                  </Tooltip> */}
                  {!isMitigatedRisk(risk) &&
                    authUser({
                      service: IMS_SERVICES.RISK_MANAGEMENT,
                      action: ACTIONS.DELETE,
                      effect: EFFECTS.ALLOW,
                    }) &&
                    (authAdminAccess() ||
                      entityAccessControl({
                        users: risk.created.by ? [risk.created.by._id] : [],
                        effect: "Allow",
                      })) && (
                      <DropdownItem
                        onClick={(e) => {
                          warningWithConfirmMessage(
                            "This risk will be deleted",
                            () => {
                              deleteRisk(risk);
                            }
                          );
                          e.stopPropagation();
                        }}
                        name="delete"
                        id="delete"
                        tooltip="Delete"
                      >
                        {processing[USER_ACTIONS.DELETE_RISK].status &&
                        processing[USER_ACTIONS.DELETE_RISK].id === risk._id ? (
                          <Spinner size="sm" />
                        ) : (
                          "Delete"
                        )}
                      </DropdownItem>
                    )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          ),
        };
      }),
    [processing, dataTable]
  );
  let columns = React.useMemo(
    () => RiskQueryTools.toolState.filter.tableState || tables.default,
    [RiskQueryTools.toolState]
  );
  return (
    <React.Fragment>
      {alert}

      <SearchableDocument moduleTypes={["risks"]} />
      <div className="content">
        {processing[USER_ACTIONS.LOAD_RISKS].status && <Loading />}
        <ReactTable
          data={data}
          // filterable
          resizable={false}
          columns={columns.slice()}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
          tableToolbar={
            <React.Fragment>
              <CreateRisk />
              {"  "}
              <DrawerOpener drawerId="tags">
                <Button
                  color="primary"
                  outline
                  size="md"
                  className="shadow-sm--hover ms-3"
                >
                  <i className="ims-icons icon-icon-tag-24 me-1 p-0"></i>
                  {"  "} Categories
                </Button>
              </DrawerOpener>
              {authUser({
                service: IMS_SERVICES.DOCUMENT_MANAGEMENT,
                action: ACTIONS.READ,
                effect: EFFECTS.ALLOW,
              }) && (
                <DocumentListOpener>
                  <Button
                    color="primary"
                    outline
                    size="md"
                    className="shadow-sm--hover ms-3"
                  >
                    Documents
                  </Button>
                </DocumentListOpener>
              )}
            </React.Fragment>
          }
          filterToolbar={<RiskFilter />}
          rowProps={function (row) {
            return {
              onClick: function (e) {
                visitRisk(row.original?.data);
                openDrawer("risk-detail");
              },
            };
          }}
          onPageChange={fetchRisks}
          pagination={RiskQueryTools.toolState.pagination}
          filters={filters}
          {...RiskQueryTools}
          {...props}
          {...rest}
          title={`Risks`}
          isFilterable
          isSearchable
          // title={}
        />
        {/* Detail Drawer */}
        <DrawerRight
          drawerId="risk-detail"
          onDrawerClose={() => {
            visitRisk(null);
          }}
          toolbar={<RiskToolBar />}
        >
          {<RiskDrawerDetail />}
        </DrawerRight>
        {/* Edit Risk Form Drawer */}
        <DrawerRight drawerId="edit-risk-form">
          {visitingRisk && <RiskDrawerForm />}
        </DrawerRight>
        <DrawerRight drawerId="risk-analyser">
          {visitingRisk && (
            <AnalyticalAssistant
              template={risk_analyser}
              source={{
                moduleType: "risks",
                module: visitingRisk?._id,
              }}
              data={{
                organisation,
                risk: visitingRisk,
              }}
              onCreateNewTask={reloadRisk}
            />
          )}
        </DrawerRight>
        <DrawerRight drawerId="compliance-control-picker">
          <SearchableCompliance
            onNewSelection={(data) =>
              linkISOControl({
                controls: [data?.control?._id],
                toolkits: [],
              })
            }
            onDeselection={(data) => {
              removeISOControl({
                controls: [data?.control?._id],
                toolkits: [],
              });
            }}
            preSelectedControls={controlsOnVisitingRisk}
          />
        </DrawerRight>
        <DrawerRight drawerId="add-task-form">
          {visitingRisk && (
            <TaskForm
              drawerView={true}
              module={visitingRisk._id}
              moduleType="risks"
              onSubmit={async (data) => {
                await handleCreateTask(data);
                closeDrawer("add-task-form");
                reloadRisk();
              }}
            />
          )}
        </DrawerRight>
        <DrawerRight drawerId="create-risk">
          <RiskForm
            drawerView={true}
            onSubmit={async (data) => {
              await createRisk(data);
              closeDrawer("create-risk");
              openDrawer("risk-detail");
            }}
          />
        </DrawerRight>
        <DrawerRight drawerId="tags">
          <Index applicableModules="risks" />
        </DrawerRight>
      </div>
    </React.Fragment>
  );
};

export default ReactTables;
