import useUsers from "hooks/useUsers";
import { Card, CardBody, ImsCarousel } from "ims-ui-kit";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import StaffList from "./StaffList";
import StaffRemoteList from "./StaffRemoteList";

const DashboardCarousel = ({ dataSet, HoS, staffRemote, staff }) => {
  return (
    dataSet && (
      <ImsCarousel slidesPerView={3} navigation>
        <Card
          variant="active"
          style={{
            minHeight: "184px",
          }}
        >
          <CardBody className="d-flex flex-column justify-content-between">
            <div>
              <div className="d-flex align-items-center">
                <i
                  style={{
                    fontSize: "8px",
                  }}
                  class="fa-solid fa-circle text-primary"
                ></i>{" "}
                <span
                  style={{
                    color: "#152536",
                    marginLeft: "8px",
                  }}
                >
                  Confidence level
                </span>
              </div>
              <h4>
                Your organisational confidence level is{" "}
                <span className="text-primary">
                  {dataSet.organizationalConfidence}%
                </span>
              </h4>
            </div>
          </CardBody>
        </Card>
        <Link to="/admin/groups">
          <Card
            variant="secondary"
            style={{
              minHeight: "184px",
            }}
          >
            <CardBody className="d-flex flex-column justify-content-between">
              {HoS ? (
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p
                      style={{
                        color: "#152536",
                      }}
                    >
                      Business Unit
                    </p>
                    <i
                      style={{
                        color: "#CED4DA",
                      }}
                      class="fa-solid fa-building"
                    ></i>
                  </div>
                  <h4
                    style={{
                      color: "#17A2B8",
                    }}
                  >
                    {dataSet.businessFunctions}{" "}
                  </h4>
                </div>
              ) : (
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p
                      style={{
                        color: "#152536",
                      }}
                    >
                      Business Unit
                    </p>
                    <i
                      style={{
                        color: "#CED4DA",
                      }}
                      class="fa-solid fa-building"
                    ></i>
                  </div>
                  <h1
                    style={{
                      color: "#17A2B8",
                    }}
                  >
                    {dataSet.businessFunctions}{" "}
                  </h1>
                </div>
              )}
            </CardBody>
          </Card>
        </Link>
        <Card
          variant="secondary"
          style={{
            minHeight: "184px",
          }}
        >
          <CardBody className="d-flex flex-column justify-content-between">
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <p
                  style={{
                    color: "#152536",
                  }}
                >
                  Staff
                </p>
                <i
                  style={{
                    color: "#CED4DA",
                  }}
                  class="fa-solid fa-user"
                ></i>
              </div>
              <h3
                style={{
                  color: "#17A2B8",
                }}
              >
                {dataSet.numberOfStaffs} Member
                {dataSet.numberOfStaffs > 1 ? "s" : ""}
              </h3>
            </div>
            <div>
              <StaffList staff={staff} />
            </div>
          </CardBody>
        </Card>

        <Card
          variant="secondary"
          style={{
            minHeight: "184px",
          }}
        >
          <CardBody className="d-flex flex-column justify-content-between">
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <p
                  style={{
                    color: "#152536",
                  }}
                >
                  Remote Staff
                </p>
                <i
                  style={{
                    color: "#CED4DA",
                  }}
                  class="fa-solid fa-user "
                ></i>
              </div>
              <h3
                style={{
                  color: "#17A2B8",
                }}
              >
                {dataSet.staffRemote} Member
                {dataSet.staffRemote > 1 ? "s" : ""}
              </h3>
            </div>
            <div>
              <StaffRemoteList staffRemote={staffRemote} />
            </div>
          </CardBody>
        </Card>
        {!HoS && (
          <Card
            variant="secondary"
            style={{
              minHeight: "184px",
            }}
          >
            <CardBody className="d-flex flex-column justify-content-between">
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <p
                    style={{
                      color: "#152536",
                    }}
                  >
                    Compliance Bodies
                  </p>
                  <i
                    style={{
                      color: "#CED4DA",
                    }}
                    class="fa-solid fa-building "
                  ></i>
                </div>
                <h1
                  style={{
                    color: "#17A2B8",
                  }}
                >
                  {dataSet.complianceFunctions}{" "}
                </h1>
              </div>
            </CardBody>
          </Card>
        )}
      </ImsCarousel>
    )
  );
};

export default DashboardCarousel;
