
const tables = {
    customers: [
        {
            Header: "Reference",
            accessor: "ID",
        },
        {
            Header: "Business unit",
            accessor: "group",
        },
        {
            Header: "Organisation",
            accessor: "oraganisation_name",
        },
        {
            Header: "Email",
            accessor: "email",
        },
        {
            Header: "Account manager",
            accessor: "account_manager",
        },
        {
            Header: "Account number",
            accessor: "account_number",
        },
        {
            Header: "Contract start date",
            accessor: "contract_startDate",
        },
        {
            Header: "Contract end date",
            accessor: "contract_endDate",
        },
        {
            Header: "Contract review date",
            accessor: "reviewDate",
        },
        {
            Header: "Actions",
            accessor: "actions",
        },
    ],
    prospects: [
        {
            Header: "Reference",
            accessor: "ID",
        },
        {
            Header: "Business unit",
            accessor: "group",
        },
        {
            Header: "Organisation",
            accessor: "oraganisation_name",
        },
        {
            Header: "Email",
            accessor: "email",
        },
        {
            Header: "Organisation profile",
            accessor: "stage",
        },
        {
            Header: "Status",
            accessor: "status",
        },
        {
            Header: "Probability",
            accessor: "probability",
        },
        {
            Header: "Contract value",
            accessor: "contractValue",
        },
        {
            Header: "Source",
            accessor: "source",
        },
        {
            Header: "Actions",
            accessor: "actions",
        },
    ],
    warmleads: [
        {
            Header: "Reference",
            accessor: "ID",
        },
        {
            Header: "Business unit",
            accessor: "group",
        },
        {
            Header: "Organisation",
            accessor: "oraganisation_name",
        },
        {
            Header: "Email",
            accessor: "email",
        },
        {
            Header: "Organisation profile",
            accessor: "stage",
        },
        {
            Header: "Status",
            accessor: "status",
        },
        {
            Header: "Probability",
            accessor: "probability",
        },
        {
            Header: "Contract value",
            accessor: "contractValue",
        },
        {
            Header: "Source",
            accessor: "source",
        },
        {
            Header: "Actions",
            accessor: "actions",
        },
    ],
    proposals: [
        {
            Header: "Reference",
            accessor: "ID",
        },
        {
            Header: "Business unit",
            accessor: "group",
        },
        {
            Header: "Organisation",
            accessor: "oraganisation_name",
        },
        {
            Header: "Email",
            accessor: "email",
        },
        {
            Header: "Organisation profile",
            accessor: "stage",
        },
        {
            Header: "Status",
            accessor: "status",
        },
        {
            Header: "Probability",
            accessor: "probability",
        },
        {
            Header: "Contract value",
            accessor: "contractValue",
        },
        {
            Header: "Source",
            accessor: "source",
        },
        {
            Header: "Actions",
            accessor: "actions",
        },
    ],
    qualified: [
        {
            Header: "Reference",
            accessor: "ID",
        },
        {
            Header: "Business unit",
            accessor: "group",
        },
        {
            Header: "Organisation",
            accessor: "oraganisation_name",
        },
        {
            Header: "Email",
            accessor: "email",
        },
        {
            Header: "Organisation profile",
            accessor: "stage",
        },
        {
            Header: "Status",
            accessor: "status",
        },
        {
            Header: "Probability",
            accessor: "probability",
        },
        {
            Header: "Contract value",
            accessor: "contractValue",
        },
        {
            Header: "Source",
            accessor: "source",
        },
        {
            Header: "Actions",
            accessor: "actions",
        },
    ],
    default: [
        {
            Header: "Reference",
            accessor: "ID",
        },
        {
            Header: "Business unit",
            accessor: "group",
        },
        {
            Header: "Organisation",
            accessor: "oraganisation_name",
        },
        {
            Header: "Email",
            accessor: "email",
        },
        {
            Header: "Actions",
            accessor: "actions",
        },
    ],
}
export default tables