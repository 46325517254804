import { Col, Row } from "ims-ui-kit";
import AboutRepository from "./AboutRepository";
import DocumentActions from "./DocumentActions";
import ImportantAlerts from "./ImportantAlerts";
import NodesTable from "./NodesTable";
import RepositoryDescription from "./RepositoryDescription";
import RepositorySideBar from "./RepositorySideBar";
const RepositoryBody = () => {
  return (
    <>
      <div className="doc-body-container">
        <Row className="">
          <Col xl="8">
            <ImportantAlerts />
            <DocumentActions />
            <NodesTable />
            <Row className="mt-3 mt-xl-0 mb-4">
              <Col xl="12">
                <RepositoryDescription />
              </Col>
            </Row>
          </Col>
          <Col xl="4">
            <RepositorySideBar />
            <AboutRepository />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default RepositoryBody;
