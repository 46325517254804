import ImsBarChart from "components/charts/ImsBarChart";
import ImsCircularProgressChart from "components/charts/ImsCircularProgressChart";
import ImsPaddingPieChart from "components/charts/ImsPaddingPieChart";
import { Card, CardBody, CardTitle, Col, Row } from "ims-ui-kit";
import React from "react";
import { Link } from "react-router-dom";

const AuditInspection = ({ dataSet, HoS }) => {
  return (
    <React.Fragment>
      {HoS ? (
        <Row>
          <Col lg="8" md="8">
            <Card variant="secondary" hover={false}>
              <Link to="/admin/audits/internal">
                <h4
                  style={{
                    fontSize: "20px",
                  }}
                >
                  Audits overview
                </h4>
              </Link>
              <CardBody>
                <ImsBarChart
                  data={dataSet.internalAudits.data}
                  options={dataSet.internalAudits.options}
                />
              </CardBody>
            </Card>
          </Col>
          <Col className="mr-auto" lg="4">
            <Card variant="secondary" hover={false}>
              <CardBody>
                <Row>
                  <Col xs="12" className="mb-5 mt-4 text-center">
                    <ImsCircularProgressChart
                      data={dataSet.sheduledAuditVsCompletedAudit.data}
                      options={dataSet.sheduledAuditVsCompletedAudit.options}
                    />
                  </Col>
                  <Col xs="12">
                    <CardTitle tag="h4" className="text-center">
                      <i className="ims-icons-20 icon-icon-trophy-24 text-success" />{" "}
                      Completed {dataSet.audits.completed}
                    </CardTitle>
                    <p className="category text-center">
                      Scheduled {dataSet.audits.inCompleted}
                    </p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xl="8">
            <Card
              style={{
                minHeight: "308px",
              }}
              variant="secondary"
              hover={false}
            >
              <Link to="/admin/audits/internal">
                <h4
                  style={{
                    fontSize: "20px",
                  }}
                >
                  Non-conformities
                </h4>
              </Link>

              <CardBody>
                <Row className="mt-5 ">
                  <Col md="6" className="mb-xl-0 mb-5">
                    <div className="chart-area">
                      <ImsPaddingPieChart
                        data={dataSet.nonConformitiesVsConformities.data}
                        options={dataSet.nonConformitiesVsConformities.options}
                      />
                    </div>
                  </Col>
                  <Col md="6" className="text-xl-left text-center">
                    <h4
                      style={{
                        fontSize: "20px",
                      }}
                      className="text-secondary"
                    >
                      Top business units
                    </h4>
                    <p className="category my-3">
                      <i
                        style={{
                          width: "8px",
                          height: "8px",
                        }}
                        class="fa-solid fa-circle mr-3"
                      ></i>{" "}
                      {dataSet.nonConformities.businessFunctionNames[0]}{" "}
                      {dataSet.nonConformities.amount[0]}
                    </p>
                    <p className="category my-3">
                      <i
                        style={{
                          width: "8px",
                          height: "8px",
                        }}
                        class="fa-solid fa-circle mr-3"
                      ></i>{" "}
                      {dataSet.nonConformities.businessFunctionNames[1]}{" "}
                      {dataSet.nonConformities.amount[1]}
                    </p>
                    <p className="category my-3">
                      <i
                        style={{
                          width: "8px",
                          height: "8px",
                        }}
                        class="fa-solid fa-circle mr-3"
                      ></i>{" "}
                      {dataSet.nonConformities.businessFunctionNames[2]}{" "}
                      {dataSet.nonConformities.amount[2]}
                    </p>
                    <p className="category my-3">
                      <i
                        style={{
                          width: "8px",
                          height: "8px",
                        }}
                        class="fa-solid fa-circle mr-3"
                      ></i>{" "}
                      {dataSet.nonConformities.businessFunctionNames[3]}{" "}
                      {dataSet.nonConformities.amount[3]}
                    </p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card
              style={{
                minHeight: "308px",
              }}
              variant="secondary"
              hover={false}
            >
              <CardBody>
                <Row>
                  <Col
                    xs="12"
                    className="mb-5 mt-4 d-flex justify-content-center align-items-center"
                  >
                    <ImsCircularProgressChart
                      data={dataSet.sheduledAuditVsCompletedAudit.data}
                      options={dataSet.sheduledAuditVsCompletedAudit.options}
                    />
                  </Col>
                  <Col xs="12">
                    <CardTitle tag="h4" className="text-center">
                      <i className="ims-icons-20 icon-icon-trophy-24 text-success" />{" "}
                      Completed {dataSet.audits.completed}
                    </CardTitle>
                    <p className="category text-center">
                      Scheduled {dataSet.audits.inCompleted}
                    </p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default AuditInspection;
