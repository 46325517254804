const USER_ACTIONS = {
  LOAD_CIPS: "load-cips",
  LOAD_CIP: "load-cip",
  LOAD_CIP_DRAWER: "load-cip-drawer",
  CREATE_CIP: "create-cip",
  AMEND_CIP: "amend-cip",
  DELETE_CIP: "delete-cip",
  NUDGE_OWNER: "nudge-owner",
  DELETE_ATTACHMENT: "delete-attachment",
  IMPLEMENT_CIP: "implement-cip",
  LINK_ISO_CONTROL: "link-iso-control",
  LOAD_LINKED_CONTROLS_TO_CIP: "load-linked-controls-to-cip",
};
export default USER_ACTIONS;
