import Can from "components/Can/Can";
import { DrawerContextProvider } from "ims-ui-kit";
import { ACTIONS, IMS_SERVICES } from "rolesAndPermissions";
import Dashboard from "./Dashboard";
import { DashboardContextProvider } from "./store";
const Index = () => {
  return (
    <DashboardContextProvider>
      <DrawerContextProvider>
        <Can
          policy={{
            service: IMS_SERVICES.DASHBOARD,
            action: ACTIONS.READ,
          }}
        >
          <Dashboard />
        </Can>
      </DrawerContextProvider>
    </DashboardContextProvider>
  );
};

export default Index;
