const { IMS_SERVICES } = require("rolesAndPermissions");

//updated
let summaries = [
  {
    toolKit: IMS_SERVICES.ESG_ENVIRONMENTAL,
    summary: `This toolkit provides guidance and resources for organizations 
    to implement energy-saving measures, waste reduction and recycling practices, 
    water conservation, and greenhouse gas emission reduction in line with the 
    environmental aspects of ESG (Environmental, Social, and Governance) compliance. 
    It includes a step-by-step implementation guide and templates for necessary 
    documentation to demonstrate compliance to auditors or interested parties.`,
  },
  {
    toolKit: IMS_SERVICES.ESG_SOCIAL,
    summary: `This toolkit provides guidance and resources for organizations 
    to implement diversity and inclusion practices, fair labour practices, 
    health and safety measures, community engagement initiatives, and anti-corruption 
    policies in line with the social aspects of ESG (Environmental, Social, and 
    Governance) compliance. It includes a step-by-step implementation guide and 
    templates for necessary documentation to demonstrate compliance to auditors 
    or interested parties.`,
  },
  {
    toolKit: IMS_SERVICES.ESG_GOVERNANCE,
    summary: `This toolkit provides guidance and resources for organizations to 
    implement transparency and accountability in financial reporting, establish 
    an independent and diverse board of directors, develop a code of conduct, and
    implement effective risk management processes in line with the governance aspects 
    of ESG (Environmental, Social, and Governance) compliance. It includes a 
    step-by-step implementation guide and templates for necessary documentation 
    to demonstrate compliance to auditors or interested parties.`,
  },
  {
    toolKit: IMS_SERVICES.ISO27001,
    summary: `ISO 27001:2013 is the international standard which is used for information security. It sets
    out the specification for an information security management system (ISMS). The
    ISMS standard’s best-practice approach helps organisations secure the
    confidentiality, integrity and availability (CIA) of corporate information assets. ISO
    27001 is a framework that helps organisations “establish, implement, operate,
    monitor, review, maintain and continually improve an ISMS”.`,
  },
  {
    toolKit: IMS_SERVICES.ISO27001_2022,
    summary: `ISO 27001:2022 is the international standard which is used for information security. It sets
    out the specification for an information security management system (ISMS). The
    ISMS standard’s best-practice approach helps organisations secure the
    confidentiality, integrity and availability (CIA) of corporate information assets. ISO
    27001 is a framework that helps organisations “establish, implement, operate,
    monitor, review, maintain and continually improve an ISMS”.`,
  },
  {
    toolKit: IMS_SERVICES.ISO27001_2022_ANNEX_A,
    summary: `Annex A for ISO 27001:2022 contains information security controls that are directly 
    derived from and aligned with those listed in ISO 27002:2022, clauses 5 to 8, and shall be 
    used in context with 6.1.3.`,
  },
  {
    toolKit: IMS_SERVICES.ISO27002,
    summary: `ISO 27002:2013 gives guidelines for organizational information security
    standards and information security management practices. It is designed to be used
    by organizations that intend to select controls within the process of implementing
    an Information Security Management System based on ISO/IEC 27001, implement
    commonly accepted information security controls or develop their own information
    security management guidelines. It supports and should be read alongside ISO
    27001.`,
  },
  {
    toolKit: IMS_SERVICES.ISO20000,
    summary: `ISO 20000:2018 is the international ITSM (IT service management) standard. It
        enables IT departments to ensure that their ITSM processes are aligned with the
        business’s needs and international best practices.`,
  },
  {
    toolKit: IMS_SERVICES.ISO9001,
    summary: `ISO 9001:2015 is the international standard for creating a Quality Management Systems
        (QMS). This standard provides the QMS requirements to be implemented for a
        company that wishes to create all the policies, processes, and procedures necessary
        to provide services and products that meet customer and regulatory needs, as well
        as improve customer satisfaction.`,
  },
  {
    toolKit: IMS_SERVICES.ISO45001,
    summary: `ISO 45001:2018 (previously ISO 18001) specifies requirements for an occupational health
        and safety (OH&S) management system, and gives guidance for its use, to enable
        organisations to provide safe and healthy workplaces by preventing work-related
        injury and ill health, as well as by proactively improving its OH&S performance. ISO
        45001 is applicable to any organisation that wishes to establish, implement and
        maintain an OH&S management system to improve occupational health and safety,
        eliminate hazards and minimize OH&S risks (including system deficiencies), take
        advantage of OH&S opportunities, and address OH&S management system
        nonconformities associated with its activities.`,
  },
  {
    toolKit: IMS_SERVICES.DSPTNHS,
    summary: `DSPT:2022 The Data Security and Protection Toolkit is tool that allows organisations to measure
        their performance against the National Data Guardian’s 10 data security standards.
        As data security standards evolve, the requirements of the DSPT are reviewed and
        updated to ensure they are aligned with current best practice. Organisations with
        access to NHS patient data must therefore review and submit their annual
        assessment each year before the deadline. The DSPT also provides organisations
        with a means of reporting security incidents and data breaches.`,
  },
  {
    toolKit: IMS_SERVICES.BS9997,
    summary: `BS 9997:2019 details the requirements of an organisational fire risk
        management system, the aim of which is to minimize risks to life, property, mission
        continuity and the environment from the threat of fire which arises as a result of its
        activities.`,
  },
  {
    toolKit: IMS_SERVICES.ISO14001,
    summary: `ISO 14001:2015 sets out the specification for an environmental management system that an organisation can use to enhance its environmental performance. ISO 14001 is intended for use by an organisation seeking to manage its environmental responsibilities in a systematic manner that contributes to the environmental pillar of sustainability.`,
  },
  {
    toolKit: IMS_SERVICES.ISO15686_5,
    summary: `ISO 15686-5:2017 provides requirements and guidelines for performing life-cycle cost (LCC) analyses of buildings and constructed assets and their parts, whether new or existing.`,
  },
];

export default summaries;
