import { DrawerContextProvider } from "ims-ui-kit";
import { RiskContextProvider } from "../store";
import RiskDetail from "./RiskDetail";
import { TaskContextProvider } from "views/taskManagement/store";
import { TagsAndCategoriesContextProvider } from "views/tagsAndCategoriesManager/store";

const Index = (props) => {
  return (
    <RiskContextProvider {...props}>
      <DrawerContextProvider>
        <TaskContextProvider>
          <TagsAndCategoriesContextProvider applicableModules={"risks"}>
            <RiskDetail />
          </TagsAndCategoriesContextProvider>
        </TaskContextProvider>
      </DrawerContextProvider>
    </RiskContextProvider>
  );
};

export default Index;
