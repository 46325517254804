import Loading from "components/Loader/Loading";
import { Col, PanelTab, PanelTabs, PanelWindow, Row, Table } from "ims-ui-kit";
import React from "react";
import { Attachments } from "views/shared/Attachments/Index";
import DetailsDrawerHeader from "views/shared/DetailComponents/DetailsDrawerHeader";
import DetailsWrapper from "views/shared/DetailComponents/DetailsWrapper";
import { DetailsSectionHeader } from "views/shared/DetailsSectionHeader";
import ErrorHandlerComponent from "views/shared/ErrorHandlerComponent";
import CampaignOverview from "./CampaignOverview";
import Recipients from "./Recipients";
import USER_ACTIONS from "./actions";
import { useCampaign } from "./store";

const CampaignDrawerDetail = () => {
  let {
    processing,
    visitingCampaign: campaign,
    lists,
    toolState,
    loadRecipients,
  } = useCampaign();
  return (
    <React.Fragment>
      <ErrorHandlerComponent
        hasError={processing[USER_ACTIONS.LOAD_EMAIL].error}
        errorMessage="This campaign has been deleted or removed"
      >
        {processing[USER_ACTIONS.LOAD_EMAIL].status ? (
          <Loading />
        ) : (
          campaign && (
            <React.Fragment>
              <DetailsDrawerHeader data={campaign} />
              <PanelTabs variant="outline" activeTab={0}>
                <PanelTab>
                  <i className="ims-icons-20 icon-icon-notebook-24 mr-1"></i>
                  Overview
                </PanelTab>
                <PanelTab>
                  <i className="ims-icons-20 icon-icon-list-24 mr-1"></i>
                  Details
                </PanelTab>
                <PanelTab>
                  <i
                    className="fa-solid fa-comment mr-2
                    "
                  ></i>
                  Recipients
                </PanelTab>

                <PanelWindow tabId={0}>
                  <CampaignOverview />
                </PanelWindow>
                <PanelWindow tabId={1}>
                  {campaign.customAudience.lenght > 0 ? (
                    <div>
                      <DetailsSectionHeader title={`Additional audience`} />
                      <Row>
                        <Col md="12">
                          <Table>
                            <thead className="text-primary">
                              <tr>
                                <th>Reference</th>
                                <th className="text-right">Customer name</th>
                              </tr>
                            </thead>
                            <tbody>
                              {campaign.customAudience.map((customer) => (
                                <tr>
                                  <th>{customer.reference}</th>
                                  <th className="text-secondary text-right">
                                    {customer.name}
                                  </th>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Col>
                        <Col md="12"></Col>
                      </Row>
                    </div>
                  ) : null}
                  <Row>
                    <Col md="12">
                      <DetailsWrapper
                        label={"Subject:"}
                        value={campaign.subject}
                      />
                    </Col>
                    <Col md="12">
                      <DetailsWrapper
                        label={"Message:"}
                        value={campaign.body}
                      />
                    </Col>
                  </Row>
                  <br></br>
                  <DetailsSectionHeader title={`Attachments`} />
                  <Row>
                    <Col md="12">
                      <Attachments s3Information={campaign.attachments} />
                    </Col>
                  </Row>
                </PanelWindow>
                <PanelWindow tabId={2}>
                  {processing[USER_ACTIONS.LOAD_RECIPIENTS].status && (
                    <Loading />
                  )}
                  <Recipients
                    lists={lists}
                    processing={processing}
                    toolState={toolState}
                    loadRecipients={loadRecipients}
                  />
                </PanelWindow>
              </PanelTabs>
            </React.Fragment>
          )
        )}
      </ErrorHandlerComponent>
    </React.Fragment>
  );
};

export default CampaignDrawerDetail;
