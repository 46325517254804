import ReactTable from "components/ReactTable/ReactTable";
import useAccess from "hooks/useAccess";
import useAlert from "hooks/useAlerts";
import { getCurrentUserInfo } from "services/userServices";
import useRepositories from "./store/useStore";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { ACTIONS, EFFECTS, IMS_SERVICES } from "rolesAndPermissions";
import USER_ACTIONS from "../repositories/store/actions";
import { DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from "ims-ui-kit";
import React from "react";
const defaultdata = [["No data found"]];

const DeletedRepositoriesTable = () => {
  let {
    deletedRepositories,
    processing,
    dispatch,
    handleRestore,
    handleHardDelete,
    fetchDeletedRepositories,
    deletedToolState,
    ...rest
  } = useRepositories();
  let { authUser, authGlobalAccess,authAdminAccess,entityAccessControl } = useAccess();

  let { alert,warningWithConfirmMessage } = useAlert();
  let history = useHistory();

  const data = React.useMemo(
    () =>
    deletedRepositories.map((data, key) => {
        return {
          id: data._id,
          ID: data.reference,
          business_function: data.group ? data.group.name : "N/A",
          document_name: data.name || "Untitled repository",
          owner: data.owner && data.owner.name,
          interval: "Yearly",
          nextReview: moment(
            new Date(data.createdAt).getTime() + 86400 * 365 * 1000
          ).format("DD/MM/YYYY"),
          actions: (
            <UncontrolledDropdown size="sm" direction="right">
              <DropdownToggle
                data-display="static"
                className="border border rounded-circle"
                outline
                onClick={(e) => e.stopPropagation()}
              >
                <i className="fa-solid fa-ellipsis-h" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() =>
                    history.push(`/admin/document-repositories/${data._id}`)
                  }
                  id="detail"
                  tooltip="View Details"
                >
                  Details
                </DropdownItem>
                {authUser({
                  service: IMS_SERVICES.DOCUMENT_MANAGEMENT,
                  action: ACTIONS.DELETE,
                  effect: EFFECTS.ALLOW,
                }) &&
                  (authAdminAccess() ||
                    entityAccessControl({
                      users: [data.created?.by?._id, data.owner?._id],
                      effect: "Allow",
                    })) ? (
                  <>
                    <DropdownItem
                      tooltip="Restore"
                      onClick={(e) => {
                        e.stopPropagation();
                        warningWithConfirmMessage(
                          `Repository ${data.reference} will be restored`,
                          () => {
                            handleRestore(data._id);
                          }
                        );
                      }}
                      name="restore"
                      id="restore"
                    >
                      {processing[USER_ACTIONS.RESTORE_REPOSITORY].status &&
                        processing[USER_ACTIONS.RESTORE_REPOSITORY].id ===
                        data._id ? (
                        <Spinner size="sm" />
                      ) : (
                        "Restore"
                      )}
                    </DropdownItem>
                    <DropdownItem
                      tooltip="Delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        warningWithConfirmMessage(
                          `Repository ${data.reference} will be deleted permanently`,
                          () => {
                            handleHardDelete(data._id);
                          }
                        );
                      }}
                      name="delete"
                      id="delete"
                    >
                      {processing[USER_ACTIONS.HARD_DELETE_REPOSITORY].status &&
                        processing[USER_ACTIONS.HARD_DELETE_REPOSITORY].id ===
                        data._id ? (
                        <Spinner size="sm" />
                      ) : (
                        "Delete permanently"
                      )}
                    </DropdownItem>
                  </>
                ) : null}
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        };
      }),
    [processing, deletedRepositories]
  );

  //This table contains the next date of review
  return (
    <>
      {alert}
      <div className="content">
        <ReactTable
          data={data}
          {...rest}
          resizable={false}
          columns={[
            {
              Header: "Reference",
              accessor: "ID",
            },
            {
              Header: authGlobalAccess() ? "Business unit" : "Business unit",
              accessor: "business_function",
            },
            {
              Header: "Repository name",
              accessor: "document_name",
            },
            {
              Header: "Owner",
              accessor: "owner",
            },
            {
              Header: "Review interval",
              accessor: "interval",
            },
            {
              Header: "Next review date",
              accessor: "nextReview",
            },
            {
              Header: "Actions",
              accessor: "actions",
              sortable: false,
              filterable: false,
            },
          ]}
          title={"Recycle bin"}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
          isFilterable
          isSearchable
          pagination={deletedToolState.pagination}
          onPageChange={fetchDeletedRepositories}
        />
      </div>
    </>
  );
};

export default DeletedRepositoriesTable;
