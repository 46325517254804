import useAccess from "hooks/useAccess";
import useProcessingControl from "hooks/useProcessingControl";
import React, { useState } from "react";
import { ACTIONS, EFFECTS, IMS_SERVICES } from "rolesAndPermissions";
import { getCurrentSessionData } from "services/authService";
import * as cqcApi from "services/cqcServices";
import * as dashboardApi from "services/dashBoardServices";
import { imsLogger } from "services/loggerService";
import { getCurrentUserInfo } from "services/userServices";
import USER_ACTIONS from "../actions";
import { useApplication } from "stores/applicationStore";

export default function useDashboardStore(config) {
  const [currentTime, setCurrentTime] = React.useState(new Date());
  const { tokenPair } = useApplication();
  React.useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return function cleanup() {
      clearInterval(timerID);
    };
  });
  function canLoadOrgDashboardForUser() {
    /**
     * we are allowing organisational dashboard for a user if he has a role in the system, without
     * being associated with a business unit.
     *
     */
    return (
      authGlobalAccess() ||
      (!tokenPair?.accessTokenData?.user?.groupId &&
        tokenPair?.accessTokenData?.user?.organizationId &&
        tokenPair?.accessTokenData?.user?.role)
    );
  }
  function tick() {
    setCurrentTime(new Date());
  }
  function getRatingClasses(rating) {
    switch (rating) {
      case "Not rated":
        return "";
      case "Inadequate":
        return "text-danger";
      case "Requires improvement":
        return "text-warning";
      case "Good":
        return "text-success";
      case "Outstanding":
        return "text-gold";
      default:
        return "";
    }
  }
  function getComplianceColors(compliancePercentage, type) {
    switch (true) {
      case compliancePercentage < 20:
        return type === "text-component" ? "text-danger text-right" : "red";
      case compliancePercentage < 40:
        return type === "text-component"
          ? "text-primary text-right"
          : "primary";
      case compliancePercentage < 60:
        return type === "text-component"
          ? "text-warning text-right"
          : "warning";
      case compliancePercentage < 80:
        return type === "text-component" ? "text-info text-right" : "info";
      case compliancePercentage <= 100:
        return type === "text-component"
          ? "text-success text-right"
          : "success";
      default:
        return " text-right";
    }
  }
  let { authGlobalAccess } = useAccess(getCurrentUserInfo());
  const { processing, dispatch: _dispatch } = useProcessingControl(
    Object.keys(USER_ACTIONS).map((action) => {
      return { action: USER_ACTIONS[action] };
    })
  );
  //organizational dashboard
  let [dataSetSA, setDataSetSA] = React.useState(null);
  //business function dashboard
  let [unmappedData, setUnMappedData] = React.useState(null);
  let [dataSetHOS, setDataSetHOS] = React.useState(null);
  let [cqcDataSet, setCqcDataSet] = React.useState(null);
  let [CQCoverview, setCQCOverview] = useState(null);
  const [bigChartData, setbigChartData] = React.useState("data5");

  const changeRiskDataTab = (tab) => {
    setbigChartData(tab);
  };
  async function fetchSAdashboardData() {
    try {
      _dispatch({
        [USER_ACTIONS.LOAD_SA_DASHBOARD]: {
          status: true,
          error: null,
          id: null,
        },
      });
      let { data } = await dashboardApi.loadDashboardData();
      setUnMappedData(data.dashboard);
      let mapedData = dashboardApi.mapToDashBoardSAModel(data.dashboard);
      setDataSetSA(mapedData);
      _dispatch({
        [USER_ACTIONS.LOAD_SA_DASHBOARD]: {
          status: false,
          error: null,
          id: null,
        },
      });
    } catch (ex) {
      imsLogger("OrganizationalDashboard", ex, ex.response);
      _dispatch({
        [USER_ACTIONS.LOAD_SA_DASHBOARD]: {
          status: false,
          error: ex,
          id: null,
        },
      });
    }
  }
  async function fetchCQCOverview() {
    try {
      let { data } = await cqcApi.getRatingOverview("undefined", {
        query: `group=${getCurrentSessionData().user.current.group._id}`,
      });
      let mapedOverviewData = cqcApi.mapToToolOverview(data.overview);
      setCQCOverview(data.overview);
      setCqcDataSet(mapedOverviewData);
    } catch (err) {
      imsLogger("BusinessFunctionDashboard", err);
    }
  }
  async function fetchHOSDashboard() {
    try {
      // setLoading(true);
      _dispatch({
        [USER_ACTIONS.LOAD_HOS_DASHBOARD]: {
          status: true,
          error: null,
          id: null,
        },
      });
      let { data } = await dashboardApi.loadBusinessDashboardData(
        tokenPair?.accessTokenData?.user?.groupId
      );
      let mapedData = dashboardApi.mapToBusinessDashBoardHOSModel(
        data.dashboard
      );
      setDataSetHOS(mapedData);
      // setLoading(false);
      _dispatch({
        [USER_ACTIONS.LOAD_HOS_DASHBOARD]: {
          status: false,
          error: null,
          id: null,
        },
      });
    } catch (ex) {
      imsLogger("BusinessFunctionDashboard", ex, ex.response);
      _dispatch({
        [USER_ACTIONS.LOAD_HOS_DASHBOARD]: {
          status: false,
          error: ex,
          id: null,
        },
      });
    }
  }
  React.useEffect(() => {
    if (canLoadOrgDashboardForUser()) {
      fetchSAdashboardData();
    } else {
      fetchHOSDashboard();
      // fetchCQCOverview();
    }
  }, []);

  return {
    dataSetSA,
    dataSetHOS,
    cqcDataSet,
    CQCoverview,
    getRatingClasses,
    getComplianceColors,
    bigChartData,
    processing,
    authGlobalAccess,
    fetchSAdashboardData,
    fetchHOSDashboard,
    fetchCQCOverview,
    currentTime,
    unmappedData,
    changeRiskDataTab,
    canLoadOrgDashboardForUser,
  };
}
