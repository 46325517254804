import { DrawerContextProvider } from "ims-ui-kit";
import TaskTable from "./TasksTable";
import { TaskContextProvider } from "./store";

const TaskManagement = ({ moduleType = "tasks", module = null, ...props }) => {
  return (
    <DrawerContextProvider>
      <TaskContextProvider moduleType={moduleType} module={module} {...props}>
        <TaskTable {...props} />
      </TaskContextProvider>
    </DrawerContextProvider>
  );
};

export default TaskManagement;
