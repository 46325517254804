import Loading from "components/Loader/Loading";
import { Col, PanelTab, PanelTabs, PanelWindow, Row } from "ims-ui-kit";
import React from "react";
import DetailsDrawerHeader from "views/shared/DetailComponents/DetailsDrawerHeader";
import DetailsSectionContent from "views/shared/DetailsSectionContent";
import ErrorHandlerComponent from "views/shared/ErrorHandlerComponent";
import USER_ACTIONS from "./actions";
import { useOrganizationAssets } from "./store";
import OrganisationAssetOverview from "./OrganisationAssetOverview";
import DetailsWrapper from "views/shared/DetailComponents/DetailsWrapper";

const OrganizationAssetDrawerDetail = (props) => {
  let { processing, organization } = useOrganizationAssets();
  return (
    <React.Fragment>
      <ErrorHandlerComponent
        hasError={processing[USER_ACTIONS.LOAD_ORGANIZATION]?.error}
        errorMessage="This hardware has been deleted or removed"
      >
        {processing[USER_ACTIONS.LOAD_ORGANIZATION]?.status ? (
          <Loading />
        ) : (
          organization && (
            <React.Fragment>
              <DetailsDrawerHeader data={organization} />
              <React.Fragment>
                <PanelTabs variant="outline" activeTab={0}>
                <PanelTab>
                    <i className="ims-icons-20 icon-icon-notebook-24 mr-1"></i>
                    Overview
                  </PanelTab>
                <PanelTab>
                    <i className="ims-icons-20 icon-icon-list-24 mr-1"></i>
                    Details
                  </PanelTab>
                  <PanelWindow tabId={0}>
                    <OrganisationAssetOverview data={organization} />
                  </PanelWindow>
                  <PanelWindow tabId={1}>
                    <DetailsSectionContent
                      label={"Information inventory:"}
                      value={organization.informationInventory}
                    />
                    <DetailsSectionContent
                      label={"Format:"}
                      value={organization.format}
                    />
                    <DetailsSectionContent
                      label={"Storage location:"}
                      value={organization.storageLocation}
                    />
                    <DetailsSectionContent
                      label={"Link:"}
                      value={organization.link}
                    />
                    <Row>
                      {organization?.tagsAndCategories && (
                        <Col md="12" className="mx-2">
                          <DetailsWrapper
                            label={"Additional Information:"}
                            iconClass={"tim-icons icon-pencil"}
                            value={`Category: ${organization?.tagsAndCategories?.name}`}
                            labelClass={"pr-2"}
                          />
                        </Col>
                      )}
                    </Row>
                  </PanelWindow>
                </PanelTabs>
              </React.Fragment>
            </React.Fragment>
          )
        )}
      </ErrorHandlerComponent>
    </React.Fragment>
  );
};

export default OrganizationAssetDrawerDetail;
