const USER_ACTIONS = {
  LOAD_TASKS: "load-tasks",
  LOAD_TODOLISTS:"load-todolist",
  LOAD_TASK: "load-task",
  CREATE_TASK: "create-task",
  UPDATE_TASK: "update-task",
  DELETE_TASK: "delete-task",
  NUDGE_OWNER: "nudge-owner",
  DELETE_ATTACHMENT: "delete-attachment",
  COMPLETE_TASK: "complete-task",
  REQUESTED_TASK: "requested-task",
};
export default USER_ACTIONS;
