import ReactTable from "components/ReactTable/ReactTable";
import NotificationContext from "contexts/notificationContext";
import useAccess from "hooks/useAccess";
import useAlerts from "hooks/useAlerts";
import useModal from "hooks/useModal";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
} from "ims-ui-kit";
import React from "react";
import { ACTIONS, EFFECTS, IMS_SERVICES } from "rolesAndPermissions";
import { deletePremise } from "services/iamGroupPremisesServices";
import { imsLogger } from "services/loggerService";
import PremisesDetail from "./PremisesDetail";

const defaultdata = [["No data found"]];
const PremisesTable = ({
  dataTable,
  pathname,
  setPremises,
  processing,
  setProcessing,
  ...rest
}) => {
  let notify = React.useContext(NotificationContext);
  let { alert, warningWithConfirmMessage, successAlert } = useAlerts();
  let updateDataTable = (updatedPremise) => {
    setProcessing({ action: "update", id: updatedPremise._id });
    setPremises((prevPremises) =>
      prevPremises.map((premise) =>
        premise._id === updatedPremise._id ? updatedPremise : premise
      )
    );
    setProcessing({ action: null, id: null });
  };
  let { activateView, Modal, isOpen } = useModal({ onUpdate: updateDataTable });

  let handleDelete = async (data) => {
    setProcessing({ action: "delete", id: data._id });
    try {
      await deletePremise(data._id);
      setPremises((prevPremises) =>
        prevPremises.filter((premise) => premise._id !== data._id)
      );
      notify("Premise Delete successfully", "success");
      successAlert("Deleted successfully");
    } catch (ex) {
      imsLogger("PremisesTable", ex);
      notify("Could not delete", "danger");
    }
    setProcessing({ action: null, id: null });
  };
  let { authUser, authSuperUser, entityAccessControl } = useAccess();
  dataTable = dataTable ? dataTable : defaultdata;
  const data = React.useMemo(
    () =>
      dataTable.map((data, key) => {
        return {
          id: data._id,
          premise_name: data.name,
          premise_location: data.location,
          premise_address: data.address,
          activateView: activateView,
          data: data,
          actions: (
            <>
              <UncontrolledDropdown size="sm" direction="right">
                <DropdownToggle
                  outline
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  data-display="static"
                  className="border border rounded-circle"
                >
                  <i className="fa-solid fa-ellipsis-h" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                     
                    onClick={(e) => {
                      activateView(data);
                      e.stopPropagation();
                    }}
                    id="detail"
                    tooltip="View Details"
                  >
                    Details
                  </DropdownItem>
                  {authUser({
                    service: IMS_SERVICES.IAM_PREMISES,
                    action: ACTIONS.DELETE,
                    effect: EFFECTS.ALLOW,
                  }) &&
                    (authSuperUser() ||
                      entityAccessControl({
                        users: [data?.created?.by?._id],
                        effect: "Allow",
                      })) && (
                      <DropdownItem
                        
                        tooltip="Delete"
                        onClick={(e) => {
                          warningWithConfirmMessage(
                            "This premise will be deleted",
                            () => {
                              handleDelete(data);
                            }
                          );
                          e.stopPropagation();
                        }}
                        id="delete"
                      >
                        {processing.action === "delete" &&
                        processing.id === data?._id ? (
                          <Spinner size="sm" />
                        ) : (
                          "Delete"
                        )}
                      </DropdownItem>
                    )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          ),
        };
      }),
    [processing]
  );
  return (
    <>
      {alert}
      <div className="content">
        <ReactTable
          data={data}
          {...rest}
          resizable={false}
          columns={[
            {
              Header: "Premise Name",
              accessor: "premise_name",
            },
            {
              Header: "Premise Location",
              accessor: "premise_location",
            },
            {
              Header: "Premise Address",
              accessor: "premise_address",
            },
            {
              Header: "Actions",
              accessor: "actions",
              sortable: false,
              filterable: false,
            },
          ]}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
          // isFilterable
          isSearchable
          title={"Business premises"}
          rowProps={function (row) {
            return {
              onClick: function (e) {
                activateView(row.original.data);
              },
            };
          }}
        />
        <Modal title="Premise">
          <PremisesDetail isModalOpen={isOpen} />
        </Modal>
      </div>
    </>
  );
};

export default PremisesTable;
