import { useContext, useEffect, useState } from "react";
import { imsLogger } from "services/loggerService";
import * as documentManagmentApi from "services/documentManagement/index";
import USER_ACTIONS from "./actions";
import useProcessingControl from "hooks/useProcessingControl";
import NotificationContext from "contexts/notificationContext";
import useForm from "hooks/useForm";
import IVal from "validations/validator";
export default function useRequestSignatureStore(config) {
  const notify = useContext(NotificationContext);
  const defaultSignatureConfig = {
    pageNumber: 1,
    pageX: 0.1,
    pageY: 0.1,
  };
  const { processing, dispatch: _dispatch } = useProcessingControl(
    Object.keys(USER_ACTIONS).map((action) => {
      return { action: USER_ACTIONS[action] };
    })
  );
  const dataSet = {
    data: {
      message: "",
    },
    errors: {},
  };
  const schema = {
    message: IVal.string().max(200).allow("", null).label("Message"),
  };
  const messageFormControllers = useForm(dataSet, schema);
  const [selectedInternalUserIds, setSelectedInternalUserIds] = useState([]);
  const [selectedExternalEmails, setSelectedExternalEmails] = useState([]);
  const [signaturePageNumber, setSignaturePageNumber] = useState(
    defaultSignatureConfig.pageNumber
  );
  const [signatureCords, setSignatureCords] = useState({
    pageX: defaultSignatureConfig.pageX,
    pageY: defaultSignatureConfig.pageY,
  });
  function addToSelectedInternalUserIdsList(newuserid) {
    setSelectedInternalUserIds((prevuserids) => [...prevuserids, newuserid]);
  }
  function addToSelectedExternalEmailsList(newemail) {
    if (!selectedExternalEmails.includes(newemail))
      setSelectedExternalEmails((prevemails) => [...prevemails, newemail]);
  }
  function removeFromSelectedInternalUserIdsList(userid) {
    setSelectedInternalUserIds((prevuserids) =>
      prevuserids.filter((id) => id !== userid)
    );
  }
  function removeFromSelectedExternalEmailsList(email) {
    setSelectedExternalEmails((prevemails) =>
      prevemails.filter((e) => e !== email)
    );
  }
  function changeSignatureCords(pageX, pageY) {
    setSignatureCords({ pageX, pageY });
  }
  function toggleInternalUserIdInTheList(id) {
    selectedInternalUserIds.includes(id)
      ? removeFromSelectedInternalUserIdsList(id)
      : addToSelectedInternalUserIdsList(id);
  }
  function toggleAllInternalUsersInTheList(list) {
    selectedInternalUserIds.length === list.length
      ? setSelectedInternalUserIds([])
      : setSelectedInternalUserIds(list);
  }
  function hasSignaureConfigModifed() {
    const current = {
      pageNumber: signaturePageNumber,
      pageX: signatureCords.pageX,
      pageY: signatureCords.pageY,
    };
    return JSON.stringify(defaultSignatureConfig) !== JSON.stringify(current);
  }
  function areAllConditionsComplete() {
    return (
      (selectedInternalUserIds.length || selectedExternalEmails.length) &&
      hasSignaureConfigModifed() &&
      !messageFormControllers.validate()
    );
  }
  function modifySignatureConfig(data) {
    setSignaturePageNumber(data?.pageNumber);
    setSignatureCords({
      pageX: data?.signaturePosition?.topLeftInPercentage?.x,
      pageY: data?.signaturePosition?.topLeftInPercentage?.y,
    });
  }
  return {
    processing,
    selectedInternalUserIds,
    selectedExternalEmails,
    signaturePageNumber,
    signatureCords,
    message: messageFormControllers.dataModel.data.message,
    messageFormControllers,
    changeSignatureCords,
    addToSelectedInternalUserIdsList,
    toggleAllInternalUsersInTheList,
    addToSelectedExternalEmailsList,
    removeFromSelectedExternalEmailsList,
    removeFromSelectedInternalUserIdsList,
    toggleInternalUserIdInTheList,
    hasSignaureConfigModifed,
    areAllConditionsComplete,
    modifySignatureConfig,
  };
}
