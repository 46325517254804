import React from "react";
import BusinessFunctionDashBoard from "./businessFunctionDashboard/BusinessFunctionDashBoard";
import OrganizationalDashboard from "./orgnaisationDashboard/OrganizationalDashboard";
import { useDashboard } from "./store";

const Dashboard = () => {
  let { canLoadOrgDashboardForUser } = useDashboard();
  return (
    <React.Fragment>
      {canLoadOrgDashboardForUser() ? (
        <OrganizationalDashboard />
      ) : (
        <BusinessFunctionDashBoard />
      )}
    </React.Fragment>
  );
};

export default Dashboard;
