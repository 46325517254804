import { DrawerContextProvider } from "ims-ui-kit";
import UsersTable from "./UsersTable";
import { UserManagerContextProvider } from "./store";
import InvitationsTable from "./InvitationsTable";
import Can from "components/Can/Can";
import { IMS_SERVICES, ACTIONS } from "rolesAndPermissions";

const Users = (props) => {
  //  TODO: This UserManagerContextProvider will have to be lifted one level up
  //  once the all feature in this are braught in to userManagerStore
  return (
    <DrawerContextProvider>
      <UserManagerContextProvider {...props}>
        <div className="content">
          <UsersTable {...props} />
          <Can
            policy={{
              service: IMS_SERVICES.INVITATIONS,
              action: ACTIONS.MANAGE,
            }}
          >
            <InvitationsTable />
          </Can>
        </div>
      </UserManagerContextProvider>
    </DrawerContextProvider>
  );
};

export default Users;
