// Implementation can be done in a better way
let statuses = [
  {
    status: "Accepted",
    fade: "primary",
    icon: "ims-icons-20 icon-icon-crown-24",
  },
  {
    status: "Open",
    fade: "danger",
    icon: "ims-icons-20 icon-icon-folderopen-24",
  },
  {
    status: "Closed",
    fade: "success",
    icon: "ims-icons-20 icon-icon-prohibit-24",
  },
  {
    status: "Pending",
    fade: "warning",
    icon: "ims-icons-20 icon-icon-clipboard-24",
  },
  {
    status: "Rejected",
    fade: "danger",
    icon: "ims-icons-20 icon-icon-prohibit-24",
  },
  {
    status: "Reported",
    fade: "danger",
    icon: "ims-icons-20 icon-icon-prohibit-24",
  },
  {
    status: "On going",
    fade: "warning",
    icon: "ims-icons-20 icon-warning-regular",
  },
  {
    status: "Escalated",
    fade: "pending",
    icon: "ims-icons-20 icon-icon-clipboard-24",
  },
  {
    status: "Mitigated",
    fade: "success",
    icon: "ims-icons-20 icon-icon-gear-24",
  },
  {
    status: "Scheduled",
    fade: "primary",
    icon: "ims-icons-20 icon-icon-clipboard-24",
  },
  {
    status: "Completed",
    fade: "success",
    icon: "ims-icons-20 icon-icon-crown-24",
  },
  {
    status: "Complete",
    fade: "success",
    icon: "ims-icons-20 icon-icon-crown-24",
  },
  {
    status: "Resolved",
    fade: "success",
    icon: "ims-icons-20 icon-icon-crown-24",
  },
  {
    status: "In Progress",
    fade: "primary",
    icon: "ims-icons-20 icon-icon-flag-24",
  },
  {
    status: "In progress",
    fade: "primary",
    icon: "ims-icons-20 icon-icon-flag-24",
  },
  {
    status: "Draft",
    fade: "warning",
    icon: "ims-icons-20 icon-icon-clipboard-24",
  },
  {
    status: "Sent",
    fade: "success",
    icon: "ims-icons-20 icon-icon-crown-24",
  },
  {
    status: "Queued",
    fade: "primary",
    icon: "ims-icons-20 icon-icon-flag-24",
  },
  {
    status: "Ongoing",
    fade: "primary",
    icon: "ims-icons-20 icon-icon-flag-24",
  },
  {
    status: "Approved",
    fade: "success",
    icon: "ims-icons-20 icon-icon-crown-24",
  },
  {
    status: "Active",
    fade: "success",
    icon: "ims-icons-20 icon-icon-crown-24",
  },
  {
    status: "Blocked",
    fade: "danger",
    icon: "ims-icons-20 icon-icon-prohibit-24",
  },
  {
    status: "Granted",
    fade: "success",
    icon: "ims-icons-20 icon-icon-crown-24",
  },
  {
    status: "Declined",
    fade: "danger",
    icon: "ims-icons-20 icon-icon-prohibit-24",
  },
  {
    status: "Implemented",
    fade: "success",
    icon: "ims-icons-20 icon-icon-crown-24",
  },
  {
    status: "Not implemented",
    fade: "danger",
    icon: "ims-icons-20 icon-icon-prohibit-24",
  },
  {
    status: "Not Implemented",
    fade: "danger",
    icon: "ims-icons-20 icon-icon-prohibit-24",
  },
  {
    status: "Live",
    fade: "success",
    icon: "ims-icons-20 icon-icon-crown-24",
  },
  {
    status: "Prospect",
    fade: "warning",
    icon: "ims-icons-20 icon-icon-clipboard-24",
  },
  {
    status: "Qualified",
    fade: "primary",
    icon: "ims-icons-20 icon-icon-prohibit-24",
  },
  {
    status: "Warm lead",
    fade: "warning",
    icon: "ims-icons-20 icon-icon-prohibit-24",
  },
  {
    status: "Proposal",
    fade: "danger",
    icon: "ims-icons-20 icon-icon-prohibit-24",
  },
];

export default statuses;
