const USER_ACTIONS = {
  LOAD_LEAVES: "load-leaves",
  LOAD_LEAVE: "load-leave",
  CREATE_LEAVE: "create-leave",
  SAVE_AND_SEND_LEAVE: "save-and-send-leave",
  AMEND_LEAVE: "amend-leave",
  DELETE_LEAVE: "delete-leave",
  REMOVE_ATTACHMENT: "remove-attachment",
  SUBMIT_LEAVE: "submit-leave",
  APPROVE_LEAVE: "approve-leave",
  REJECT_LEAVE: "reject-leave",
  HANDLE_LEAVE_REQUEST: "handle-leave-request",
  LOAD_HOLIDAYS: "load-holidays",
  LOAD_LEAVE_DRAWER: "load-leave-drawer",
};
export default USER_ACTIONS;
