import React from "react";
import { useIncident } from "./store";
import ErrorHandlerComponent from "views/shared/ErrorHandlerComponent";
import USER_ACTIONS from "./actions";
import DetailsDrawerHeader from "views/shared/DetailComponents/DetailsDrawerHeader";
import IncidentOverview from "./IncidentOverview";
import {
  Col,
  PanelTab,
  PanelTabs,
  PanelWindow,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "ims-ui-kit";
import DetailsWrapper from "views/shared/DetailComponents/DetailsWrapper";
import AttachmentButtons from "./AttachmentButtons";
import { Attachments } from "views/shared/Attachments/Index";
import { DetailsSectionHeader } from "views/shared/DetailsSectionHeader";
import AuditShortDetail from "views/audits/AuditShortDetail";
import Timeline from "views/shared/Timeline/Timeline";
import IncidentStatus from "./IncidentStatus";
import Loading from "components/Loader/Loading";
import ComplianceStripe from "views/compliance/searchableList/components/ComplianceStripe";
import TaskManagement from "views/taskManagement/TaskManagement";
import { IMS_SERVICES } from "rolesAndPermissions";
import { ACTIONS } from "rolesAndPermissions";
import { EFFECTS } from "rolesAndPermissions";
import useAccess from "hooks/useAccess";

const IncidentDrawerDetail = (props) => {
  let { authUser } = useAccess();
  let {
    processing,
    visitingIncident: incident,
    isResolvedIncident,
    controlsOnVisitingIncident,
    removeISOControl,
    warningWithConfirmMessage,
  } = useIncident();
  const authTaskTab = () =>
    authUser({
      service: IMS_SERVICES.INCIDENT_MANAGEMENT,
      action: ACTIONS.READ,
      effect: EFFECTS.ALLOW,
    })
      ? // authUser({
        //   service: IMS_SERVICES.TASK_MANAGER,
        //   action: ACTIONS.READ,
        //   effect: EFFECTS.ALLOW,
        // })
        [
          <PanelTab>
            <i className="ims-icons-20 icon-icon-notepad-24 mr-1"></i>
            Tasks
          </PanelTab>,
        ]
      : [];

  const navLinks = [
    <PanelTab>
      <i className="ims-icons-20 icon-icon-notebook-24 mr-1"></i>
      Overview
    </PanelTab>,
    <PanelTab>
      <i className="ims-icons-20 icon-icon-list-24 mr-1"></i>
      Details
    </PanelTab>,
    <PanelTab>
      <i
        className="ims-icons-20 icon-icon-activity-24 mr-1
                        "
      ></i>
      Activity
    </PanelTab>,
    <PanelTab>
      <i className="ims-icons-20 icon-icon-clock-24 mr-1"></i>
      Life Cycle
    </PanelTab>,
    ...authTaskTab(),
    <PanelTab>
      <i className="ims-icons-20 icon-icon-shieldcheck-24 mr-1"></i>
      Linked controls
    </PanelTab>,
  ];
  return (
    <React.Fragment>
      {processing[USER_ACTIONS.LOAD_INCIDENT].status ? (
        <Loading />
      ) : (
        incident && (
          <React.Fragment>
            <DetailsDrawerHeader data={incident} />
            <PanelTabs variant="outline" activeTab={0}>
              {navLinks.map((link) => link)}
              <PanelWindow tabId={0}>
                {/* <RiskActions risk={risk} /> */}
                <IncidentOverview />
              </PanelWindow>
              <PanelWindow tabId={1}>
                {incident && (
                  <React.Fragment>
                    <Row>
                      <Col md="12">
                        <DetailsWrapper
                          label={"Description:"}
                          iconClass={"tim-icons icon-pencil"}
                          value={incident?.description}
                          labelClass={"pr-2"}
                        />
                      </Col>
                      <Col md="12">
                        <DetailsWrapper
                          label={"Affected service:"}
                          iconClass={"tim-icons icon-pencil"}
                          value={incident?.affectedService}
                          labelClass={"pr-2"}
                        />
                      </Col>
                      <Col md="12">
                        <DetailsWrapper
                          label={"Method of notification:"}
                          value={incident?.methodOfNotification}
                        />
                      </Col>
                      {incident?.resolved?.status &&
                      incident?.resolution !== "" ? (
                        <Col md="12">
                          <DetailsWrapper
                            label={"Resolution:"}
                            iconClass={"tim-icons icon-pencil"}
                            value={incident?.resolution}
                            labelClass={"pr-2"}
                          />
                        </Col>
                      ) : null}
                      {incident?.tagsAndCategories && (
                        <Col md="12" className="">
                          <DetailsWrapper
                            label={"Additional Information:"}
                            iconClass={"tim-icons icon-pencil"}
                            value={`Category: ${incident?.tagsAndCategories?.name}`}
                            labelClass={"pr-2"}
                          />
                        </Col>
                      )}

                      {incident?.attachments.length > 0 && (
                        <Col md="12">
                          <DetailsWrapper
                            label={"Attachments"}
                            iconClass={"tim-icons icon-pencil"}
                            value={null}
                            labelClass={"pr-2"}
                          />

                          <Attachments s3Information={incident?.attachments}>
                            <AttachmentButtons />
                          </Attachments>
                        </Col>
                      )}
                    </Row>
                    {incident?.source?.moduleType === "audits" && (
                      <>
                        <DetailsSectionHeader title={`Audit information`} />
                        <DetailsWrapper label={`Audit detail`} />
                        {incident.source.moduleType === "audits" && (
                          <Row>
                            <Col md="12">
                              <AuditShortDetail
                                audit={incident?.source?.module}
                              />
                            </Col>
                          </Row>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </PanelWindow>
              <PanelWindow tabId={2}>
                {isResolvedIncident() ? (
                  <Timeline
                    readOnly={true}
                    horizontalSpacing={false}
                    containerClass="mx-auto sm-10"
                    moduleType="incidents"
                    moduleId={incident?._id}
                    module={incident}
                  />
                ) : (
                  <Timeline
                    editLabel="comment"
                    editPlaceholder="Comment"
                    horizontalSpacing={true}
                    containerClass="mx-auto sm-10"
                    moduleType="incidents"
                    moduleId={incident?._id}
                    module={incident}
                  />
                )}
              </PanelWindow>
              <PanelWindow tabId={3}>
                <IncidentStatus />
              </PanelWindow>
              <PanelWindow tabId={4}>
                <TaskManagement moduleType="incidents" module={incident._id} />
              </PanelWindow>
              <PanelWindow tabId={5}>
                {processing[USER_ACTIONS.LOAD_LINKED_CONTROLS_TO_INCIDENT]
                  .status && <Loading />}
                {controlsOnVisitingIncident?.length > 0 &&
                !processing[USER_ACTIONS.LOAD_LINKED_CONTROLS_TO_INCIDENT]
                  .status ? (
                  controlsOnVisitingIncident.map((data) => (
                    <ComplianceStripe
                      warningWithConfirmMessage={warningWithConfirmMessage}
                      key={data._id}
                      compliance={data}
                      actions={
                        <UncontrolledDropdown size="sm" direction="right">
                          <DropdownToggle
                            outline
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            className="border"
                          >
                            <i className="fa-solid fa-ellipsis-h" />
                          </DropdownToggle>
                          <DropdownMenu bottom>
                            <DropdownItem
                              onClick={(e) => {
                                removeISOControl({
                                  toolkits: [],
                                  controls: [data?.control?._id],
                                });
                              }}
                            >
                              Remove
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      }
                    />
                  ))
                ) : (
                  <p className="text-secondary text-center">
                    There are no controls linked to this
                  </p>
                )}
              </PanelWindow>
            </PanelTabs>
          </React.Fragment>
        )
      )}
    </React.Fragment>
  );
};

export default IncidentDrawerDetail;
