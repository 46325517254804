
import { TaskContextProvider } from "views/taskManagement/store";
import ReviewTable from "./ReviewTable";
import { ScheduleContextProvider } from "./store";
import { DrawerContextProvider } from "ims-ui-kit";

const ManagementReview = (props) => {
  return (
    <div className="content">
      <DrawerContextProvider>
        <ScheduleContextProvider {...props}>
          <TaskContextProvider>
            <ReviewTable {...props} />
          </TaskContextProvider>
        </ScheduleContextProvider>
      </DrawerContextProvider>
    </div>
  );
};

export default ManagementReview;
