import React from "react";
import CampaignTable from "./CampaignTable";
import { DrawerContextProvider } from "ims-ui-kit";
import { CampaignContextProvider } from "./store";

const EmailCampaign = (props) => {
  return (
    <React.Fragment>
      <DrawerContextProvider>
        <CampaignContextProvider {...props}>
          <CampaignTable {...props} />
        </CampaignContextProvider>
      </DrawerContextProvider>
    </React.Fragment>
  );
};

export default EmailCampaign;
