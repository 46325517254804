import { Button, Col, Input, InputGroup, Row } from "ims-ui-kit";
import { createRef, useEffect, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import { imsLogger } from "services/loggerService";
import { Rnd } from "react-rnd";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import React from "react";
import * as fileHandlerService from "services/fileHandlerService";
import Loading from "components/Loader/Loading";
function isWordFile(fileName = "") {
  for (let ext of ["doc", "docx"]) {
    if (fileName.endsWith(ext)) return true;
  }
  return false;
}
export function SignatureplacePicker({
  fileDetails,
  onConfirm = () => {},
  onDragStop = () => {},
  onChange = () => {},
  onPageChange = () => {},
}) {
  const [previewUrl, setPreiewUrl] = useState(null);
  const [totalPages, setTotalPages] = useState(20);
  const [curretPage, setCurretPage] = useState(1);
  const [drgaState, setDragState] = useState(null);
  const pageElement = createRef();
  useEffect(() => {
    const getUrl = async () => {
      try {
        if (isWordFile(fileDetails.Name)) {
          let url = await fileHandlerService.getDocumentPreview(fileDetails);
          setPreiewUrl(url);
        } else {
          let { data } = await fileHandlerService.getSignedUrl(fileDetails);
          setPreiewUrl(data.url);
        }
      } catch (err) {
        imsLogger(err);
      }
    };
    getUrl();
  }, []);
  const _prepareAccumulatedEventData = () => {
    const parentWidth =
      pageElement?.current?.getBoundingClientRect().width || 0;
    const parentHeight =
      pageElement?.current?.getBoundingClientRect().height || 0;
    const topLeftInPercentage = {
      x: parseFloat((drgaState?.x / parentWidth).toFixed(2)),
      y: parseFloat((drgaState?.y / parentHeight).toFixed(2)),
    };
    const event = {
      signaturePosition: { topLeftInPercentage },
      pageNumber: curretPage,
    };
    return event;
  };
  const _onChange = () => {
    onChange(_prepareAccumulatedEventData());
  };
  const _onConfirm = (e) => {
    onConfirm(_prepareAccumulatedEventData());
  };
  const _onPageChange = (page) => {
    setCurretPage(page);
    onPageChange(page);
    _onChange();
  };
  const _onDragStop = (e, d) => {
    setDragState(d);
    onDragStop(d);
    /** maybe need to handle for change event for dragging in future for better dev experience */
  };
  const _nextPage = () => {
    if (curretPage + 1 <= totalPages) _onPageChange(curretPage + 1);
  };
  const _prevPage = () => {
    if (curretPage - 1 >= 1) _onPageChange(curretPage - 1);
  };
  const _onInputChange = (e) => {
    let value = e.currentTarget.value;
    value = parseInt(value);
    if (value && value >= 0 && value <= totalPages) return _onPageChange(value);
  };
  return (
    <React.Fragment>
      <Row>
        <Col md={12}>
          <InputGroup>
            <Button size="sm" onClick={_prevPage} className="">
              <i className="fa-solid fa-arrow-left-long" />
            </Button>
            <Input
              type="number"
              bsSize="sm"
              className="text-center my-auto"
              placeholder="Select page"
              onChange={_onInputChange}
            />
            <Button size="sm" onClick={_nextPage} className="">
              <i className="fa-solid fa-arrow-right-long" />
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md="12">
          {previewUrl ? (
            <Document
              file={previewUrl}
              onLoadSuccess={({ numPages, ...rest }) => {
                setTotalPages(numPages);
              }}
              onLoadError={(err) => imsLogger(err)}
              loading={<Loading text={`Loading document...`} />}
            >
              <div ref={pageElement} className="shadow-md m-4 overflow-hidden">
                <Page
                  canvasRef={pageElement}
                  width={570}
                  pageNumber={curretPage}
                  scale={1}
                >
                  <Rnd
                    enableResizing={false}
                    bounds={"parent"}
                    onDragStop={_onDragStop}
                    minHeight={70}
                    maxHeight={70}
                    minWidth={140}
                    maxWidth={140}
                    style={{
                      border: 2,
                      borderColor: "#1d8bf8",
                      borderStyle: "solid",
                      color: "#1d8bf8",
                      backgroundColor: "rgba(29,139,248,.12)",
                      borderRadius: 5,
                    }}
                  >
                    <span
                      className="text-center my-3"
                      style={{ color: "#1d8bf8" }}
                    >
                      Signature
                    </span>
                  </Rnd>
                </Page>
              </div>
              <center>
                <small className="text-center">
                  <span className="text-primary">{curretPage}</span>/
                  {totalPages}{" "}
                </small>
              </center>
            </Document>
          ) : (
            <Loading text="Processing document..." />
          )}
        </Col>
      </Row>
      <Button
        size="sm"
        color="primary"
        className="btn-block mt-1"
        onClick={_onConfirm}
      >
        Confirm
      </Button>
    </React.Fragment>
  );
}
