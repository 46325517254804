import Loading from "components/Loader/Loading";
import ReactTable from "components/ReactTable/ReactTable";
import useAccess from "hooks/useAccess";
import {
  Button,
  DrawerOpener,
  DrawerRight,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
  useDrawer,
} from "ims-ui-kit";
import React from "react";
import Index from "views/tagsAndCategoriesManager/Index";
import { ACTIONS, EFFECTS, IMS_SERVICES } from "rolesAndPermissions";
import CreateOrganizationAsset from "./CreateOrganizationAsset";
import OrganizationAssetDrawerDetail from "./OrganizationAssetDrawerDetail";
import OrganizationAssetDrawerForm from "./OrganizationAssetDrawerForm";
import OrganizationAssetToolBar from "./OrganizationAssetToolbar";
import USER_ACTIONS from "./actions";
import { useOrganizationAssets } from "./store";
import { useHistory } from "react-router-dom";
import OrganizationAssetForm from "./OrganisationAssetForm";
import OrganisationFilter from "./OrganisationFilter";
import useAlerts from "hooks/useAlerts";

const defaultdata = [["No data found"]];

const ReactTables = ({ ...props }) => {
  let { toggle, openDrawer, closeDrawer } = useDrawer();
  let {
    organizationsAssets,
    pathname,
    processing,
    visitOrganization,
    handleDeleteOrganization,
    fetchOrganizations,
    OrganizationQueryTools,
    organization,
    createOrganization,
    ...rest
  } = useOrganizationAssets();

  let { alert, warningWithConfirmMessage } = useAlerts();

  let { authUser, authGlobalAccess, authAdminAccess, entityAccessControl } =
    useAccess();

  let history = useHistory();

  organizationsAssets = organizationsAssets ? organizationsAssets : defaultdata;
  const data = React.useMemo(
    () =>
      organizationsAssets.map((data, key) => {
        return {
          id: data._id,
          reference: data.reference,
          information_inventory: data.informationInventory,
          group: data.group && data.group.name,
          title: data.title,
          storage_location: data.storageLocation,
          owner: (data.owner && data.owner.name) || "Not assigned",
          data: data,
          actions: (
            <>
              <UncontrolledDropdown size="sm" direction="right">
                <DropdownToggle
                  outline
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  data-display="static"
                  className="border border rounded-circle"
                >
                  <i className="fa-solid fa-ellipsis-h" />
                </DropdownToggle>
                <DropdownMenu>
                  <DrawerOpener>
                    <DropdownItem
                      id="detail"
                      tooltip="View Details"
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push(
                          `/admin/inventory/information/${data._id}`
                        );
                      }}
                    >
                      Details
                    </DropdownItem>
                  </DrawerOpener>

                  {authUser({
                    service: IMS_SERVICES.INVENTORY,
                    action: ACTIONS.DELETE,
                    effect: EFFECTS.ALLOW,
                  }) &&
                    (authAdminAccess() ||
                      entityAccessControl({
                        users: data.created.by ? [data.created.by._id] : [],
                        effect: "Allow",
                      })) && (
                      <DropdownItem
                        onClick={(e) => {
                          warningWithConfirmMessage(
                            "This asset will be deleted",
                            () => {
                              handleDeleteOrganization(data);
                            }
                          );
                          e.stopPropagation();
                        }}
                        id="delete"
                        tooltip="Delete"
                      >
                        {processing[USER_ACTIONS.DELETE_ORGANIZATION].id &&
                        processing[USER_ACTIONS.DELETE_ORGANIZATION].id ===
                          data._id ? (
                          <Spinner size="sm" />
                        ) : (
                          "Delete"
                        )}
                      </DropdownItem>
                    )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          ),
        };
      }),
    [processing, organizationsAssets]
  );
  return (
    <>
      {alert}
      <div className="content">
        {processing[USER_ACTIONS.LOAD_ORGANIZATIONS].status && <Loading />}
        <ReactTable
          data={data}
          {...rest}
          resizable={false}
          columns={[
            {
              Header: "Reference",
              accessor: "reference",
            },
            {
              Header: authGlobalAccess() ? "Business unit" : "Business unit",
              accessor: "group",
            },
            {
              Header: "Info inventory",
              accessor: "information_inventory",
            },
            {
              Header: "Title",
              accessor: "title",
            },
            {
              Header: "Storage location",
              accessor: "storage_location",
            },
            {
              Header: "Owner",
              accessor: "owner",
            },
            {
              Header: "Actions",
              accessor: "actions",
              sortable: false,
              filterable: false,
            },
          ]}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
          isFilterable
          isSearchable
          pathname={pathname}
          processing={processing}
          onPageChange={fetchOrganizations}
          pagination={OrganizationQueryTools.toolState.pagination}
          tableToolbar={
            <React.Fragment>
              <CreateOrganizationAsset />
              {"  "}
              <DrawerOpener drawerId="tags">
                <Button
                  color="primary"
                  outline
                  size="md"
                  className="shadow-sm--hover ms-3"
                >
                  <i className="ims-icons icon-icon-tag-24 me-1 p-0"></i>
                  {"  "} Categories
                </Button>
              </DrawerOpener>
            </React.Fragment>
          }
          filterToolbar={<OrganisationFilter />}
          {...OrganizationQueryTools}
          title={"Information assets"}
          rowProps={function (row) {
            return {
              onClick: function (e) {
                visitOrganization(row.original.data);
                toggle("organization-asset-detail");
              },
            };
          }}
        />

        <DrawerRight
          drawerId="organization-asset-detail"
          onDrawerClose={() => {
            visitOrganization(null);
          }}
          toolbar={
            <React.Fragment>
              <OrganizationAssetToolBar />
            </React.Fragment>
          }
        >
          <OrganizationAssetDrawerDetail />
        </DrawerRight>
        <DrawerRight drawerId="edit-organization-asset-form">
          {organization && <OrganizationAssetDrawerForm />}
        </DrawerRight>
        <DrawerRight drawerId="create-organization-asset-form">
          <OrganizationAssetForm
            drawerView={true}
            processing={processing}
            organization={organization}
            onSubmit={async (data) => {
              await createOrganization(data);
              closeDrawer("create-organization-asset-form");
              openDrawer("organization-asset-detail");
            }}
          />
        </DrawerRight>
        <DrawerRight drawerId="tags">
          <Index applicableModules={"informationassets"} />
        </DrawerRight>
      </div>
    </>
  );
};

export default ReactTables;
