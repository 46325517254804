import React from "react";
import { TaskContextProvider } from "views/taskManagement/store";
import CRMTable from "./CRMTable";
import { CRMContextProvider } from "./store";
import { DrawerContextProvider } from "ims-ui-kit";
import { TagsAndCategoriesContextProvider } from "views/tagsAndCategoriesManager/store";

const CRM = (props) => {
  return (
    <div className="content">
      <DrawerContextProvider>
        <CRMContextProvider {...props}>
          <TaskContextProvider>
            <TagsAndCategoriesContextProvider applicableModules={"customers"}>
              <CRMTable {...props} />
            </TagsAndCategoriesContextProvider>
          </TaskContextProvider>
        </CRMContextProvider>
      </DrawerContextProvider>
    </div>
  );
};

export default CRM;
