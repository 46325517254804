let LOADERS = {
    LOAD_GROUP: "load-group",
    LOAD_GROUPS: "load-group",
    LOAD_USERS: "load-users",
    CREATE_GROUP:'create-group',
    AMEND_GROUP: "amend-group",
    ADD_MEMBERS: "add-members",
    GRANT_ACCESS: 'grand-access',
    REVOKE_USER: "revoke-user",
    REMOVE_USER: 'remove-user',
    LOAD_POLICIES: 'load-policies',
    DELETE_GROUP: ' deleted-group'
};
export default LOADERS