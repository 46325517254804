const USER_ACTIONS = {
  LOAD_HARDWARES: "load-hardwares",
  LOAD_HARDWARE: "load-hardware",
  CREATE_HARDWARE: "create-hardware",
  UPDATE_HARDWARE: "update-hardware",
  DELETE_HARDWARE: "delete-hardware",
  DELETE_ATTACHMENT: "delete-attachment",
  LIST_HARDWARE_TAGS: "list-hardware-tags",
};
export default USER_ACTIONS;
