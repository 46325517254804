import ImsPaddingPieChart from "components/charts/ImsPaddingPieChart";
import { Card, CardBody, Col, Row } from "ims-ui-kit";
import { Pie } from "react-chartjs-2";

const SupplierManagement = ({ dataSet, unmappedData }) => {
  return (
    <Row>
      <Col xl="5" md="12">
        <Card
          style={{
            minHeight: "385px",
          }}
          variant="secondary"
        >
          <h4
            style={{
              fontSize: "20px",
            }}
          >
            Supplier Management
          </h4>
          {/* <h5 className="card-category">
            <Link to="/admin/cip" className="module-link">
              Compliant vs non-compliant
            </Link>
          </h5> */}
          <CardBody className="pt-3">
            <div className="chart-area">
              <ImsPaddingPieChart
                data={dataSet.suppliersVsCompliant.data}
                options={dataSet.suppliersVsCompliant.options}
              />
            </div>
            <div
              style={{
                width: "362px",
                borderTop: "1px solid #E5E5E5",
                height: "1px",
                marginTop: "30px",
              }}
            ></div>
            <Row>
              <Col xs="6" className="p-3">
                {/* <p className="font-size-subtitle-1">
                  {dataSet.suppliersVsCompliant.data[0].value}
                </p> */}
                <p className="font-size-subtitle-2">
                  <i
                    style={{
                      width: "6px",
                      height: "6px",
                    }}
                    class="fa-solid fa-circle-dot text-primary mr-3"
                  ></i>{" "}
                  Compliant{" "} - {dataSet.suppliersVsCompliant.data[0].value}
                </p>
              </Col>
              <Col xs="6" className="py-3 ">
                {/* <p className="font-size-subtitle-1">
                  {dataSet.suppliersVsCompliant.data[1].value}
                </p> */}
                <p className="font-size-subtitle-2">
                  <i
                    style={{
                      width: "6px",
                      height: "6px",
                      color: "#FFC107",
                    }}
                    class="fa-solid fa-circle-dot  mr-3"
                  ></i>{" "}
                  Non-Compliant{" "} - {dataSet.suppliersVsCompliant.data[1].value}
                </p>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col xl="7" md="12">
        <Card
          style={{
            minHeight: "121px",
          }}
          variant="gradient"
          color="success"
        >
          <CardBody>
            <CardBody>
              <p>
                <i
                  style={{
                    width: "8px",
                    height: "8px",
                  }}
                  class="fa-solid fa-circle text-success mr-3"
                ></i>{" "}
                <span
                  style={{
                    fontSize: "16px",
                  }}
                >
                  Procurement value
                </span>
              </p>
              <h2
                style={{
                  fontSize: "32px",
                }}
              >
                £{parseInt(dataSet.totalContractValue).toLocaleString()}
              </h2>
              {/* <i className="tim-icons icon-delivery-fast text-success" />{" "}
                Total Suppliers{" "}
                {dataSet.suppliers.compliant + dataSet.suppliers.inCompliant}
              </CardTitle>
              <p className="category">
                Compliant {dataSet.suppliers.percentage}%
              </p> */}
            </CardBody>
          </CardBody>
        </Card>
        <Card
          style={{
            minHeight: "220px",
          }}
          variant="secondary"
        >
          <h4
            style={{
              fontSize: "20px",
            }}
          >
            Supplier Incidents
          </h4>
          <CardBody>
            <Row>
              <Col sm="6">
                <ImsPaddingPieChart
                  data={dataSet.supplierIncidentVsResolvedIncident.data}
                  options={dataSet.supplierIncidentVsResolvedIncident.options}
                />
                {/* <Pie
                  style={{
                    minHeight: "243px",
                  }}
                  data={{
                    labels: ["Total Resolved", "Total Incidents"],
                    datasets: [
                      {
                        data: [
                          dataSet.incidents.resolved,
                          dataSet.incidents.total,
                        ],
                        backgroundColor: ["#CED4DA", "#439EED"],
                        hoverBackgroundColor: ["#CED4DA", "#439EED"],
                      },
                    ],
                  }}
                  options={{
                    legend: {
                      display: false,
                    },
                    tooltips: {
                      enabled: true,
                    },
                    cutoutPercentage: 70,
                    animation: {
                      animateScale: true,
                    },
                  }}
                /> */}
              </Col>
              <Col
                sm="6"
                style={{
                  paddingTop: "35px",
                }}
              >
                <span
                  // style={{
                  //   fontSize: "20px",
                  // }}
                  className="text-secondary font-size-subtitle-1"
                >
                  {" "}
                  Total incidents :{" "}
                  {dataSet.supplierIncidents.open +
                    dataSet.supplierIncidents.resolved}
                </span>
                <p className="text-secondary font-size-subtitle-2">
                  <i
                    style={{
                      width: "8px",
                      height: "8px",
                    }}
                    class="fa-solid fa-circle-dot text-primary mr-3"
                  ></i>{" "}
                  Open Incidents : {dataSet.supplierIncidents.open}
                </p>

                <p className="text-secondary font-size-subtitle-2">
                  <i
                    style={{
                      width: "8px",
                      height: "8px",
                    }}
                    class="fa-solid fa-circle-dot text-secondary mr-3"
                  ></i>{" "}
                  Total resolved : {dataSet.supplierIncidents.resolved}
                </p>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default SupplierManagement;
