const filters = [
  {
    value: "",
    label: "All units",
    default: true
  },
  {
    value: { type: "Internal function" },
    label: "Internal function",
  },
  {
    value: { type: "External function" },
    label: "External function",
  },
];
export default filters