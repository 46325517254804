import React from 'react';
import { CRMContextProvider } from '../store';
import MyCustomerOverview from './MyCustomerOverview';

const MyCRMOverviewIndex = () => {
    return (
        <CRMContextProvider>
            <MyCustomerOverview/>
        </CRMContextProvider>
    );
};

export default MyCRMOverviewIndex;