import React from "react";
import Results from "./Results";
import Navigator from "./Navigator";
const Contents = ({}) => {
  return (
    <React.Fragment>
      <Navigator />
      <Results />
    </React.Fragment>
  );
};

export default Contents;
