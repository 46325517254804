const USER_ACTIONS = {
  UPDATE_NODE_INFO: "update-node-info",
  ADD_NEW_VERSION: "add-new-version",
  ADD_REVISION: "add-revision",
  SHARE_DOCUMENT: "share-document",
  REMOVE_SIGNEE: "remove-signee",
  ADD_EXTERNAL_SIGNEE: "add-external-signee",
  ADD_INTERNAL_SIGNEE: "add-internal-signee",
  SIGN_DOCUMENT: "share-document",
  HANDLE_APPROVAL: "handle-approval",
  LOAD_SIGNATURES: "load-signatures",
  LOAD_VERSIONS: "load-versions",
  LOAD_NODE: "load-node",
  RESEND_INTERNAL_SIGNEE:"resend-internal-signee",
  RESEND_EXTERNAL_SIGNEE:"resend-external-signee",
};
export default USER_ACTIONS;
