import Loading from "components/Loader/Loading";
import ReactTable from "components/ReactTable/ReactTable";
import useAccess from "hooks/useAccess";
import {
  Button,
  DrawerOpener,
  DrawerRight,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
  useDrawer,
} from "ims-ui-kit";
import moment from "moment";
import React from "react";
import { ACTIONS, EFFECTS, IMS_SERVICES } from "rolesAndPermissions";
import TimeDateComponent from "views/shared/TimeDateComponent";
import CreateHardwareAsset from "./CreateHardwareAsset";
import HardwareAssetDrawerForm from "./HardwareAssetDrawerForm";
import HardwareAssetToolbar from "./HardwareAssetToolbar";
import HardwareAssetDrawerDetail from "./HardwareAssetsDrawerDetail";
import USER_ACTIONS from "./actions";
import { useHardwareAssets } from "./store";
import { useHistory } from "react-router-dom";
import HardwareAssetForm from "./HardwareAssetForm";
import useAlerts from "hooks/useAlerts";
import Index from "views/tagsAndCategoriesManager/Index";
import HardwareFilter from "./HardwareFilter";
const defaultdata = [["No data found"]];

const ReactTables = ({}) => {
  let {
    hardwareAssets,
    pathname,
    processing,
    handleDelete,
    fetchHardwares,
    HardwareQueryTools,
    visitHardware,
    createHardwareAsset,
    hardware,
    ...rest
  } = useHardwareAssets();

  let { alert, warningWithConfirmMessage } = useAlerts();
  let { authUser, authGlobalAccess, authAdminAccess, entityAccessControl } =
    useAccess();

  let { closeDrawer, openDrawer } = useDrawer();

  hardwareAssets = hardwareAssets ? hardwareAssets : defaultdata;
  let history = useHistory();
  const data = React.useMemo(
    () =>
      hardwareAssets.map((data, key) => {
        return {
          id: data._id,
          reference: data.reference,
          asset_name: data.name,
          group: data.group && data.group.name,
          asset_tag: data.tag || "No tag",
          asset_owner: (data.owner && data.owner.name) || "Not assigned",
          assigned_date: data.assignedDate ? (
            <TimeDateComponent date={data.assignedDate} />
          ) : (
            "N/A"
          ),
          returned_date: data.returnDate
            ? moment(data.returnDate).format("DD/MM/YYYY")
            : "N/A",
          data: data,
          actions: (
            <UncontrolledDropdown size="sm" direction="right">
              <DropdownToggle
                outline
                onClick={(e) => {
                  e.stopPropagation();
                  visitHardware(data);
                }}
                data-display="static"
                className="border border rounded-circle"
              >
                <i className="fa-solid fa-ellipsis-h" />
              </DropdownToggle>
              <DropdownMenu>
                <DrawerOpener drawerId="hardware-assets-detail">
                  <DropdownItem
                    id="detail"
                    tooltip="View Details"
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(`/admin/inventory/hardware/${data._id}`);
                    }}
                  >
                    Details
                  </DropdownItem>
                </DrawerOpener>
                {authUser({
                  service: IMS_SERVICES.INVENTORY,
                  action: ACTIONS.DELETE,
                  effect: EFFECTS.ALLOW,
                }) &&
                  (authAdminAccess() ||
                    entityAccessControl({
                      users: data.created.by ? [data.created.by._id] : [],
                      effect: "Allow",
                    })) && (
                    <DropdownItem
                      onClick={(e) => {
                        warningWithConfirmMessage(
                          "This asset will be deleted",
                          () => {
                            handleDelete(data);
                          }
                        );
                        e.stopPropagation();
                      }}
                      id="delete"
                      tooltip="Delete"
                    >
                      {processing[USER_ACTIONS.DELETE_HARDWARE] &&
                      processing[USER_ACTIONS.DELETE_HARDWARE].id ===
                        data._id ? (
                        <Spinner size="sm" />
                      ) : (
                        "Delete"
                      )}
                    </DropdownItem>
                  )}
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        };
      }),
    [processing]
  );
  return (
    <>
      {alert}
      <div className="content">
        {processing[USER_ACTIONS.LOAD_HARDWARES].status && <Loading />}
        <ReactTable
          data={data}
          {...rest}
          resizable={false}
          columns={[
            {
              Header: "Reference",
              accessor: "reference",
            },
            {
              Header: authGlobalAccess() ? "Business unit" : "Business unit",
              accessor: "group",
            },
            {
              Header: "Asset name",
              accessor: "asset_name",
            },
            {
              Header: "Asset tag",
              accessor: "asset_tag",
            },
            {
              Header: "Asset Owner",
              accessor: "asset_owner",
            },
            {
              Header: "Assigned date",
              accessor: "assigned_date",
            },

            {
              Header: "Actions",
              accessor: "actions",
              sortable: false,
              filterable: false,
            },
          ]}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
          isFilterable
          isSearchable
          dataTable={hardwareAssets}
          pathname={pathname}
          processing={processing}
          onPageChange={fetchHardwares}
          title={"Hardware assets"}
          tableToolbar={
            <React.Fragment>
              {authUser({
                service: IMS_SERVICES.INVENTORY,
                action: ACTIONS.CREATE,
                effect: EFFECTS.ALLOW,
              }) && <CreateHardwareAsset />}
              {"  "}
              <DrawerOpener drawerId="tags">
                <Button
                  color="primary"
                  outline
                  size="md"
                  className="shadow-sm--hover ms-3"
                >
                  <i className="ims-icons icon-icon-tag-24 me-1 p-0"></i>
                  {"  "} Categories
                </Button>
              </DrawerOpener>
            </React.Fragment>
          }
          filterToolbar={<HardwareFilter />}
          pagination={HardwareQueryTools.toolState.pagination}
          {...HardwareQueryTools}
          rowProps={function (row) {
            return {
              onClick: function (e) {
                visitHardware(row.original.data);
                openDrawer("hardware-asset-detail");
              },
            };
          }}
        />
        <DrawerRight
          onDrawerClose={() => {
            visitHardware(null);
          }}
          toolbar={<HardwareAssetToolbar />}
          drawerId="hardware-asset-detail"
        >
          <HardwareAssetDrawerDetail />
        </DrawerRight>
        <DrawerRight drawerId="edit-hardware-asset-form">
          {hardware && <HardwareAssetDrawerForm />}
        </DrawerRight>
        <DrawerRight drawerId="create-hardware-asset">
          <HardwareAssetForm
            processing={processing}
            drawerView={true}
            onSubmit={async (data) => {
              await createHardwareAsset(data);
              closeDrawer("create-hardware-asset");
              openDrawer("hardware-asset-detail");
            }}
          />
        </DrawerRight>
        <DrawerRight drawerId="tags">
          <Index applicableModules="hardwareassets" />
        </DrawerRight>
      </div>
    </>
  );
};

export default ReactTables;
