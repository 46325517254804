import ImsBarChart from "components/charts/ImsBarChart";
import { Card, CardBody, Col, Row } from "ims-ui-kit";
import React from "react";
import { Link } from "react-router-dom";

const InventoryManagement = ({ dataSet, unmappedData }) => {
  return (
    <React.Fragment>
      <Row>
        <Col md="6">
          <Card variant="secondary">
            <span className="font-size-subtitle-1">
              <Link to="/admin/inventory/hardware" className="module-link">
                Assets
              </Link>
            </span>
            <CardBody>
              <div
                style={{
                  minHeight: "250px",
                }}
                className="chart-area"
              >
                <ImsBarChart
                  data={dataSet.inventory.data}
                  options={dataSet.inventory.options}
                />
                {/* <Bar
                  data={dataSet.inventory.data}
                  options={dataSet.inventory.options}
                /> */}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md="6">
          <Card variant="secondary">
            <span className="font-size-subtitle-1">
              <Link to="/admin/inventory/hardware" className="module-link">
                Asset expenditure
              </Link>
            </span>
            <CardBody>
              <div
                style={{
                  minHeight: "250px",
                }}
                className="chart-area"
              >
                <ImsBarChart
                  data={dataSet.finance.data}
                  options={dataSet.finance.options}
                />
                {/* <Bar
                  data={dataSet.finance.data}
                  options={dataSet.finance.options}
                /> */}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default InventoryManagement;
