import Loading from "components/Loader/Loading";
import { Panel, Panels } from "components/Panel/HorizontalPanel";
import { Button, ImsCard } from "ims-ui-kit";
import React from "react";
import { IMS_SERVICES } from "rolesAndPermissions";
import ErrorHandlerComponent from "views/shared/ErrorHandlerComponent";
import ComplianceToolTable from "../ComplianceToolTable";
import Analytics from "../Overview/Analytics";
import LOADER from "./actions";
import { useBS9997 } from "./store";
import BS9997Filter from "./BS9997Filter";
import { ACTIONS } from "rolesAndPermissions";
import { EFFECTS } from "rolesAndPermissions";
import SearchableDocument from "views/documentManagement/searchableList/components/Index";
import useAccess from "hooks/useAccess";
import DocumentListOpener from "views/documentManagement/searchableList/components/DocumentListOpener";

const BS9997 = (props) => {
  let {
    processing,
    bs9997Controls,
    setBs9997Controls,
    bs9997Overview,
    updateDataTable,
    BS9997QueryTools,
  } = useBS9997();

  let { authUser } = useAccess();
  return (
    <div className="content">
      <Panels
        navLinks={["Overview", "BS 9997 (2019)"]}
        defaultPanel={"Overview"}
      >
        <Panel panelId="Overview">
          <ImsCard>
            <ErrorHandlerComponent
              hasError={processing[LOADER.LOAD_OVERVIEW_BS9997].error}
              errorMessage="This iso tool has been deleted or removed"
            >
              {processing[LOADER.LOAD_OVERVIEW_BS9997].status ? (
                <Loading />
              ) : (
                <Analytics
                  toolKit={IMS_SERVICES.BS9997}
                  overview={bs9997Overview}
                />
              )}
            </ErrorHandlerComponent>
          </ImsCard>
        </Panel>
        <Panel panelId="BS 9997 (2019)">
          <ImsCard>
            <ErrorHandlerComponent
              hasError={processing[LOADER.LOAD_COMPLIANCE_BS9997].error}
              errorMessage="This iso tool has been deleted or removed"
            >
              {processing[LOADER.LOAD_COMPLIANCE_BS9997].status && <Loading />}
              <SearchableDocument
                moduleTypes={["compliancecontrols"]}
                complianceTools={["BS 9997"]}
              />
              <ComplianceToolTable
                toolkit="BS 9997 (Fire Risk Assessment System-2019)"
                dataTable={bs9997Controls}
                setDataTable={setBs9997Controls}
                updateDataTable={updateDataTable}
                processing={processing[LOADER.LOAD_COMPLIANCE_BS9997]}
                pagination={BS9997QueryTools.toolState.pagination}
                tableToolbar={
                  <React.Fragment>
                    {authUser({
                      service: IMS_SERVICES.DOCUMENT_MANAGEMENT,
                      action: ACTIONS.READ,
                      effect: EFFECTS.ALLOW,
                    }) && (
                      <DocumentListOpener>
                        <Button
                          color="primary"
                          outline
                          size="md"
                          className="shadow-sm--hover ms-3"
                        >
                          Documents
                        </Button>
                      </DocumentListOpener>
                    )}
                  </React.Fragment>
                }
                filterToolbar={<BS9997Filter />}
                {...BS9997QueryTools}
              />
            </ErrorHandlerComponent>
          </ImsCard>
        </Panel>
      </Panels>
    </div>
  );
};

export default BS9997;
