const filters = [
    {
        value: "",
        label: "Full toolkit",
        default: true
    },
    {
        value: { clause: { regex: '^1.*$' } },
        label: "Section-1",

    },
    {
        value: { clause: { regex: '^2.*$' } },
        label: "Section-2",

    },
    {
        value: { clause: { regex: '^3.*$' } },
        label: "Section-3",

    },
    {
        value: { clause: { regex: '^4.*$' } },
        label: "Section-4",

    },
    {
        value: { clause: { regex: '^5.*$' } },
        label: "Section-5"
    },
    {
        value: { clause: { regex: '^6.*$' } },
        label: "Section-6"
    },
    {
        value: { clause: { regex: '^7.*$' } },
        label: "Section-7"
    },
    {
        value: { clause: { regex: '^8.*$' } },
        label: "Section-8"
    },
    {
        value: { clause: { regex: '^9.*$' } },
        label: "Section-9"
    },
    {
        value: { clause: { regex: '^10.*$' } },
        label: "Section-10"
    },
]

export default filters