import { Card, CardBody, Table } from "ims-ui-kit";

const PeopleAssetOverview = ({ data }) => {
  return (
    <Card className="shadow-none border-0">
      <CardBody className="">
        <Table borderless responsive className="table-sm">
          {data && (
            <tbody>
              <tr>
                <td className="w-50 text-dark">Reference</td>
                <td className="text-left">
                  <span className="text-info">{data?.reference}</span>
                </td>
              </tr>
              <tr>
                <td className="text-dark">Business unit</td>
                <td className="text-left">
                  <span className="text-info">{data?.group?.name}</span>
                </td>
              </tr>
              <tr>
                <td className="text-dark">Role</td>
                <td className="text-left">
                  <span>{data.role}</span>
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </CardBody>
    </Card>
  );
};

export default PeopleAssetOverview;
