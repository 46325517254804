import { Card, CardBody, Col, Row } from "ims-ui-kit";
import React from "react";

const AvgResolutionTime = ({ dataSet }) => {
  return (
    dataSet && (
      <React.Fragment>
        <Card
          variant="secondary"
          style={{
            minHeight: "312px",
          }}
        >
          <h4
            style={{
              fontSize: "20px",
            }}
          >
            Average resolution time
          </h4>
          <CardBody className="px-4 mt-5">
            <div className="my-2"></div>
            <Row>
              <Col md="6" sm="6" className="mb-3">
                <div className="numbers">
                  <p className="pb-0">Resolution time P1</p>
                  <h4
                    className={
                      dataSet.incidents.p1AvgResolutionTime.alert
                        ? "text-danger"
                        : "text-success"
                    }
                  >
                    {dataSet.incidents.p1AvgResolutionTime.time}
                  </h4>
                </div>

                <span
                  className="d-none d-xl-block"
                  style={{
                    borderLeft: "1px solid #CED4DA",
                    height: "80px",
                    position: "absolute",
                    left: "100%",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                ></span>
              </Col>
              <Col md="6" sm="6" className="mb-3">
                <div className="numbers">
                  <p className="">Resolution time P2</p>
                  <h4
                    className={
                      dataSet.incidents.p2AvgResolutionTime.alert
                        ? "text-danger"
                        : "text-success"
                    }
                  >
                    {dataSet.incidents.p2AvgResolutionTime.time}
                  </h4>
                </div>
              </Col>
              <Col md="6" sm="6" className="mb-3">
                <div className="numbers">
                  <p className="">Resolution time P3</p>
                  <h4
                    className={
                      dataSet.incidents.p3AvgResolutionTime.alert
                        ? "text-danger"
                        : "text-success"
                    }
                  >
                    {dataSet.incidents.p3AvgResolutionTime.time}
                  </h4>
                </div>

                <span
                  className="d-none d-xl-block"
                  style={{
                    borderLeft: "1px solid #CED4DA",
                    height: "80px",
                    position: "absolute",
                    left: "100%",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                ></span>
              </Col>
              <Col md="6" sm="6" className="mb-3">
                <Row>
                  {/* <Col xs="3">
                      <div className="info-icon text-center icon-danger">
                        <i className="tim-icons icon-alert-circle-exc" />
                      </div>
                    </Col> */}
                  <Col xs="9">
                    <div className="numbers">
                      <p className="">Resolution time P4</p>
                      <h4
                        className={
                          dataSet.incidents.p4AvgResolutionTime.alert
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        {dataSet.incidents.p4AvgResolutionTime.time}
                      </h4>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  );
};

export default AvgResolutionTime;
