import { IncidentContextProvider } from "./store";
import IncidentsTable from "./IncidentsTable";
import { TaskContextProvider } from "views/taskManagement/store";
import { DrawerContextProvider } from "ims-ui-kit";
import { TagsAndCategoriesContextProvider } from "views/tagsAndCategoriesManager/store";

const IncidentManagement = ({
  moduleType = ["incidents", "audits"],
  moduleId = null,
  ...props
}) => {
  return (
    <DrawerContextProvider>
      <IncidentContextProvider
        moduleType={moduleType}
        moduleId={moduleId}
        {...props}
      >
        <TaskContextProvider>
          <TagsAndCategoriesContextProvider applicableModules={"incidents"}>
            <IncidentsTable {...props} />
          </TagsAndCategoriesContextProvider>
        </TaskContextProvider>
      </IncidentContextProvider>
    </DrawerContextProvider>
  );
};

export default IncidentManagement;
