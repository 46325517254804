import Loading from "components/Loader/Loading";
import ReactTable from "components/ReactTable/ReactTable";
import useAccess from "hooks/useAccess";
import useAlert from "hooks/useAlerts";
import {
  DrawerRight,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
  useDrawer,
} from "ims-ui-kit";
import React from "react";
import { useHistory } from "react-router-dom";
import { useTagsAndCategories } from "./store";
import USER_ACTIONS from "./store/actions";
import tables from "./tables";
import CreateTagsAndCategories from "./CreateTagsAndCategories";
import TagsAndCategoryForm from "./TagsAndCategoryForm";
import TagsDrawerDetail from "./TagsDrawerDetail";
import TagsToolBar from "./TagsToolBar";
import TagsDrawerForm from "./TagsDrawerForm";
import filters from "./store/filters";

const defaultdata = [["No data found"]];

const TagsAndCategoriesTable = ({ ...props }) => {
  let {
    tagsAndCategories: dataTable,
    processing,
    toolState,
    visitingTagAndCategory,
    visitTagsCategory,
    queryHandlers,
    fetchTags,
    createTagsAndCategories,
    deleteTagAndCategory,
    ...rest
  } = useTagsAndCategories();
  let { authUser, entityAccessControl } = useAccess();
  let history = useHistory();
  let { openDrawer, closeDrawer } = useDrawer();
  let { alert, warningWithConfirmMessage } = useAlert();

  dataTable = dataTable ? dataTable : defaultdata;
  const data = React.useMemo(
    () =>
      dataTable.map((data, key) => {
        return {
          id: data._id,
          reference: data.reference,
          name: (
            <span>
              <i className="ims-icons-20 icon-icon-key-16" /> {data.name}
            </span>
          ),
          applicableModules: data?.applicableModules.map((applicableModule) => (
            <span>{applicableModule} </span>
          )),
          description: data.description,
          actions: (
            <>
              <UncontrolledDropdown size="sm" direction="right">
                <DropdownToggle
                  outline
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  data-display="static"
                  className="border border rounded-circle"
                >
                  <i className="fa-solid fa-ellipsis-h" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={(e) => {
                      warningWithConfirmMessage(
                        "This tag will be deleted",
                        () => {
                          deleteTagAndCategory(data);
                        }
                      );
                      e.stopPropagation();
                    }}
                    name="delete"
                    id="delete"
                    tooltip="Delete"
                  >
                    {processing.action === "delete" &&
                    processing.id === data._id ? (
                      <Spinner size="sm" />
                    ) : (
                      "Delete"
                    )}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          ),
        };
      }),
    [processing]
  );
  let columns = React.useMemo(
    () => toolState.filter.tableState || tables.default,
    [toolState]
  );
  return (
    <>
      {alert}
      <div className="content">
        {processing[USER_ACTIONS.LIST_TAG_AND_CATEGORY].status && <Loading />}
        <ReactTable
          data={data}
          {...rest}
          resizable={false}
          columns={columns.slice()}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
          title={"Categories"}
          tableToolbar={<CreateTagsAndCategories />}
          isFilterable
          isSearchable
          filters={filters}
          rowProps={function (row) {
            return {
              onClick: function (e) {},
            };
          }}
          {...queryHandlers}
          {...props}
          onPageChange={fetchTags}
          pagination={toolState.pagination}
        />

        <DrawerRight drawerId="create-tag">
          <TagsAndCategoryForm
            drawerView={true}
            onSubmit={async (data) => {
              await createTagsAndCategories(data);
              closeDrawer("create-tag");
              // openDrawer("tag-detail");
            }}
          />
        </DrawerRight>
        <DrawerRight
          drawerId="tag-detail"
          onDrawerClose={() => {
            visitTagsCategory(null);
          }}
          toolbar={<TagsToolBar />}
        >
          <TagsDrawerDetail />
        </DrawerRight>
        <DrawerRight drawerId="edit-tag-form">
          <TagsDrawerForm />
        </DrawerRight>
      </div>
    </>
  );
};

export default TagsAndCategoriesTable;
