import { Panel, Panels } from "components/Panel/HorizontalPanel";
import useAccess from "hooks/useAccess";
import { ImsCard } from "ims-ui-kit";
import { ACTIONS, EFFECTS, IMS_SERVICES } from "rolesAndPermissions";
import ExpenseReports from "../expenseReport/ExpenseReports";
import Leaves from "../leaves/Leaves";
import WorkLogs from "../workLog/WorkLogs";
import { getCurrentSessionData } from "services/authService";

const Wallet = (props) => {
  let { authUser, authWalletAccess } = useAccess();
  let walletId = authWalletAccess(props.view?._id)
    ? props.view._id
    : getCurrentSessionData().user?._id;
  return (
    <div className="content">
      <Panels
        defaultPanel={"Expenses"}
        navLinks={
          authUser({
            service: IMS_SERVICES.INCIDENT_MANAGEMENT,
            action: ACTIONS.CREATE,
            effect: EFFECTS.ALLOW,
          }) && ["Expenses", "Leaves", "Work log"]
        }
      >
        <Panel panelId="Expenses">
          <ImsCard>
            <ExpenseReports walletId={walletId} />
          </ImsCard>
        </Panel>
        <Panel panelId="Leaves">
          <ImsCard>
            <Leaves walletId={walletId} />
          </ImsCard>
        </Panel>
        <Panel panelId="Work log">
          <ImsCard>
            <WorkLogs walletId={walletId} />
          </ImsCard>
        </Panel>
      </Panels>
    </div>
  );
};

export default Wallet;
