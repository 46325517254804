import { Button } from "ims-ui-kit";
import { useContext } from "react";
import { ViewContext } from "./contexts/ViewContext";

const SwitchButton = ({ children, ...rest }) => {
  let { switchView } = useContext(ViewContext);
  return (
    <Button outline onClick={() => switchView()} className="mb-2" {...rest}>
      {children}
    </Button>
  );
};

export default SwitchButton;
