let riskTypes = [

    {
        value: 'Hardware',
        label: 'Hardware'
    },
    {
        value: 'Software',
        label: 'Software'
    },
    {
        value: 'People',
        label: 'People'
    },
    {
        value: 'Premise',
        label: 'Premise'
    },
    {
        value: 'Organisational',
        label: 'Organisational'
    },

    {
        value: 'Clinical',
        label: 'Clinical'
    },
]

export default riskTypes;