import Loading from "components/Loader/Loading";
import ReactTable from "components/ReactTable/ReactTable";
import useAccess from "hooks/useAccess";
import useAlerts from "hooks/useAlerts";
import {
  DrawerOpener,
  DrawerRight,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
  useDrawer,
} from "ims-ui-kit";
import React from "react";
import { useHistory } from "react-router-dom";
import { ACTIONS, EFFECTS, IMS_SERVICES } from "rolesAndPermissions";
import { getCurrentSessionData } from "services/authService";
import BadgeStatus from "views/shared/StatusMapper/BadgeStatus";
import TimeDateComponent from "views/shared/TimeDateComponent";
import OrganizationalOverview from "../licenseManagement/OrganizaionalOverview";
import CreateUser from "./CreateUser";
import InvitationForm from "./InvitationForm";
import DeleteProcess from "./deleteProcess/Index";
import filters from "./filters/filters";
import { USER_ACTIONS, useUserManager } from "./store";
import tables from "./tables";
import { useApplication } from "stores/applicationStore";

const defaultdata = [];

const UserTable = ({ ...props }) => {
  let {
    inviteUser,
    users: dataTable,
    processing,
    toolState,
    fetchUsers,
    deleteUser,
    grantAccess,
    revokeAccess,
    visitUser,
    queryHandlers,
    resendVerification,
    setUsers,
    ...rest
  } = useUserManager();
  const { tokenPair } = useApplication();
  dataTable = dataTable ? dataTable : defaultdata;
  let { authUser, authSuperUser } = useAccess();
  let { alert, warningWithConfirmMessage } = useAlerts();

  let history = useHistory();
  let { closeDrawer } = useDrawer();
  const data = React.useMemo(
    () =>
      dataTable.map((data, key) => {
        return {
          id: data._id,
          name: data.name,
          email: data.email,
          jobTitle: data.membership?.find(
            (m) => m.organization === tokenPair?.accessTokenData?.user?.organizationId
          )?.jobTitle,
          status: <BadgeStatus status={data.systemAccess?.status} />,
          data: data,
          userType: data.membership.find(
            (m) =>
              m.organization ===
              tokenPair?.accessTokenData?.user?.organizationId
          )?.role,
          lastLoggedIn: data.loggedIn?.on ? (
            <TimeDateComponent date={data.loggedIn?.on} />
          ) : (
            "Never logged in"
          ),
          actions: (
            <>
              <UncontrolledDropdown size="sm" direction="right">
                <DropdownToggle
                  outline
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  data-display="static"
                  className="border border rounded-circle"
                >
                  <i className="fa-solid fa-ellipsis-h" />
                </DropdownToggle>
                <DropdownMenu>
                  {/* <DropdownItem
                    id="assign"
                    tooltip="Assign Toolkit"
                    to={`${props.match.url}/${data._id}/tools`}
                  >
                    Assign Toolkit
                  </DropdownItem> */}
                  {data.emailVerified.status === "pending" && (
                    <DropdownItem
                      name="resend"
                      // disabled={
                      //   processing.action === "resend" ||
                      //   new Date(data.verificationEmailAfter) > new Date()
                      // }
                      id="resend"
                      tooltip="Resend email"
                      onClick={(e) => {
                        e.stopPropagation();
                        warningWithConfirmMessage(
                          `Verification email will be sent to ${data.email}`,
                          () => {
                            resendVerification(data);
                          }
                        );
                      }}
                    >
                      {processing[USER_ACTIONS.RESEND_VERIFICATION].status &&
                      processing[USER_ACTIONS.RESEND_VERIFICATION].id ==
                        data._id ? (
                        <Spinner size="sm" />
                      ) : (
                        "Resend email"
                      )}
                    </DropdownItem>
                  )}
                  <>
                    <DropdownItem
                      onClick={(e) => {
                        visitUser(data);
                        history.push(`/admin/users/${data._id}`);
                        e.stopPropagation();
                      }}
                      id="detail"
                      tooltip="View Details"
                    >
                      Details
                    </DropdownItem>
                  </>{" "}
                  {/* {authUser({
                    service: IMS_SERVICES.USERS,
                    action: ACTIONS.CREATE,
                    effect: EFFECTS.ALLOW,
                  }) &&
                  authSuperUser() &&
                  data._id !== getCurrentSessionData().user?._id &&
                  data.emailVerified.status !== "pending" ? (
                    data.systemAccess.status === "Blocked" ||
                    (data.systemAccess.period !== "Full time" &&
                      data.systemAccess.expires < Date.now()) ? (
                      <DropdownItem
                        name="grant"
                        disabled={processing[USER_ACTIONS.GRANT_ACCESS].status}
                        href="#"
                        id="grant-access"
                        tooltip="Grant"
                        onClick={(e) => {
                          e.stopPropagation();
                          warningWithConfirmMessage(
                            "Access will be granted",
                            () => {
                              grantAccess(data);
                            }
                          );
                        }}
                      >
                        {processing[USER_ACTIONS.GRANT_ACCESS].status &&
                        processing[USER_ACTIONS.GRANT_ACCESS].id == data._id ? (
                          <Spinner size="sm" />
                        ) : (
                          "Grant"
                        )}
                      </DropdownItem>
                    ) : (
                      <DropdownItem
                        name="revoke"
                        tooltip="Revoke"
                        disabled={processing.action === "revoke"}
                        href="#"
                        id="revoke-access"
                        onClick={(e) => {
                          e.stopPropagation();
                          warningWithConfirmMessage(
                            "Access will be revoked",
                            () => {
                              revokeAccess(data);
                            }
                          );
                          e.stopPropagation();
                        }}
                      >
                        {processing[USER_ACTIONS.REVOKE_ACCESS].status &&
                        processing[USER_ACTIONS.REVOKE_ACCESS].id ==
                          data._id ? (
                          <Spinner size="sm" />
                        ) : (
                          "Revoke"
                        )}
                      </DropdownItem>
                    )
                  ) : null} */}
                  {authUser({
                    service: IMS_SERVICES.INVITATIONS,
                    action: ACTIONS.DELETE,
                  }) &&
                    data._id !== getCurrentSessionData().user?._id &&
                    !data.accessPolicies.length && (
                      <DrawerOpener
                        drawerId="transfer-drawer"
                        onClick={(e) => {
                          e.stopPropagation();
                          visitUser(data);
                        }}
                      >
                        <DropdownItem
                          name="delete"
                          disabled={processing[USER_ACTIONS.DELETE_USER].status}
                          id="delete"
                          tooltip="Delete"
                        >
                          {processing[USER_ACTIONS.DELETE_USER].status &&
                          processing[USER_ACTIONS.DELETE_USER].id ==
                            data._id ? (
                            <Spinner size="sm" />
                          ) : (
                            "Delete"
                          )}
                        </DropdownItem>
                      </DrawerOpener>
                    )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          ),
        };
      }),
    [processing, dataTable]
  );

  let columns = React.useMemo(
    () => toolState.filter.tableState || tables.default,
    [toolState]
  );

  return (
    <React.Fragment>
      {alert}
      {processing[USER_ACTIONS.LOAD_USERS].status && <Loading />}
      <ReactTable
        data={data}
        {...rest}
        resizable={false}
        columns={columns.slice()}
        defaultPageSize={10}
        showPaginationTop
        showPaginationBottom={false}
        className="-striped -highlight"
        isFilterable
        isSearchable
        filters={filters}
        title="Users"
        tableToolbar={
          <React.Fragment>
            <CreateUser />
          </React.Fragment>
        }
        rowProps={function (row) {
          return {
            onClick: function (e) {
              history.push(`/admin/users/${row.original.data._id}`);
            },
          };
        }}
        {...queryHandlers}
        {...props}
        onPageChange={fetchUsers}
        pagination={toolState.pagination}
      />

      <DrawerRight drawerId="create-user">
        <React.Fragment>
          <OrganizationalOverview
            groups={false}
            tools={false}
            className="mb-3"
          />
          <InvitationForm
            drawerView={true}
            onSubmit={async (data) => {
              await inviteUser(data);
              closeDrawer("create-user");
            }}
          />
        </React.Fragment>
      </DrawerRight>
      <DrawerRight drawerId="transfer-drawer">
        <DeleteProcess
          onDelete={(user) =>
            setUsers((prevUsers) =>
              prevUsers.filter((u) => u?._id !== user?._id)
            )
          }
        />
      </DrawerRight>
    </React.Fragment>
  );
};

export default UserTable;
