import Loading from "components/Loader/Loading";
import { Panel, Panels } from "components/Panel/HorizontalPanel";
import useAccess from "hooks/useAccess";
import useProcessingControl from "hooks/useProcessingControl";
import useQuery from "hooks/useQuery/index.js";
import { Col, Row, ImsCard } from "ims-ui-kit";
import React, { useState } from "react";
import { ACTIONS, EFFECTS, IMS_SERVICES } from "rolesAndPermissions";
import { getGroups } from "services/iamGroupServices";
import { imsLogger } from "services/loggerService";
import OrganizationalOverview from "../licenseManagement/OrganizaionalOverview";
import filters from "./filters/groups";
import CreateGroup from "./GroupForm";
import GroupTable from "./GroupTable";
import LOADERS from "./LoadingActions";

const Groups = (props) => {
  let [groups, setGroups] = useState([]);
  imsLogger(groups?.length);
  let { processing, dispatch } = useProcessingControl([
    { action: LOADERS.LOAD_GROUPS, status: true },
    { action: LOADERS.CREATE_GROUP },
    { action: LOADERS.LOAD_POLICIES, status: true },
    { action: LOADERS.DELETE_GROUP },
  ]);
  let { authUser, authGlobalAccess } = useAccess();

  let { query, toolState, getQuery, updatePagination, ...queryHandlers } =
    useQuery({
      filter: getFilters().find((item) => item.default),
    });

  const addToTable = (group) =>
    setGroups((prevGroups) => [group, ...prevGroups]);

  const fetchData = async (qStr) => {
    try {
      dispatch({
        [LOADERS.LOAD_GROUPS]: { status: true, error: false, id: null },
      });
      let { data } = await getGroups({ query: `${qStr}` });
      setGroups(data.iamGroups);
      updatePagination(data.pagination);
      dispatch({
        [LOADERS.LOAD_GROUPS]: { status: false, error: false, id: null },
      });
    } catch (ex) {
      dispatch({
        [LOADERS.LOAD_GROUPS]: { status: false, error: true, id: null },
      });
      imsLogger("Groups", ex.response);
    }
  };

  React.useEffect(() => {
    fetchData(getQuery());
  }, [query]);

  function getFilters() {
    return authGlobalAccess() ? filters : [];
  }

  return (
    <div className="content">
      <Panels
        defaultPanel={"Business units"}
        navLinks={
          authUser({
            service: IMS_SERVICES.IAM_GROUPS,
            // service: IMS_SERVICES.OUR_IMS,
            action: ACTIONS.CREATE,
            effect: EFFECTS.ALLOW,
          })
            ? ["Create a function", "Business units"]
            : authUser({
                service: IMS_SERVICES.IAM_GROUPS,
                action: ACTIONS.READ,
                effect: EFFECTS.ALLOW,
              }) && ["Business units"]
        }
      >
        <Panel panelId="Create a function">
          <ImsCard>
            <Row>
              <Col md="8">
                {" "}
                <CreateGroup
                  {...props}
                  dispatch={dispatch}
                  processing={processing}
                  addToTable={addToTable}
                />
              </Col>
              <Col md="4">
                {processing[LOADERS.CREATE_GROUP].status === true ? (
                  <Loading />
                ) : (
                  <OrganizationalOverview users={false} tools={false} />
                )}
              </Col>
            </Row>
          </ImsCard>
        </Panel>
        <Panel panelId="Business units" hasTable={true}>
          <ImsCard>
            {processing[LOADERS.LOAD_GROUPS].status && <Loading />}
            <GroupTable
              dataTable={groups}
              processing={processing}
              setGroups={setGroups}
              dispatch={dispatch}
              fetchData={fetchData}
              toolState={toolState}
              filters={getFilters()}
              {...queryHandlers}
            />
          </ImsCard>
        </Panel>
      </Panels>
    </div>
  );
};

export default Groups;
