export let modules = [
  {
    value: "customers",
    label: "CRM/Customers",
  },
  {
    value: "risks",
    label: "Risk management",
  },
  {
    value: "incidents",
    label: "Incident management",
  },
  {
    value: "hardwareassets",
    label: "Inventory/hardware",
  },
  {
    value: "informationassets",
    label: "Inventory/information",
  },
  {
    value: "peopleassets",
    label: "Inventory/people",
  },
  {
    value: "premiseassets",
    label: "Inventory/premise",
  },
  {
    value: "softwareassets",
    label: "Inventory/software",
  },
];
