const USER_ACTIONS = {
  LOAD_RISKS: "load-risks",
  LOAD_RISK: "load-risk",
  LOAD_RISK_DRAWER: "load-risk-drawer",
  CREATE_RISK: "create-risk",
  AMEND_RISK: "amend-risk",
  DELETE_RISK: "delete-risk",
  ESCALATE_RISK: "escalate-risk",
  NUDGE_OWNER: "nudge-owner",
  DELETE_ATTACHMENT: "delete-attachment",
  MITIGATE_RISK: "mitigate-risk",
  ACCEPT_RISK: "accept-risk",
  LOAD_LINKED_CONTROLS_TO_RISK: "load-linked-controls-to-risk",
  LINK_ISO_CONTROL: "link-iso-control",
  REMOVE_ISO_CONTROL: "remove-iso-control",
};
export default USER_ACTIONS;
