import React from "react";
import { IncidentContextProvider } from "../store";
import IncidentDetails from "./IncidentDetail";
import { TaskContextProvider } from "views/taskManagement/store";
import { DrawerContextProvider } from "ims-ui-kit";
import { TagsAndCategoriesContextProvider } from "views/tagsAndCategoriesManager/store";

const Index = (props) => {
  return (
    <IncidentContextProvider {...props}>
      <DrawerContextProvider>
        <TaskContextProvider>
          <TagsAndCategoriesContextProvider applicableModules={"incidents"}>
            <IncidentDetails />
          </TagsAndCategoriesContextProvider>
        </TaskContextProvider>
      </DrawerContextProvider>
    </IncidentContextProvider>
  );
};

export default Index;
