const USER_ACTIONS = {
    LOAD_EMAILS: "load-emails",
    LOAD_EMAIL: "load-email",
    CREATE_EMAIL: "create-email",
    SEND_EMAIL:"send-email",
    SAVE_AND_SEND_EMAIL: "save-and-send-email",
    AMEND_AND_SEND_EMAIL:"amend-and-send-email",
    AMEND_EMAIL: "amend-email",
    REMOVE_EMAIL: "remove-email",
    DOWNLOAD_ATTACHMENTS:"download-attachments",
    LOAD_RECIPIENTS:"load-recipients",
    LOAD_OVERVIEW:"load-overview",
    CLOSE_CAMPAIGN:"close-campaign"
}
export default USER_ACTIONS