import Loading from "components/Loader/Loading";
import ReactTable from "components/ReactTable/ReactTable";
import useAccess from "hooks/useAccess";
import useAlerts from "hooks/useAlerts";
import {
  Button,
  DrawerOpener,
  DrawerRight,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
  useDrawer,
} from "ims-ui-kit";
import React from "react";
import { useHistory } from "react-router-dom";
import { ACTIONS, EFFECTS, IMS_SERVICES } from "rolesAndPermissions";
import SearchableCompliance from "views/compliance/searchableList/components/Index";
import DocumentListOpener from "views/documentManagement/searchableList/components/DocumentListOpener";
import SearchableDocument from "views/documentManagement/searchableList/components/Index";
import BadgeStatus from "views/shared/StatusMapper/BadgeStatus";
import TimeDateComponent from "views/shared/TimeDateComponent";
import Index from "views/tagsAndCategoriesManager/Index";
import TaskForm from "views/taskManagement/TaskForm";
import { useTask } from "views/taskManagement/store";
import NotificationContext from "../../contexts/notificationContext";
import AnalyticalAssistant from "../shared/ai/analyticalAssistant/Index";
import { incident_analyser } from "../shared/ai/analyticalAssistant/templates";
import CreateIncident from "./CreateIncident";
import IncidentDrawerDetail from "./IncidentDrawerDetail";
import IncidentDrawerForm from "./IncidentDrawerForm";
import IncidentFilter from "./IncidentFilter";
import IncidentForm from "./IncidentForm";
import IncidentToolBar from "./IncidentToolBar";
import USER_ACTIONS from "./actions";
import { useIncident } from "./store";
import tables from "./tables";
const defaultdata = [["No data found"]];

const IncidentsTable = ({ ...props }) => {
  let {
    moduleType,
    moduleId,
    incidents: dataTable,
    processing,
    visitingIncident,
    escalateIncident,
    deleteIncident,
    nudgeIncident,
    visitIncident,
    IncidentQueryTools,
    linkISOControl,
    removeISOControl,
    createIncident,
    fetchIncidents,
    controlsOnVisitingIncident,
    reloadIncident,
    suppliers,
    ...rest
  } = useIncident();
  let { handleCreateTask } = useTask();
  let { authUser, authAdminAccess, entityAccessControl } = useAccess();
  let { openDrawer, closeDrawer } = useDrawer();
  let history = useHistory();
  let notify = React.useContext(NotificationContext);
  let { alert, warningWithConfirmMessage, successAlert } = useAlerts();
  dataTable = dataTable ? dataTable : defaultdata;
  const data = React.useMemo(
    () =>
      dataTable.map((data, key) => {
        return {
          id: data._id,
          ID: "INC-" + data.ID,
          group: data.group && data.group.name,
          title: data.title,
          owner: data.owner?.name,
          createdOn: <TimeDateComponent date={data.created.on} />,
          escalatedOn: <TimeDateComponent date={data.escalated?.on} />,
          resolvedOn: <TimeDateComponent date={data.resolved?.on} />,
          status: data.resolved.status ? (
            <BadgeStatus status="Resolved" />
          ) : !data.resolved.status && data.escalated.status ? (
            <BadgeStatus status="Escalated" />
          ) : (
            <BadgeStatus status="Open" />
          ),

          priority: (
            <span
              className={
                data.priority === "P4"
                  ? "text-success"
                  : data.priority === "P3"
                  ? "text-info"
                  : data.priority === "P3"
                  ? "text-warning"
                  : "text-danger"
              }
            >
              {data.priority}
            </span>
          ),
          data: data,
          actions: (
            <UncontrolledDropdown size="sm" direction="right">
              <DropdownToggle
                outline
                onClick={(e) => {
                  e.stopPropagation();
                }}
                data-display="static"
                className="border border rounded-circle"
              >
                <i className="fa-solid fa-ellipsis-h" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(`/admin/incidentmanagement/${data?._id}`);
                  }}
                  id="detail"
                  tooltip="View Details"
                >
                  Details
                </DropdownItem>

                {!data.resolved.status && (
                  <DropdownItem
                    onClick={(e) => {
                      warningWithConfirmMessage(
                        `${data.owner.name} will be nudged to look at ${data.reference} ${data.title}`,
                        () => {
                          nudgeIncident(data);
                        }
                      );
                      e.stopPropagation();
                    }}
                    name="nudge"
                    id="nudge"
                  >
                    Nudge
                  </DropdownItem>
                )}

                {authUser({
                  service: IMS_SERVICES.INCIDENT_MANAGEMENT,
                  action: ACTIONS.DELETE,
                  effect: EFFECTS.ALLOW,
                }) &&
                  !data.resolved.status && (
                    <DropdownItem
                      name="escalate"
                      // disabled={data.escalated.status}
                      id="escalate"
                      onClick={(e) => {
                        if (data.escalated.status) {
                          notify("Incident already escalated", "danger");
                          e.stopPropagation();
                          return;
                        }
                        warningWithConfirmMessage(
                          "This incident will be escalated",
                          () => {
                            escalateIncident(data);
                          }
                        );
                      }}
                    >
                      {processing[USER_ACTIONS.ESCALATE_INCIDENT].status &&
                      processing[USER_ACTIONS.ESCALATE_INCIDENT].id ==
                        data._id ? (
                        <Spinner size="sm" />
                      ) : (
                        "Escalate"
                      )}
                    </DropdownItem>
                  )}
                {authUser({
                  service: IMS_SERVICES.INCIDENT_MANAGEMENT,
                  action: ACTIONS.DELETE,
                  effect: EFFECTS.ALLOW,
                }) &&
                  (authAdminAccess() ||
                    entityAccessControl({
                      users: data.created.by ? [data.created.by._id] : [],
                      effect: "Allow",
                    })) &&
                  !data.resolved.status && (
                    <DropdownItem
                      onClick={(e) => {
                        warningWithConfirmMessage(
                          "This incident will be deleted",
                          () => {
                            deleteIncident(data);
                          }
                        );
                        e.stopPropagation();
                      }}
                      name="delete"
                      id="delete"
                      tooltip="Delete"
                    >
                      {processing[USER_ACTIONS.DELETE_INCIDENT].status &&
                      processing[USER_ACTIONS.DELETE_INCIDENT].id ===
                        data._id ? (
                        <Spinner size="sm" />
                      ) : (
                        "Delete"
                      )}
                    </DropdownItem>
                  )}
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        };
      }),
    [processing, dataTable]
  );
  let columns = React.useMemo(
    () => IncidentQueryTools.toolState.filter.tableState || tables.default,
    [IncidentQueryTools.toolState]
  );
  return (
    <>
      {alert}
      <div className="content">
        {processing[USER_ACTIONS.LOAD_INCIDENTS].status && <Loading />}
        <SearchableDocument moduleTypes={["incidents"]} />
        <ReactTable
          data={data}
          // hasBulkActions={true}
          resizable={false}
          columns={columns.slice()}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
          tableToolbar={
            <React.Fragment>
              <CreateIncident />
              {"  "}
              <DrawerOpener drawerId="tags">
                <Button
                  color="primary"
                  outline
                  size="md"
                  className="shadow-sm--hover ms-3"
                >
                  <i className="ims-icons icon-icon-tag-24 me-1 p-0"></i>
                  {"  "} Categories
                </Button>
              </DrawerOpener>
              {authUser({
                service: IMS_SERVICES.DOCUMENT_MANAGEMENT,
                action: ACTIONS.READ,
                effect: EFFECTS.ALLOW,
              }) && (
                <DocumentListOpener>
                  <Button
                    color="primary"
                    outline
                    size="md"
                    className="shadow-sm--hover ms-3"
                  >
                    Documents
                  </Button>
                </DocumentListOpener>
              )}
            </React.Fragment>
          }
          filterToolbar={<IncidentFilter />}
          title="Incidents"
          rowProps={function (row) {
            return {
              onClick: function (e) {
                visitIncident(row.original?.data);
                openDrawer("incident-detail");
              },
            };
          }}
          {...IncidentQueryTools}
          {...props}
          {...rest}
          onPageChange={fetchIncidents}
          pagination={IncidentQueryTools.toolState.pagination}
          isFilterable
          isSearchable
        />
        <DrawerRight
          drawerId="incident-detail"
          onDrawerClose={() => {
            visitIncident(null);
          }}
          toolbar={<IncidentToolBar />}
        >
          {<IncidentDrawerDetail />}
        </DrawerRight>
        <DrawerRight drawerId="edit-incident-form">
          {visitingIncident && <IncidentDrawerForm />}
        </DrawerRight>
        <DrawerRight drawerId="compliance-control-picker">
          <SearchableCompliance
            onNewSelection={(data) =>
              linkISOControl({
                controls: [data?.control?._id],
                toolkits: [],
              })
            }
            onDeselection={(data) => {
              removeISOControl({
                controls: [data?.control?._id],
                toolkits: [],
              });
            }}
            preSelectedControls={controlsOnVisitingIncident}
          />
        </DrawerRight>
        <DrawerRight drawerId="add-task-form">
          {visitingIncident && (
            <TaskForm
              drawerView={true}
              module={visitingIncident._id}
              moduleType="audits"
              onSubmit={async (data) => {
                await handleCreateTask(data);
                closeDrawer("add-task-form");
                reloadIncident();
              }}
            />
          )}
        </DrawerRight>
        <DrawerRight drawerId="create-incident">
          <IncidentForm
            drawerView={true}
            suppliers={suppliers}
            onSubmit={async (data) => {
              await createIncident(data);
              closeDrawer("create-incident");
              openDrawer("incident-detail");
            }}
          />
        </DrawerRight>
        <DrawerRight drawerId="incident-analyser">
          {visitingIncident && (
            <AnalyticalAssistant
              template={incident_analyser}
              source={{
                moduleType: "incidents",
                module: visitingIncident?._id,
              }}
              data={visitingIncident}
              onCreateNewTask={reloadIncident}
            />
          )}
        </DrawerRight>
        <DrawerRight drawerId="tags">
          <Index applicableModules="incidents" />
        </DrawerRight>
      </div>
    </>
  );
};

export default IncidentsTable;
