import attachment_placeholder from "assets/img/attachment-placeholder.png";
import docx_placeholder from "assets/img/docx-placeholder.png";
import emptyRepository from "assets/img/empty-repository.png";
import folder_placeholder from "assets/img/folder.svg";
import jpg_placeholder from "assets/img/jpg-placeholder.png";
import pdf_placeholder from "assets/img/pdf-placeholder.png";
import png_placeholder from "assets/img/png-placeholder.png";
import pptx_placeholder from "assets/img/pptx-placeholder.png";
import xlsx_placeholder from "assets/img/xlsx-placeholder.png";
import SimpleTable from "components/SimpleTable/SimpleTable";
import {
  Button,
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "ims-ui-kit";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import { useDocumentAnalytics } from "./store";

const placeholder = new Map();
placeholder.set("pdf", pdf_placeholder);
placeholder.set("png", png_placeholder);
placeholder.set("jpg", jpg_placeholder);
placeholder.set("jpeg", jpg_placeholder);
placeholder.set("ppt", pptx_placeholder);
placeholder.set("pptx", pptx_placeholder);
placeholder.set("doc", docx_placeholder);
placeholder.set("docx", docx_placeholder);
placeholder.set("xls", xlsx_placeholder);
placeholder.set("xlsx", xlsx_placeholder);
placeholder.set("csv", xlsx_placeholder);

const DocumentList = () => {
  let { overviewDocuments } = useDocumentAnalytics();
  function _extractImageFromExtension(type, name) {
    if (type === "document") {
      let splited = name.split(".");
      let extension = splited[splited.length - 1];
      extension = extension.toLowerCase();
      return placeholder.get(extension) || attachment_placeholder;
    }
    if (type === "folder") {
      return folder_placeholder;
    }
  }
  const history = useHistory();
  return (
    <React.Fragment>
      <div className="justify-content-start mb-3">
        <Button
          onClick={() => history.push("/admin/document-repositories/overview")}
        >
          <i className="fa-solid fa-arrow-left" />
          {"  "}Back to overview
        </Button>
      </div>
      {overviewDocuments?.length > 0 ? (
        <Card>
          <SimpleTable
            linear
            striped
            active
            thead={[
              {
                text: "Name",
                className: "",
              },
              {
                text: "Owner",
                className: "",
              },
              {
                text: "Date",
                className: "",
              },
              {
                text: "Actions",
                className: "",
              },
            ]}
            tbody={overviewDocuments.map((node) => {
              return {
                onRowClick: function () {
                  //   loadChildDetails(node);
                },
                data: [
                  {
                    item: (
                      <span id="doc-name cursor-pointer">
                        <img
                          id="doc-img"
                          className="mr-3 doc-img"
                          src={_extractImageFromExtension(
                            node?.type,
                            node?.name
                          )}
                          alt="..."
                        />
                        <span
                          to={"#"}
                          onClick={() => {
                            if (node.type === "document")
                              history.push(
                                `/admin/document-repositories/${
                                  node?.repository?._id || node?.repository
                                }/nodes/${node?._id}`
                              );
                          }}
                        >
                          {node?.name}
                        </span>
                      </span>
                    ),
                  },
                  {
                    item: node?.created?.by?.name,
                  },
                  {
                    item: moment(node?.created?.on).format("DD/MM/YYYY"),
                  },
                ],
                actions: (
                  <UncontrolledDropdown size="sm" direction="right">
                    <DropdownToggle
                      outline
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      data-display="static"
                    >
                      <i className="fa-solid fa-ellipsis-vertical three-dots"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={(e) => {
                          e.stopPropagation();
                          history.push(
                            `/admin/document-repositories/${
                              node?.repository?._id || node?.repository
                            }/nodes/${node?._id}`
                          );
                        }}
                        id="detail"
                        tooltip="View Details"
                      >
                        View document
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                ),
              };
            })}
          />
        </Card>
      ) : (
        <div className="d-flex justify-content-center py-5">
          <img src={emptyRepository} alt="" />
        </div>
      )}
    </React.Fragment>
  );
};

export default DocumentList;
