import { Card, CardBody, Col, Row } from "ims-ui-kit";
import Content from "./Content";
import DataImportContextProvider from "./importerStore/Context";
const DataImport = (props) => {
  return (
    <DataImportContextProvider>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Content />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </DataImportContextProvider>
  );
};

export default DataImport;
