import useForm from "hooks/useForm";
import {
  Button,
  Col,
  Form,
  ImsInputSelect,
  ImsInputText,
  Row,
} from "ims-ui-kit";
import { ADDITIONALMODULES, COMPLIANCE_TOOLS } from "rolesAndPermissions";
import IVal from "validations/validator";
import {} from "views/shared/CustomFormElements";
import { ImsButtonGroup } from "views/shared/ImsFormElements/Index";

const LicenseForm = ({ existingLicense, onSubmit = () => {} }) => {
  const dataSet = {
    data: {
      superUser: 0,
      groupAmount: 0,
      userAmount: 0,
      message: "",
      complianceTools: [],
      additionalModules: [],
    },
    errors: {},
  };
  const schema = {
    superUser: IVal.number().integer().min(0).label("User licence"),
    additionalModules: IVal.array().items(),
    groupAmount: IVal.number().integer().min(0).label("Group licence"),
    userAmount: IVal.number().integer().min(0).label("User licence"),
    message: IVal.label("Message"),
    complianceTools: IVal.array().items(),
  };
  const { dataModel, handleChange, handleSubmit, isBusy, validate } = useForm(
    dataSet,
    schema
  );

  let { data, errors } = dataModel;
  return (
    <Form action="/" className="form-horizontal">
      <Row>
        <Col md="6">
          <ImsInputText
            label="Super user"
            type="number"
            name="superUser"
            value={data.superUser}
            onChange={handleChange}
            error={errors.superUser}
          />
        </Col>
        <Col md="6">
          <ImsInputText
            label="User licences"
            type="number"
            name="userAmount"
            value={data.userAmount}
            onChange={handleChange}
            error={errors.userAmount}
          />
        </Col>
        <Col md="6">
          <ImsInputText
            label="Business unit licences"
            type="number"
            name="groupAmount"
            value={data.groupAmount}
            onChange={handleChange}
            error={errors.groupAmount}
          />
        </Col>
        <Col md="6">
          <ImsInputSelect
            isMulti
            placeholder="Additional Module"
            label="Additional Module"
            name="additionalModules"
            value={data.additionalModules}
            className="react-select default"
            classNamePrefix="react-select"
            onChange={handleChange}
            options={Object.values(ADDITIONALMODULES)
              .filter(
                (item) => !existingLicense?.additionalModules?.includes(item)
              )
              .map((item) => ({
                value: item,
                label: item,
              }))}
          />
        </Col>
        <Col md="12">
          <ImsInputSelect
            isMulti
            placeholder="Toolkit"
            label="Toolkit"
            name="complianceTools"
            value={data.complianceTools}
            className="react-select default"
            classNamePrefix="react-select"
            onChange={handleChange}
            options={Object.values(COMPLIANCE_TOOLS)
              .filter(
                (item) => !existingLicense?.complianceTools?.includes(item)
              )
              .map((item) => ({
                value: item,
                label: item,
              }))}
          />
        </Col>

        <Col md="12">
          <ImsInputText
            label="Message"
            cols="80"
            placeholder="Message"
            rows="2"
            type="textarea"
            name="message"
            value={data.message}
            onChange={handleChange}
            error={errors.message}
          />
        </Col>
      </Row>
      <ImsButtonGroup>
        <Button
          name="confirm"
          onClick={(e) => {
            handleSubmit(e, () => onSubmit(dataModel.data));
          }}
          disabled={validate() ? true : isBusy}
          className="btn-fill"
          color="primary"
          type="button"
        >
          {isBusy ? "Processing..." : "Confirm"}
        </Button>
      </ImsButtonGroup>
    </Form>
  );
};

export default LicenseForm;
