import { Panel, Panels } from "components/Panel/HorizontalPanel";
import AboutDocument from "./AboutDocument";
import DocumentActivity from "./DocumentActivity";
import useRepository from "./store/useRepository";
import React from "react";
import { ImsCard } from "ims-ui-kit";
const RepositorySideBar = ({ ...props }) => {
  const { detailsOfSelectedChild } = useRepository();
  return (
    <React.Fragment>
      {detailsOfSelectedChild && (
        <span className="text-secondary font-size-subtitle-2">
          <span className="text-primary">
            {detailsOfSelectedChild?.reference}
          </span>{" "}
          selected
        </span>
      )}
      <Panels defaultPanel={"About"} navLinks={["About", "Activities"]}>
        <Panel panelId="About">
          <ImsCard>
            <AboutDocument {...props} />
          </ImsCard>
        </Panel>
        <Panel panelId="Activities">
          <ImsCard>
            <DocumentActivity {...props} />
          </ImsCard>
        </Panel>
      </Panels>
    </React.Fragment>
  );
};

export default RepositorySideBar;
