let tagsModules = [
    {
        value: 'risks',
        label: 'Risk Management'
    },
    {
        value: 'customers',
        label: 'CRM'
    },
    {
        value: 'hardwareassets',
        label: 'Hardware Asset'
    },
    {
        value: 'softwareassets',
        label: 'Software Asset'
    },
    {
        value: 'peopleassets',
        label: 'People Asset'
    },
    {
        value: 'premiseassets',
        label: 'Premise Asset'
    },
    {
        value: 'informationassets',
        label: 'Information Asset'
    },
    // {
    //     value: 'cips',
    //     label: 'Continual Improvement'
    // },
    // {
    //     value: 'suppliers',
    //     label: 'Supplier Management'
    // },
    {
        value: 'incidents',
        label: 'Incident Management'
    },
    // {
    //     value: 'expensereports',
    //     label: 'Expense Reports'
    // },

]

export default tagsModules;