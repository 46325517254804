import { Panel, Panels } from "components/Panel/HorizontalPanel";
import React from "react";
import ReportBugForm from "./ReportBugForm";

const ReportBug = () => {
  let [processing, setProcessing] = React.useState({
    action: "report-bug",
    id: null,
  });
  return (
    <>
      <div className="content">
        <h4 className="mb-5">Report an issue</h4>
        <ReportBugForm processing={processing} setProcessing={setProcessing} />
      </div>
    </>
  );
};

export default ReportBug;
