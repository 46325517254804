import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "ims-ui-kit";

const ManagementReview = ({ dataSet }) => {
  return (
    <Card variant="active">
      <div className="d-flex align-items-center">
        <i
          style={{
            fontSize: "8px",
          }}
          class="fa-solid fa-circle mr-2 text-primary"
        ></i>{" "}
        <p className="card-category font-size-subtitle-1">
          <Link
            to="/admin/management-reviews"
            className="text-dark font-weight-bold"
          >
            Management Review
          </Link>
        </p>
      </div>

      <CardBody>
        <Row>
          <Col md="6" className="mb-3">
            <div className="numbers">
              <p className="card-category font-size-subtitle-2">Previous</p>
              <span className=" font-size-subtitle-1">
                {dataSet.lastManagementReviewDate}
              </span>
            </div>
          </Col>
          <Col md="6">
            <div className="numbers">
              <p className="card-category font-size-subtitle-2">Next</p>
              <span className=" font-size-subtitle-1">
                {dataSet.nextManagementReviewDate}
              </span>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ManagementReview;
