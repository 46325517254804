import React from "react";
import { TaskContextProvider } from "views/taskManagement/store";
import AuditTable from "./AuditTable";
import { AuditContextProvider } from "./store";
import { DrawerContextProvider } from "ims-ui-kit";

const Audits = (props) => {
  return (
    <React.Fragment>
      <div className="content">
        <DrawerContextProvider>
          <AuditContextProvider {...props}>
            <TaskContextProvider>
              <AuditTable {...props} />{" "}
            </TaskContextProvider>
          </AuditContextProvider>
        </DrawerContextProvider>
      </div>
    </React.Fragment>
  );
};

export default Audits;
