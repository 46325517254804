import ImsBarChart from "components/charts/ImsBarChart";
import { Card, CardBody, Col, Row } from "ims-ui-kit";
import React from "react";
import { Link } from "react-router-dom";

const CIPManagement = ({ dataSet, HoS, unmappedData }) => {
  return (
    <React.Fragment>
      {HoS ? (
        <Row>
          <Col lg="6">
            <Card variant="secondary" hover={false}>
              <span className="font-size-subtitle-1">
                <Link to="/admin/cip" className="module-link">
                  Improvements identified
                </Link>
              </span>
              <CardBody>
                <ImsBarChart
                  data={dataSet.improvementsOpen.data}
                  options={dataSet.improvementsOpen.options}
                />
              </CardBody>
            </Card>
          </Col>
          <Col lg="6">
            <Card variant="secondary" hover={false}>
              <span className="font-size-subtitle-1">
                <Link to="/admin/cip" className="module-link">
                  Improvements implemented
                </Link>
              </span>

              <CardBody>
                <div className="chart-area">
                  <ImsBarChart
                    data={dataSet.improvementsImplemented.data}
                    options={dataSet.improvementsImplemented.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        <Card variant="secondary">
          <h4
            style={{
              fontSize: "20px",
            }}
          >
            Continual Improvement
          </h4>
          <Row>
            <Col md={6}>
              <Card variant="outline" hover={false}>
                <span className="font-size-subtitle-1">
                  <Link to="/admin/cip" className="module-link">
                    Improvements identified
                  </Link>
                </span>

                <CardBody>
                  <div
                    style={{
                      minHeight: "308px",
                    }}
                    className="chart-area"
                  >
                    <ImsBarChart
                      data={dataSet.opportunities.data}
                      options={dataSet.opportunities.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card variant="outline" hover={false}>
                <span className="font-size-subtitle-1">
                  <Link to="/admin/cip" className="module-link">
                    Improvements implemented
                  </Link>
                </span>
                <CardBody>
                  <div
                    style={{
                      minHeight: "308px",
                    }}
                    className="chart-area"
                  >
                    <ImsBarChart
                      data={dataSet.improvements.data}
                      options={dataSet.improvements.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Card>
      )}
    </React.Fragment>
  );
};

export default CIPManagement;
