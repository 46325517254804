
import React from "react";
import DetailsDrawerHeader from "views/shared/DetailComponents/DetailsDrawerHeader";
import ContinualImprovementPlanForm from "./ContinualImprovementPlanForm";
import { useCip } from "./store";
import { useDrawer } from "ims-ui-kit";

const CipDrawerForm = () => {
  const { visitingCip, updateCip, implementCip } = useCip();
  const { closeDrawer, openDrawer } = useDrawer();
  return (
    <React.Fragment>
      <DetailsDrawerHeader data={visitingCip} />
      <ContinualImprovementPlanForm
        visitingCip={visitingCip}
        drawerView
        onSubmit={async (data) => {
          await updateCip(data);
          closeDrawer("edit-cip-form");
        }}
        onImplement={async (data) => {
          await implementCip(data);
          closeDrawer("edit-cip-form");
        }}
      />
    </React.Fragment>
  );
};

export default CipDrawerForm;
