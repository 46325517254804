import Loading from "components/Loader/Loading";
import ReactTable from "components/ReactTable/ReactTable";
import React, { useMemo } from "react";
import BadgeStatus from "views/shared/StatusMapper/BadgeStatus";
import TimeDateComponent from "views/shared/TimeDateComponent";
import CreateTask from "./CreateTask";
import { MyTaskTableActions } from "./TableActions";
import TaskDrawerDetail from "./TaskDrawerDetail";
import TaskDrawerForm from "./TaskDrawerForm";
import TaskForm from "./TaskForm";
import TaskToolBar from "./TaskToolbar";
import USER_ACTIONS from "./actions";
import { useTask } from "./store";
import tables from "./tables";
import { Button, DrawerRight, useDrawer } from "ims-ui-kit";
import moment from "moment";
import TaskFilter from "./TaskFilter";

const TaskTable = (props) => {
  let { openDrawer, closeDrawer } = useDrawer();
  let {
    task,
    setTask,
    processing,
    alert,
    fetchTask,
    tasks,
    handleUpdateDatatable,
    TaskQueryTools,
    filters,
    loadTasks,
    getStatusColor,
    getAssignee,
    handleCreateTask,
    moduleType,
    module,
    visitTask,
    reloadTask,
    ...rest
  } = useTask();

  const _buildMyTaskTableRows = () =>
    tasks.map((data, key) => {
      return {
        id: data._id,
        reference: data.reference,
        name: data.name,
        due: <p className="text-dark">{moment(data.due).format("ll")}</p>,
        completed_on: data.completed.status && (
          <TimeDateComponent date={data.completed?.on} />
        ),
        assignedTo: getAssignee(data),
        status: <BadgeStatus status={data.completed.status} />,
        priority: (
          <span className={getStatusColor(data.priority)}>{data.priority}</span>
        ),
        data: data,
        actions: <MyTaskTableActions data={data} />,
      };
    });

  let myTasks = useMemo(_buildMyTaskTableRows, [processing]);

  let columns = React.useMemo(
    () => TaskQueryTools.toolState.filter.tableState || tables.default,
    [TaskQueryTools.toolState]
  );
  return (
    <>
      {alert}
      <div className="content">
        {processing[USER_ACTIONS.LOAD_TASKS].status && <Loading />}
        <ReactTable
          data={myTasks}
          resizable={false}
          columns={columns.slice()}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
          isFilterable
          isSearchable
          onPageChange={loadTasks}
          toolState={TaskQueryTools.toolState}
          pagination={TaskQueryTools.toolState.pagination}
          filters={filters}
          tableToolbar={<CreateTask />}
          filterToolbar={<TaskFilter />}
          {...TaskQueryTools}
          title="Tasks"
          rowProps={function (row) {
            return {
              onClick: function (e) {
                visitTask(row.original.data);
                openDrawer("task-detail");
              },
            };
          }}
          {...rest}
        />
        <DrawerRight
          toolbar={<TaskToolBar />}
          onDrawerClose={() => {
            visitTask(null);
          }}
          drawerId="task-detail"
        >
          <TaskDrawerDetail />
        </DrawerRight>
        <DrawerRight drawerId="edit-task-form">
          <TaskDrawerForm />
        </DrawerRight>
        <DrawerRight drawerId="create-task">
          <TaskForm
            drawerView={true}
            processing={processing}
            module={module}
            moduleType={moduleType}
            onSubmit={async (data) => {
              await handleCreateTask(data);
              closeDrawer("create-task");
              openDrawer("task-detail");
            }}
          />
        </DrawerRight>
      </div>
    </>
  );
};

export default TaskTable;
