const USER_ACTIONS = {
  LOAD_SOFTWARES: "load-softwares",
  LOAD_SOFTWARE: "load-software",
  CREATE_SOFTWARE: "create-software",
  UPDATE_SOFTWARE: "update-software",
  DELETE_SOFTWARE: "delete-software",
  DELETE_ATTACHMENT: "delete-attachment",
  ADD_SOFTWARE_DOC: "add-software-doc",
  ADD_SOFTWARE_KEY: "add-software-key",
  DELETE_SOFTWARE_KEY: "delete-software-key",
  DELETE_SOFTWARE_DOC: "delete-software-doc",
};
export default USER_ACTIONS;
