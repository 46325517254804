import { DrawerContextProvider } from "ims-ui-kit";
import SupplierManagementTable from "./SupplierManagementTable";
import { SupplierContextProvider } from "./store";
import { TaskContextProvider } from "views/taskManagement/store";

const SupplierManagement = (props) => {
  return (
    <DrawerContextProvider>
      <SupplierContextProvider {...props}>
        <TaskContextProvider>
          <SupplierManagementTable {...props} />
        </TaskContextProvider>
      </SupplierContextProvider>
    </DrawerContextProvider>
  );
};

export default SupplierManagement;
