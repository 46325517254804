import React from "react";
import { useAudits } from "./store";
import useAlerts from "hooks/useAlerts";
import { useTask } from "views/taskManagement/store";
import useAccess from "hooks/useAccess";
import NotificationContext from "contexts/notificationContext";
import { Card, DrawerOpener, DrawerRight, useDrawer } from "ims-ui-kit";
import TooltipButton from "components/Tooltip/TooltipButton";
import TaskForm from "views/taskManagement/TaskForm";
import SearchableCompliance from "views/compliance/searchableList/components/Index";
import authCompliance from "utils/complianceAuthCheck";

const AuditActions = () => {
  let {
    processing,
    visitingAudit,
    linkISOControl,
    removeISOControl,
    controlsOnVisitingAudit,
    isCompletedAudit,
    reloadAudit,
  } = useAudits();
  let { closeDrawer } = useDrawer();
  let { alert, successAlert, warningWithConfirmMessage } = useAlerts();
  let { handleCreateTask } = useTask();
  let { authUser } = useAccess();
  let notify = React.useContext(NotificationContext);

  return (
    <React.Fragment>
      {alert}
      {!isCompletedAudit() && (
        <Card className="bg-light shadow-none">
          <div className="d-flex justify-content-center">
            {authCompliance() && (
              <DrawerOpener drawerId="compliance-control-picker">
                <TooltipButton
                  size="lg"
                  name="nudge"
                  id="nudge"
                  color="link"
                  tooltip="Select Compliance Control(s)"
                  className="btn-link-primary"
                >
                  <i className="ims-icons-20 icon-icon-shieldcheck-24" />
                </TooltipButton>
              </DrawerOpener>
            )}
            <DrawerOpener drawerId="add-task-form">
              <TooltipButton
                size="lg"
                name="nudge"
                id="nudge"
                color="link"
                tooltip="Link task"
                className="btn-link-primary"
              >
                <i className="ims-icons-20 icon-icon-notepad-24" />
              </TooltipButton>
            </DrawerOpener>
          </div>
          <DrawerRight drawerId="compliance-control-picker">
            <SearchableCompliance
              onNewSelection={(data) =>
                linkISOControl({
                  controls: [data?.control?._id],
                  toolkits: [],
                })
              }
              onDeselection={(data) => {
                removeISOControl({
                  controls: [data?.control?._id],
                  toolkits: [],
                });
              }}
              preSelectedControls={controlsOnVisitingAudit}
            />
          </DrawerRight>

          <DrawerRight drawerId="add-task-form">
            {visitingAudit && (
              <TaskForm
                drawerView={true}
                module={visitingAudit._id}
                moduleType="audits"
                onSubmit={async (data) => {
                  await handleCreateTask(data);
                  closeDrawer("add-task-form");
                  reloadAudit();
                }}
              />
            )}
          </DrawerRight>
        </Card>
      )}
    </React.Fragment>
  );
};

export default AuditActions;
