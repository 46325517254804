import { Container } from "ims-ui-kit";
const Example = () => {
  return (
    <>
      <div className="content">
        <Container>
          Hello I'm any component that you want in this layout. I will always be
          white mode
        </Container>
      </div>
    </>
  );
};

export default Example;
