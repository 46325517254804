import Loading from "components/Loader/Loading";
import React from "react";
import RepositoriesContextProvider from "./store/Context";
import DeletedRepositoriesTable from "./DeletedRepositoriesTable";
import useRepositories from "./store/useStore";

const DeletedRepositoriesTableIndex = (props) => {
  let {
    deletedRepos,
    fetchDeletedRepositories,
    deletedToolState,
    deletedQueryHandlers,
    isFetchingDeletedRepositories,
  } = useRepositories();
  return (
    <RepositoriesContextProvider>
      <div className="content">
        {isFetchingDeletedRepositories() && <Loading />}
        <DeletedRepositoriesTable
          dataTable={deletedRepos}
          pathname={props.match.url}
          onPageChange={fetchDeletedRepositories}
          pagination={deletedToolState.pagination}
          {...deletedQueryHandlers}
        />
      </div>
    </RepositoriesContextProvider>
  );
};

export default DeletedRepositoriesTableIndex;
