import Loading from "components/Loader/Loading";
import { Col, PanelTab, PanelTabs, PanelWindow, Row } from "ims-ui-kit";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { Attachments } from "views/shared/Attachments/Index";
import DetailsDrawerHeader from "views/shared/DetailComponents/DetailsDrawerHeader";
import DetailsWrapper from "views/shared/DetailComponents/DetailsWrapper";
import DetailsSectionContent from "views/shared/DetailsSectionContent";
import { DetailsSectionHeader } from "views/shared/DetailsSectionHeader";
import Timeline from "views/shared/Timeline/Timeline";
import { useTask } from "views/taskManagement/store";
import AttachmentsButtons from "./AttachmentsButtons";
import { RequestedTaskTableActions } from "./TableActions";
import TaskOverview from "./TaskOverview";
import USER_ACTIONS from "./actions";

const TaskDrawerDetail = (props) => {
  let { processing, task, authAcceptanceStatus, linkedURL } = useTask();

  return (
    <React.Fragment>
      {processing[USER_ACTIONS.LOAD_TASK].status ? (
        <Loading />
      ) : (
        task && (
          <React.Fragment>
            <DetailsDrawerHeader data={task} />
            <PanelTabs variant="outline" activeTab={0}>
              <PanelTab>
                <i className="ims-icons-20 icon-icon-notebook-24 mr-1"></i>
                Overview
              </PanelTab>
              <PanelTab>
                <i className="ims-icons-20 icon-icon-list-24 mr-1"></i>
                Details
              </PanelTab>
              <PanelTab>
                <i
                  className="ims-icons-20 icon-icon-activity-24 mr-1
                        "
                ></i>
                Activity
              </PanelTab>

              <PanelWindow tabId={0}>
                <TaskOverview />
              </PanelWindow>

              <PanelWindow tabId={1}>
                {task.source.module && (
                  <Col md="12" className="text-center mb-5">
                    <Link className="text-info font-weight-bold" to={linkedURL}>
                      This task is linked to {task.source.module.reference} (
                      {task.source.module.name || task.source.module.title})
                    </Link>
                  </Col>
                )}
                {task?.completed?.status === "Completed" && (
                  <span className="text-success">
                    <i className="fas fa-calendar-check"></i> This task has been
                    completed on{" "}
                    {task?.completed?.on
                      ? moment(task.completed.on).format("DD/MM/YYYY")
                      : ""}{" "}
                    by {task?.completed.by?.name}
                  </span>
                )}
                <Row>
                  {authAcceptanceStatus() === "Declined" && (
                    <Col md="12" className="text-center mb-5">
                      <p className="text-danger">
                        This task has been declined.
                      </p>
                    </Col>
                  )}
                  {authAcceptanceStatus() === "Accepted" && (
                    <Col md="12" className="text-center mb-5">
                      <p className="text-secondary">
                        This task has been assigned by{" "}
                        {task.created.by && task.created.by.name}.
                      </p>
                      {task.teamPriority && (
                        <p className="text-warning">Team priority</p>
                      )}
                    </Col>
                  )}
                  {authAcceptanceStatus() === "Pending" &&
                    task?.completed.status !== "Complete" && (
                      <Col md="12" className="text-center mb-5">
                        <p>Do you want to accept the task?</p>
                        <RequestedTaskTableActions task={task} />
                      </Col>
                    )}
                </Row>
                <Row>
                  {task.completed.by && (
                    <Col md="4">
                      <DetailsSectionContent
                        label="Completed:"
                        value={`${task.completed.by.name} ${moment(
                          task.completed.on
                        ).format("DD/MM/YYYY")}`}
                      />
                    </Col>
                  )}
                  <Col md="12">
                    <DetailsWrapper
                      label={"Description:"}
                      iconClass={"tim-icons icon-pencil"}
                      value={task.description}
                      labelClass={"pr-2"}
                    />
                  </Col>
                  <Col md="12">
                    <DetailsWrapper label={"Assigned to:"} />
                    <DetailsSectionContent
                      labelClass="text-info"
                      value={
                        <Row className="mt-3">
                          {task.assignedTo &&
                            task.assignedTo.map((assignee, index) => (
                              <Col md="3" key={assignee._id}>
                                <p className="text-bold">
                                  {assignee.user?.name}
                                  <br />
                                </p>
                                <span>
                                  <span
                                    className={
                                      assignee.acceptance === "Accepted"
                                        ? "text-success"
                                        : assignee.acceptance === "Declined"
                                        ? "text-danger"
                                        : assignee.acceptance === "Pending"
                                        ? "text-warning"
                                        : ""
                                    }
                                  >
                                    {assignee.acceptance}
                                  </span>{" "}
                                  {assignee.acceptance !== "Pending" && (
                                    <>
                                      on{" "}
                                      <span className="text-secondary">
                                        {moment(assignee.updatedOn).format(
                                          "DD/MM/YYYY HH:mm"
                                        )}
                                      </span>
                                    </>
                                  )}
                                </span>
                              </Col>
                            ))}
                        </Row>
                      }
                    />
                  </Col>
                </Row>
                <br></br>
                <DetailsSectionHeader title={`Attachments`} />
                <Row>
                  <Col md="12" className="mb-4">
                    <Attachments s3Information={task.attachments}>
                      <AttachmentsButtons />
                    </Attachments>
                  </Col>
                </Row>
              </PanelWindow>
              <PanelWindow tabId={2}>
                {task.completed.status === "Complete" ? (
                  <Timeline
                    readOnly={true}
                    horizontalSpacing={false}
                    containerClass="mx-auto sm-10"
                    moduleType="tasks"
                    moduleId={task._id}
                  />
                ) : (
                  <Timeline
                    editLabel="comment"
                    editPlaceholder="Comment"
                    horizontalSpacing={true}
                    containerClass="mx-auto sm-10"
                    moduleType="tasks"
                    moduleId={task._id}
                  />
                )}
              </PanelWindow>
            </PanelTabs>
          </React.Fragment>
        )
      )}
    </React.Fragment>
  );
};

export default TaskDrawerDetail;
