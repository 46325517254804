import Loading from "components/Loader/Loading";
import { Panel, Panels } from "components/Panel/HorizontalPanel";
import { Button, ImsCard } from "ims-ui-kit";
import React from "react";
import { IMS_SERVICES } from "rolesAndPermissions";
import ErrorHandlerComponent from "views/shared/ErrorHandlerComponent";
import ComplianceToolTable from "../ComplianceToolTable";
import Analytics from "../Overview/Analytics";
import LOADER from "./actions";
import { useISO9001 } from "./store";
import Iso9001Filter from "./Iso9001Filter";
import SearchableDocument from "views/documentManagement/searchableList/components/Index";
import DocumentListOpener from "views/documentManagement/searchableList/components/DocumentListOpener";
import { ACTIONS } from "rolesAndPermissions";
import { EFFECTS } from "rolesAndPermissions";
import useAccess from "hooks/useAccess";

const Iso9001Compliance = (props) => {
  let {
    processing,
    iso9001Controls,
    setIso9001Controls,
    iso9001Overview,
    Iso9001QueryTools,
    updateDataTable,
    fetchIso9001ToolControls,
  } = useISO9001();

  let { authUser } = useAccess();
  return (
    <React.Fragment>
      <div className="content">
        <Panels
          navLinks={["Overview", "ISO 9001 (2015)"]}
          defaultPanel={"Overview"}
        >
          <Panel panelId="Overview">
            <ImsCard>
              <ErrorHandlerComponent
                hasError={processing[LOADER.LOAD_OVERVIEW_9001].error}
                errorMessage="This iso tool has been deleted or removed"
              >
                {processing[LOADER.LOAD_OVERVIEW_9001].status ? (
                  <Loading />
                ) : (
                  <Analytics
                    toolKit={IMS_SERVICES.ISO9001}
                    overview={iso9001Overview}
                  />
                )}
              </ErrorHandlerComponent>
            </ImsCard>
          </Panel>
          <Panel panelId="ISO 9001 (2015)">
            <ImsCard>
              <ErrorHandlerComponent
                hasError={processing[LOADER.LOAD_COMPLIANCE_9001].error}
                errorMessage="This iso tool has been deleted or removed"
              >
                {processing[LOADER.LOAD_COMPLIANCE_9001].status && <Loading />}
                <SearchableDocument
                  moduleTypes={["compliancecontrols"]}
                  complianceTools={["ISO 9001"]}
                />
                <ComplianceToolTable
                  toolkit="ISO 9001 (Quality Management System-2015)"
                  dataTable={iso9001Controls}
                  setDataTable={setIso9001Controls}
                  processing={processing[LOADER.LOAD_COMPLIANCE_9001]}
                  updateDataTable={updateDataTable}
                  onPageChange={fetchIso9001ToolControls}
                  pagination={Iso9001QueryTools.toolState.pagination}
                  tableToolbar={
                    <React.Fragment>
                      {authUser({
                        service: IMS_SERVICES.DOCUMENT_MANAGEMENT,
                        action: ACTIONS.READ,
                        effect: EFFECTS.ALLOW,
                      }) && (
                        <DocumentListOpener>
                          <Button
                            color="primary"
                            outline
                            size="md"
                            className="shadow-sm--hover ms-3"
                          >
                            Documents
                          </Button>
                        </DocumentListOpener>
                      )}
                    </React.Fragment>
                  }
                  filterToolbar={<Iso9001Filter />}
                  {...Iso9001QueryTools}
                />
              </ErrorHandlerComponent>
            </ImsCard>
          </Panel>
        </Panels>
      </div>
    </React.Fragment>
  );
};

export default Iso9001Compliance;
