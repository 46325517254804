import { Card, CardBody, CardTitle } from "ims-ui-kit";

const CriticalArea = ({ dataSet }) => {
  return (
    <Card
      style={{
        minHeight: "150px",
      }}
      variant="gradient"
      color="primary"
    >
      <CardBody>
        <div className="numbers">
          <div className="d-flex align-items-center">
            <i
              style={{
                fontSize: "8px",
                color: "#ED3447",
              }}
              class="fa-solid fa-circle mr-2"
            ></i>{" "}
            <p className="card-category">Critical area</p>
          </div>
          <CardTitle tag="h3">
            <h3>{dataSet.criticalArea}</h3>
          </CardTitle>
        </div>
      </CardBody>
    </Card>
  );
};

export default CriticalArea;
