import Loading from "components/Loader/Loading";
import ReactTable from "components/ReactTable/ReactTable";
import NotificationContext from "contexts/notificationContext";
import useAccess from "hooks/useAccess";
import useAlerts from "hooks/useAlerts";
import {
  Button,
  DrawerRight,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
  useDrawer,
} from "ims-ui-kit";
import React from "react";
import { useHistory } from "react-router-dom";
import { ACTIONS, EFFECTS, IMS_SERVICES } from "rolesAndPermissions";
import { getCurrentUserInfo } from "services/userServices";
import ContinualImprovementPlanForm from "views/cip/ContinualImprovementPlanForm";
import SearchableCompliance from "views/compliance/searchableList/components/Index";
import SearchableDocument, {
  DocumentListOpener,
} from "views/documentManagement/searchableList/components/Index";
import BadgeStatus from "views/shared/StatusMapper/BadgeStatus";
import TimeDateComponent from "views/shared/TimeDateComponent";
import TaskForm from "views/taskManagement/TaskForm";
import { useTask } from "views/taskManagement/store";
import AnalyticalAssistant from "../shared/ai/analyticalAssistant/Index";
import CipDrawerDetail from "./CipDrawerDetail";
import CipDrawerForm from "./CipDrawerForm";
import CipToolBar from "./CipToolBar";
import CreateCip from "./CreateCip";
import USER_ACTIONS from "./actions";
import filters from "./filters";
import { useCip } from "./store";
import tables from "./tables";
import CIPFilter from "./CIPFilter";
const defaultdata = [["No data found"]];

const ReactTables = ({ ...props }) => {
  let {
    cips: dataTable,
    processing,
    fetchCips,
    CIPQueryTools,
    updateCip,
    nudgeCip,
    deleteCip,
    visitCip,
    visitingCip,
    isImplementedCip,
    linkISOControl,
    removeISOControl,
    controlsOnVisitingCip,
    createCip,
    reloadCip,
    ...rest
  } = useCip();
  let { handleCreateTask } = useTask();
  let history = useHistory();
  dataTable = dataTable ? dataTable : defaultdata;
  let { openDrawer, closeDrawer } = useDrawer();
  let notify = React.useContext(NotificationContext);
  let { alert, warningWithConfirmMessage } = useAlerts();

  let { authUser, authAdminAccess, entityAccessControl } = useAccess(
    getCurrentUserInfo()
  );

  const data = React.useMemo(
    () =>
      dataTable.map((cip, key) => {
        return {
          id: cip._id,
          reference: cip.reference,
          business_function: cip.group && cip.group.name,
          title: cip.title,
          assign_owner: cip.owner && cip.owner.name,
          status: <BadgeStatus status={cip?.implemented?.status} />,
          data: cip,
          created_on: <TimeDateComponent date={cip.created?.on} />,
          implementedOn: <TimeDateComponent date={cip.implemented?.on} />,
          actions: (
            <UncontrolledDropdown size="sm" direction="right">
              <DropdownToggle
                outline
                onClick={(e) => {
                  e.stopPropagation();
                }}
                data-display="static"
                className="border border rounded-circle"
              >
                <i className="fa-solid fa-ellipsis-h" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  id="detail"
                  tooltip="View Details"
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(`/admin/cip/${cip?._id}`);
                  }}
                >
                  Details
                </DropdownItem>
                {!isImplementedCip() && (
                  <DropdownItem
                    onClick={(e) => {
                      if (!cip.owner) {
                        notify("OFI owner is not assigned", "danger");
                        return;
                      }
                      warningWithConfirmMessage(
                        `${cip.owner.name} will be nudged to look at ${cip.reference} ${cip.title}`,
                        () => {
                          nudgeCip(cip);
                        }
                      );
                      e.stopPropagation();
                    }}
                    name="nudge"
                    id="nudge"
                  >
                    Nudge
                  </DropdownItem>
                )}
                {/* <Tooltip placement="top" target="nudge" trigger="hover">
                  {new Date(data.nextNudgeAt) > new Date()
                    ? `Already nudged ${data.owner?.name}`
                    : `Nudge ${data.owner?.name}`}
                </Tooltip> */}
                {!isImplementedCip() &&
                  authUser({
                    service: IMS_SERVICES.CONTINUAL_IMPROVEMENT_PLAN,
                    action: ACTIONS.DELETE,
                    effect: EFFECTS.ALLOW,
                  }) &&
                  (authAdminAccess() ||
                    entityAccessControl({
                      users: cip.created.by ? [cip.created.by._id] : [],
                      effect: "Allow",
                    })) && (
                    <>
                      <DropdownItem
                        name="delete"
                        id="delete"
                        tooltip="Delete"
                        onClick={(e) => {
                          warningWithConfirmMessage(
                            "This OFI will be deleted",
                            () => {
                              deleteCip(cip);
                            }
                          );
                          e.stopPropagation();
                        }}
                      >
                        {processing.action === "delete" &&
                        processing.id === cip._id ? (
                          <Spinner size="sm" />
                        ) : (
                          // <i className="ims-icons-20 icon-icon-trash-24" />
                          "Delete"
                        )}
                      </DropdownItem>
                    </>
                  )}
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        };
      }),
    [processing]
  );
  let columns = React.useMemo(
    () => CIPQueryTools.toolState.filter.tableState || tables.default,
    [CIPQueryTools.toolState]
  );
  return (
    <>
      {alert}
      <SearchableDocument moduleTypes={["cips"]} />
      <div className="content">
        {processing[USER_ACTIONS.LOAD_CIPS].status && <Loading />}
        <ReactTable
          data={data}
          {...rest}
          resizable={false}
          columns={columns.slice()}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
          isFilterable
          isSearchable
          tableToolbar={
            <React.Fragment>
              <CreateCip />
              {authUser({
                service: IMS_SERVICES.DOCUMENT_MANAGEMENT,
                action: ACTIONS.READ,
                effect: EFFECTS.ALLOW,
              }) && (
                <DocumentListOpener>
                  <Button
                    color="primary"
                    outline
                    size="md"
                    className="shadow-sm--hover ms-3"
                  >
                    Documents
                  </Button>
                </DocumentListOpener>
              )}
            </React.Fragment>
          }
          filterToolbar={<CIPFilter />}
          title={"OFIs"}
          rowProps={function (row) {
            return {
              onClick: function (e) {
                visitCip(row.original?.data);
                openDrawer("cip-detail");
              },
            };
          }}
          onPageChange={fetchCips}
          pagination={CIPQueryTools.toolState.pagination}
          filters={filters}
          {...CIPQueryTools}
          {...props}
          {...rest}
        />
        <DrawerRight
          drawerId="cip-detail"
          onDrawerClose={() => {
            visitCip(null);
          }}
          toolbar={<CipToolBar />}
        >
          {<CipDrawerDetail />}
        </DrawerRight>
        {/* Edit Risk Form Drawer */}
        <DrawerRight drawerId="edit-cip-form">
          {visitingCip && <CipDrawerForm />}
        </DrawerRight>
        <DrawerRight drawerId="compliance-control-picker">
          <SearchableCompliance
            onNewSelection={(data) =>
              linkISOControl({
                controls: [data?.control?._id],
                toolkits: [],
              })
            }
            onDeselection={(data) => {
              removeISOControl({
                controls: [data?.control?._id],
                toolkits: [],
              });
            }}
            preSelectedControls={controlsOnVisitingCip}
          />
        </DrawerRight>
        <DrawerRight drawerId="add-task-form">
          {visitingCip && (
            <TaskForm
              drawerView={true}
              module={visitingCip._id}
              moduleType="cips"
              onSubmit={async (data) => {
                await handleCreateTask(data);
                closeDrawer("add-task-form");
                reloadCip();
              }}
            />
          )}
        </DrawerRight>

        <DrawerRight drawerId="create-cip">
          <ContinualImprovementPlanForm
            drawerView={true}
            onSubmit={async (data) => {
              await createCip(data);
              closeDrawer("create-cip");
              openDrawer("cip-detail");
            }}
          />
        </DrawerRight>
        <DrawerRight drawerId="cip-analyser">
          {visitCip && (
            <AnalyticalAssistant
              template={null}
              source={{
                moduleType: "cips",
                moduleId: visitingCip?._id,
              }}
              data={visitingCip}
            />
          )}
        </DrawerRight>
      </div>
    </>
  );
};

export default ReactTables;
