import { DrawerContextProvider } from "ims-ui-kit";
import PremisesTable from "./PremisesTable";
import { PremiseAssetsContextProvider } from "./store";
import { TagsAndCategoriesContextProvider } from "views/tagsAndCategoriesManager/store";
const PremiseAssets = (props) => {
  return (
    <>
      <DrawerContextProvider>
        <PremiseAssetsContextProvider {...props}>
        <TagsAndCategoriesContextProvider
            applicableModules={"premiseassets"}
          >
          <PremisesTable {...props} />
          </TagsAndCategoriesContextProvider>
        </PremiseAssetsContextProvider>
      </DrawerContextProvider>
    </>
  );
};

export default PremiseAssets;
