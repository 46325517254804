let USER_ACTIONS = {
    LOAD_SUBSCRIBERS: "load-subscribers",
    LOAD_NOTIFICATIONS: "load-notifications",
    LOAD_DATA: "load-data",
    UPDATE_SYSTEMDATES: "update-system-date",
    UPDATE_RESOLUTION: "update-resolution",
    LOAD_RESOLUTION: "load-resolution",
    ADD_SUBSCRIBER: 'add-subscriber',
    DELETE_SUBSCRIBER: "delete-subscriber",
    ADD_NOTIFICATION: 'add-notification',
    LOAD_ORGANISATION: "load-organisation",
    UPDATE_ORGANISATION: "update-organisation",
    REQUEST_LICENSE: "request-license",
};
export default USER_ACTIONS