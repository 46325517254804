import Loading from "components/Loader/Loading";
import { Panel, Panels } from "components/Panel/HorizontalPanel";
import { Button, ImsCard } from "ims-ui-kit";
import React from "react";
import { IMS_SERVICES } from "rolesAndPermissions";
import ErrorHandlerComponent from "views/shared/ErrorHandlerComponent";
import ComplianceToolTable from "../ComplianceToolTable";
import Analytics from "../Overview/Analytics";
import LOADER from "./actions";
import { useISO20000 } from "./store";
import Iso20000Filter from "./Iso20000Filter";
import DocumentListOpener from "views/documentManagement/searchableList/components/DocumentListOpener";
import { ACTIONS } from "rolesAndPermissions";
import { EFFECTS } from "rolesAndPermissions";
import useAccess from "hooks/useAccess";
import SearchableDocument from "views/documentManagement/searchableList/components/Index";

const Iso20000 = ({ pathname }) => {
  let {
    processing,
    iso20000Controls,
    setIso20000Controls,
    iso20000Overview,
    fetchIso20000ToolControls,
    updateDataTable,
    Iso20000QueryTools,
  } = useISO20000();

  let { authUser } = useAccess();
  return (
    <React.Fragment>
      <div className="content">
        <Panels
          navLinks={["Overview", "ISO 20000 (2018)"]}
          defaultPanel={"Overview"}
        >
          <Panel panelId="Overview">
            <ImsCard>
              <ErrorHandlerComponent
                hasError={processing[LOADER.LOAD_OVERVIEW_20000].error}
                errorMessage="This iso tool has been deleted or removed"
              >
                {processing[LOADER.LOAD_OVERVIEW_20000].status ? (
                  <Loading />
                ) : (
                  <Analytics
                    toolKit={IMS_SERVICES.ISO20000}
                    overview={iso20000Overview}
                  />
                )}
              </ErrorHandlerComponent>
            </ImsCard>
          </Panel>
          <Panel panelId="ISO 20000 (2018)">
            <ImsCard>
              <ErrorHandlerComponent
                hasError={processing[LOADER.LOAD_COMPLIANCE_20000].error}
                errorMessage="This iso tool has been deleted or removed"
              >
                {processing[LOADER.LOAD_COMPLIANCE_20000].status && <Loading />}
                <SearchableDocument
                  moduleTypes={["compliancecontrols"]}
                  complianceTools={["ISO 20000"]}
                />
                <ComplianceToolTable
                  toolkit="ISO 20000 (Service Management System-2018)"
                  dataTable={iso20000Controls}
                  setDataTable={setIso20000Controls}
                  processing={processing[LOADER.LOAD_COMPLIANCE_20000]}
                  updateDataTable={updateDataTable}
                  pagination={Iso20000QueryTools.toolState.pagination}
                  tableToolbar={
                    <React.Fragment>
                      {authUser({
                        service: IMS_SERVICES.DOCUMENT_MANAGEMENT,
                        action: ACTIONS.READ,
                        effect: EFFECTS.ALLOW,
                      }) && (
                        <DocumentListOpener>
                          <Button
                            color="primary"
                            outline
                            size="md"
                            className="shadow-sm--hover ms-3"
                          >
                            Documents
                          </Button>
                        </DocumentListOpener>
                      )}
                    </React.Fragment>
                  }
                  filterToolbar={<Iso20000Filter />}
                  {...Iso20000QueryTools}
                />
              </ErrorHandlerComponent>
            </ImsCard>
          </Panel>
        </Panels>
      </div>
    </React.Fragment>
  );
};

export default Iso20000;
