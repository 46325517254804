const USER_ACTIONS = {
  LOAD_AUDITS: "load-audits",
  LOAD_AUDIT: "load-audit",
  LOAD_AUDIT_DRAWER: "load-audit-drawer",
  CREATE_AUDIT: "create-audit",
  AMEND_AUDIT: "amend-audit",
  DELETE_AUDIT: "delete-audit",
  NUDGE_OWNER: "nudge-owner",
  LOAD_AUDIT_DRAWER: "load-audit-drawer",
  DELETE_ATTACHMENT: "delete-attachment",
  COMPLETE_AUDIT: "complete-audit",
  ADD_IDENTIFICATION: "add-identification",
  AMEND_IDENTIFICATION: "amend-identification",
  DELETE_IDENTIFICATION: "delete-identification",
  ADD_OFI: "add-ofi",
  AMEND_OFI: "amend-ofi",
  DELETE_OFI: "delete-ofi",
  ADD_RISK: "add-risk",
  AMEND_RISK: "amend-risk",
  DELETE_RISK: "delete-risk",
  LINK_ISO_CONTROL: "link-iso-control",
  REMOVE_ISO_CONTROL: "remove-iso-control",
  LOAD_LINKED_CONTROLS_TO_AUDIT: "load-linked-controls-to-audit",
  SEND_REPORT:"send-audit-report"
};
export default USER_ACTIONS;
