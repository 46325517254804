import Loading from "components/Loader/Loading";
import ReactTable from "components/ReactTable/ReactTable";
import NotificationContext from "contexts/notificationContext";
import useAccess from "hooks/useAccess";
import useAlert from "hooks/useAlerts";
import {
  Button,
  DrawerRight,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
  useDrawer,
} from "ims-ui-kit";
import React from "react";
import { useHistory } from "react-router-dom";
import { ACTIONS, EFFECTS, IMS_SERVICES } from "rolesAndPermissions";
import DocumentListOpener from "views/documentManagement/searchableList/components/DocumentListOpener";
import SearchableDocument from "views/documentManagement/searchableList/components/Index";
import TaskForm from "views/taskManagement/TaskForm";
import { useTask } from "views/taskManagement/store";
import CreateSupplier from "./CreateSupplier";
import SupplierDrawerDetail from "./SupplierDrawerDetail";
import SupplierDrawerForm from "./SupplierDrawerForm";
import SupplierForm from "./SupplierForm";
import SupplierToolBar from "./SupplierToolBar";
import USER_ACTIONS from "./actions";
import { useSupplier } from "./store";
import SupplierFilter from "./SupplierFilter";
const defaultdata = [["No data found"]];

const ReactTables = ({ ...props }) => {
  let {
    suppliers: dataTable,
    processing,
    visitingSupplier,
    createSupplier,
    deleteSupplier,
    visitSupplier,
    SupplierQueryTools,
    fetchSuppliers,
    reloadSupplier,
    ...rest
  } = useSupplier();

  let { handleCreateTask } = useTask();
  let { openDrawer, closeDrawer } = useDrawer();
  let history = useHistory();
  let notify = React.useContext(NotificationContext);
  let { alert, warningWithConfirmMessage, successAlert } = useAlert();
  let { authUser, authGlobalAccess, authAdminAccess, entityAccessControl } =
    useAccess();
  dataTable = dataTable ? dataTable : defaultdata;
  const data = React.useMemo(
    () =>
      dataTable.map((data, key) => {
        return {
          id: data._id,
          ID: "SUP-" + data.ID,
          group: data.group && data.group.name,
          supplier_name: data.name,
          account_manager: data.accountManager,
          compliant: data.isCompliant ? "Yes" : "No",
          data: data,
          actions: (
            <>
              <UncontrolledDropdown size="sm" direction="right">
                <DropdownToggle
                  outline
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  data-display="static"
                  className="border border rounded-circle"
                >
                  <i className="fa-solid fa-ellipsis-h" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(`/admin/suppliermanagement/${data?._id}`);
                    }}
                    id="detail"
                    tooltip="View Details"
                  >
                    Details
                  </DropdownItem>

                  {authUser({
                    service: IMS_SERVICES.SUPPLIER_MANAGEMENT,
                    action: ACTIONS.DELETE,
                    effect: EFFECTS.ALLOW,
                  }) &&
                    (authAdminAccess() ||
                      entityAccessControl({
                        users: data.created.by ? [data.created.by._id] : [],
                        effect: "Allow",
                      })) && (
                      <DropdownItem
                        onClick={(e) => {
                          warningWithConfirmMessage(
                            "This supplier will be deleted",
                            () => {
                              deleteSupplier(data);
                            }
                          );
                          e.stopPropagation();
                        }}
                        name="delete"
                        id="delete"
                        tooltip="Delete"
                      >
                        {processing.action === "delete" &&
                        processing.id === data._id ? (
                          <Spinner size="sm" />
                        ) : (
                          "Delete"
                        )}
                      </DropdownItem>
                    )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          ),
        };
      }),
    [processing]
  );
  return (
    <>
      {alert}
      <SearchableDocument moduleTypes={["suppliers"]} />
      <div className="content">
        {processing[USER_ACTIONS.LOAD_SUPPLIERS].status && <Loading />}
        <ReactTable
          data={data}
          resizable={false}
          {...rest}
          tableToolbar={
            <React.Fragment>
              <CreateSupplier />
              {authUser({
                service: IMS_SERVICES.DOCUMENT_MANAGEMENT,
                action: ACTIONS.READ,
                effect: EFFECTS.ALLOW,
              }) && (
                <DocumentListOpener>
                  <Button
                    color="primary"
                    outline
                    size="md"
                    className="shadow-sm--hover ms-3"
                  >
                    Documents
                  </Button>
                </DocumentListOpener>
              )}
            </React.Fragment>
          }
          columns={[
            {
              Header: "Reference",
              accessor: "ID",
            },
            {
              Header: authGlobalAccess() ? "Business unit" : "Business unit",
              accessor: "group",
            },
            {
              Header: "Supplier name",
              accessor: "supplier_name",
            },
            {
              Header: "account manager",
              accessor: "account_manager",
            },

            {
              Header: "Compliant",
              accessor: "compliant",
            },
            {
              Header: "Actions",
              accessor: "actions",
              sortable: false,
              filterable: false,
            },
          ]}
          filterToolbar={<SupplierFilter />}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          onPageChange={fetchSuppliers}
          pagination={SupplierQueryTools.toolState.pagination}
          className="-striped -highlight"
          isFilterable
          isSearchable
          title="Suppliers"
          rowProps={function (row) {
            return {
              onClick: function (e) {
                visitSupplier(row.original?.data);
                openDrawer("supplier-detail");
              },
            };
          }}
          {...SupplierQueryTools}
        />
        <DrawerRight
          size="55"
          drawerId="supplier-detail"
          onDrawerClose={() => {
            visitSupplier(null);
          }}
          toolbar={<SupplierToolBar />}
        >
          {<SupplierDrawerDetail />}
        </DrawerRight>
        <DrawerRight size="55" drawerId="edit-supplier-form">
          {visitingSupplier && <SupplierDrawerForm />}
        </DrawerRight>
        <DrawerRight size="55" drawerId="add-task-form">
          {visitingSupplier && (
            <TaskForm
              drawerView={true}
              module={visitingSupplier._id}
              moduleType="suppliers"
              onSubmit={async (data) => {
                await handleCreateTask(data);
                closeDrawer("add-task-form");
                reloadSupplier();
              }}
            />
          )}
        </DrawerRight>
        <DrawerRight size="55" drawerId="create-supplier">
          <SupplierForm
            drawerView={true}
            onSubmit={async (data) => {
              await createSupplier(data);
              closeDrawer("create-supplier");
              openDrawer("supplier-detail");
            }}
          />
        </DrawerRight>
      </div>
    </>
  );
};

export default ReactTables;
