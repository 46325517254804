import FilePreview from "./Preview";
const routes = [
  // {
  //   path: "/file-preview",
  //   name: "File preview",
  //   icon: "tim-icons icon-puzzle-10",
  //   component: FilePreview,
  //   layout: "/public",
  // },
];

export default routes;
