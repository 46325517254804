export const applicableModulesOptions = [
  { label: "Compliance", value: "compliancecontrols" },
  {
    label: "Risk management",
    value: "risks",
  },
  {
    label: "Continual improvement",
    value: "cips",
  },
  { label: "Audits", value: "audits" },
  // { label: "Compliance tools", value: "compliancecontrols" },
  { label: "Management reviews", value: "managementreviews" },
  { label: "Supplier management", value: "suppliers" },
  { label: "Incident management", value: "incidents" },
 
  // { label: "Expenses", value: "expensereports" },
];


export const REACT_DND_ITEM_TYPES = {
  CARD: "card",
  FORM_BUILDER_FORM_ELEMENT: "form-builder-form-element",
};
export const FORM_FIELD_TYPES = {
  TEXT: "text",
  NUMBER: "number",
  MULTIPLE_CHOICE: "multiple_choice",
  CHECKBOX: "checkbox",
  SINGLE_SELECT_DROPDOWN: "single_select_dropdown",
  MULTIPLE_SELECT_DROPDOWN: "multiple_select_dropdown",
  TIME_STAMP: "time_stamp",
};
